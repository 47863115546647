import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import {
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from "react-google-maps";
import { isEmpty } from "../../actions/common";
import { GetAddressBasedOnLatLng } from "./geocode";
export default function GMap({ show_map, setShow_map,Add,SetAddress }) {


 
  const [getCenter,SetgetCenter] = useState('')
  const [isOpen,SetIsOpen] = useState(false)
  const [Address,SetAddresses] = useState('')
  const [Clicked,SetClicked] = useState('')
  
  // const [Address,SetAddress] = useState('')
  
  
  const GetLatLng = async(e) =>{
    let Address = await GetAddressBasedOnLatLng(Clicked?.lat,Clicked?.lng,'sign')
    console.log('sdljjhsjkhfksfs',Address,Address)
    if(Address?.Street){
      Address.Street = Address?.Street
    }
    else if(Address){
      Address.Street = ''
    }

    console.log("flkdjfksjflsf",Address)
    SetAddresses({...Address})  
  }
useEffect(()=>{
  GetLatLng()
},[Clicked])

  useEffect(()=>{
    console.log(" navigator.geolocation", navigator.geolocation)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log("dsndsmadnmsandsadas",position,position.coords.latitude)
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }; 
       SetgetCenter(pos) 
       SetClicked(pos)    
    })
  }
  },[])


  const MapContainer = 
    withGoogleMap(props =>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={getCenter}
        onClick={(e)=>{
          SetIsOpen(false)
        SetClicked({
          lat:e?.latLng?.lat(),
          lng:e?.latLng?.lng()
        })}
        }
      >
        <Marker
        clickable={true}
        // draggable
          position={Clicked}
          // onDrag={(e)=>{
          //   SetIsOpen(false)
          //   GetLatLng(e)
          //  }}
          onClick={(e)=>{
            SetIsOpen(true)
          }}
        >
                {isOpen && <InfoWindow onCloseClick={()=>SetIsOpen(!isOpen)}>
                <div>{Address}</div>
     
      </InfoWindow>}
        </Marker>
      </GoogleMap>
    )
   

 return (
    <Modal
      size="md"
      show={show_map}
      onHide={()=>setShow_map(!show_map)}
      // backdrop="static"
      keyboard={false}
      centered
      className="regpopy_modal"
    >
      <Modal.Header className="mapmodal_header" closeButton></Modal.Header>

      <Modal.Body>
     <div>
  <MapContainer
  containerElement={<div style={{ height: `400px` }} />}
  mapElement={<div style={{ height: `100%` }} />}/>
     </div>
     <div>
      <button className="blue_btn" onClick={()=>{
        setShow_map(false);
        SetAddress({Latlng : [Clicked?.lat , Clicked?.lng] ,Address:Address }); 
       }}>
        Done
      </button>
     </div>
    
      </Modal.Body>
    </Modal>
  );
}
