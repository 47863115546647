import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { useDispatch, useSelector } from "react-redux";
import { EncryptData, isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { LoadToast, updateToast } from "../actions/common";
import { handleCallback } from "../actions/errorHandler";
import config from "../actions/config";

import delpick from "../assets/images/delpick.png";

function SignIn() {
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  console.log("jbbf", payload);
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const initdata = {
    EmailId: "",
    Password: "",
  };

  const [formdata, SetFormdata] = useState(initdata);
  const [error, SetError] = useState({});
  const [load, Setload] = useState(false);
  const [passwordText, setPasswordText] = useState("password");

  const OnChange = (e) => {
    SetError({});
    const { id, value } = e.target;
    SetFormdata({ ...formdata, ...{ [id]: value } });
    Setload(false);
  };

  const Validate = () => {
    let Error = {};

    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (isEmpty(formdata?.EmailId)) {
      Error.EmailId = "Email Id Required";
    }
    if (!isEmpty(formdata?.EmailId) && !emailRegex.test(formdata.EmailId)) {
      Error.EmailId = "Enter Valid Email";
    }
    if (isEmpty(formdata?.Password)) {
      Error.Password = "Password Required";
    }
    // else if (!config.PASSWORD.test(formdata.Password)) {
    //   Error.Password =
    //     "Password must contain 1 small letter, 1 capital letter, 1 special character, 1 number.";
    // }
    return Error;
  };
  console.log("state", location?.state?.from);

  const Submit = handleCallback(async () => {
    Setload(true);
    const toastid = LoadToast();
    console.log("toastid", toastid);
    let errorvalid = Validate(formdata);
    if (isEmpty(errorvalid)) {
      SetError({});
      let senddata = formdata;

      senddata.action = "login";
      let Resp = await axiosFile.LoginReg(formdata);
      console.log("ssssss????????????", JSON.stringify(Resp, null, 2));
      if (Resp.success == "success") {
        localStorage.setItem("token_s", Resp.token);
        localStorage.setItem("_id_s", EncryptData(Resp?.result?._id));
        dispatch({
          type: "Login",
          Login: {
            User: {
              token: Resp.token,
              payload: Resp.result,
            },
          },
        });
        updateToast(toastid, "Signin Successfully", "success");
        navigate("/shoplist");
      } else {
        Setload(false);
        SetError(Resp.error);
        updateToast(toastid, "fix", "error");
      }
    } else {
      Setload(false);
      updateToast(toastid, "fix", "error");
      SetError(errorvalid);
    }
  });

  return (
    <div className="singin_bg">
      {/* <img
        className="singin_abs"
        src={require("../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs delpick_sign" src={delpick} alt="delpick" />
      <div className="sigin_form">
        <div className="signin_topttl">
          <p className="signin_ttlleft">
            Let's <br /> Sign you in
          </p>
          {/* <p className="sigin_skip">
            <Link to="/">Skip</Link>
          </p> */}
        </div>
        <p className="sigin_missedtxt">Welcome Back, You’ve been missed.</p>
        <div className="centering_sigin">
          <div className="single_center">
            <label className="signin_labels" for="mobile">
              Email Id
            </label>
            <br />
            <input
              className="sigin_inputs"
              type="text"
              placeholder="Enter your Email Id"
              id="EmailId"
              name="EmailId"
              onChange={(e) => OnChange(e)}
            />
            {error?.EmailId && (
              <span className="error-msg">{error?.EmailId}</span>
            )}
          </div>
          <div className="single_center">
            <label className="signin_labels" for="password">
              password
            </label>
            <br />
            <div className="reg_passwordinps signin_inps">
              <input
                className=""
                type={passwordText == "password" ? "password" : "text"}
                placeholder="Enter your password"
                id="Password"
                name="Password"
                onChange={(e) => OnChange(e)}
              />
              <i
                onClick={() =>
                  setPasswordText(passwordText == "text" ? "password" : "text")
                }
                className={
                  passwordText == "password"
                    ? "fa-solid fa-eye-slash"
                    : "fa-solid fa-eye"
                }
              ></i>
            </div>
            {error?.Password && (
              <span className="error-msg">{error?.Password}</span>
            )}
          </div>
        </div>
        <div className="remember_dtls">
          <div>
            {/* <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
  <label for="vehicle1" className='remember_lable'>Remember me</label> */}
          </div>
          <Link to="/forgotpw" className="signin_frgtpwt">
            Forgot password?
          </Link>
        </div>
        <div className="siginin_btn_dtl">
          <button
            className="blue_btn signin_btn"
            onClick={() => Submit()}
            disabled={load}
          >
            {load ? "Loading..." : " Sign In "}
          </button>
        </div>

        <p className="sigin_acnt_txt">
          Don't have an account?{" "}
          <span className="reg_link">
            <Link to="/register">Register</Link>
          </span>
        </p>
      </div>
    </div>
  );
}

export default SignIn;
