import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import { useLocation, useParams } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { useDispatch, useSelector } from "react-redux";
import useAxiosFile from "../../actions/useaxios";
import config from "../../actions/config";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

function UserAccounts() {
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const { pathname, search } = useLocation();
  const { toggle, payload, token } = useSelector(
    (state) => state.seller_panel.User
  );
  const axiosFile = useAxiosFile();
  let id = DecryptData(search.split("?Id=")[1]);

  console.log("_icsadasdasdasdasdasdd", id);
  const initval = {
    FirstName: payload?.FirstName,
    LastName: payload?.LastName,
    EmailId: payload?.EmailId,
    Mobile: payload?.Mobile,
    Profile: payload?.Profile,
    Gender: payload?.Gender,
    DateofBirth: payload?.DateofBirth,
  };
  const [profile, SetProfile] = useState(initval);
  const [passwordText, setPasswordText] = useState("password");

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(payload)) {
      SetProfile({
        ...profile,
        ...{
          FirstName: payload?.FirstName,
          _id: payload?._id,
          LastName: payload?.LastName,
          EmailId: payload?.EmailId,
          Mobile: payload?.Mobile,
          Profile: payload?.Profile,
          Gender: payload?.Gender,
          DateofBirth: payload?.DateofBirth,
        },
      });
    }
  }, [token]);
  console.log("dsahkjdhasjdhsakdsad", payload);
  const GenderOption = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ];
  const [Error, SetError] = useState({});
  console.log("Error", Error);
  const navigate = useNavigate();

  /**lp */

  const Onchange = (e, ids) => {
    SetError({});
    if (e?.target?.id == "Profile") {
      if (
        e?.target?.files?.length > 0 &&
        Object.values(e?.target?.files).some((it) => !it.type.includes("image"))
      ) {
        toast("Image Only Allowed", {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      } else {
        SetProfile({
          ...profile,
          ...{
            [e?.target?.id ?? ids]: e?.target?.files[0],
          },
        });
      }
    } else {
      SetProfile({
        ...profile,
        ...{
          [e?.target?.id ?? ids]: e?.target?.value ?? e?.value ?? e,
        },
      });
    }
  };

  console.log("dhskdjsfsdjfsdgfsd", profile, id);

  const Validation = () => {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    let errors = {};
    if (
      (!profile?._id && isEmpty(profile?.Profile?.type)) ||
      (id && isEmpty(profile?.Profile))
    ) {
      errors.Profile = "Profile Image Required";
    }
    if (isEmpty(profile.FirstName)) {
      errors.FirstName = "FirstName Required";
    }
    if (isEmpty(profile.LastName)) {
      errors.LastName = "LastName Required";
    }

    if (isEmpty(profile.Mobile)) {
      errors.Mobile = "Mobile Number is  Required";
    }
    if (profile.Mobile && !isValidPhoneNumber(profile.Mobile)) {
      errors.Mobile = "Enter Valid Mobile number";
    }
    if (isEmpty(profile.EmailId)) {
      errors.EmailId = "EmailId is  Required";
    }
    if (!isEmpty(profile?.EmailId) && !emailRegex.test(profile.EmailId)) {
      errors.EmailId = "Enter Valid Email";
    }
    if (isEmpty(profile.Gender)) {
      errors.Gender = "Gender is  Required";
    }
    if (isEmpty(profile.DateofBirth)) {
      errors.DateofBirth = "DateofBirth is  Required";
    }
    if (!isEmpty(profile?.Password)) {
      if (!config.PASSWORD.test(profile?.Password)) {
        errors.Password =
          "Password Must be AlphaNumeric and between 8-15 characters.";
      }
    }
    console.log(
      "jhshfshjkfhshkfsf",
      !isEmpty(profile?.Password),
      !config.PASSWORD.test(profile?.Password)
    );
    return errors;
  };
  console.log("shgshffsf", profile);
  const onsubmit = async () => {
    let Id = LoadToast("Validating");
    let error = Validation();
    console.log("gjghjg", error);
    if (isEmpty(error)) {
      let senddata = { ...{ action: "profile_add" }, ...profile };

      let Resp = await axiosFile.LoginReg(senddata);
      console.log("resppppp", Resp, senddata);
      if (Resp.success == "success") {
        dispatch({
          type: "Login",
          Login: {
            User: {
              payload: Resp?.data,
            },
          },
        });
        updateToast(Id, "Updated Successfully", "success");
      } else {
        updateToast(Id, Resp?.msg ?? "Try Again", "error");
        SetError(Resp?.error);
      }
    } else {
      updateToast(Id, "Try Again", "error");
      SetError(error);
    }
  };
  console.log("profile?.Address", profile?.Address);
  /**lp */

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div className="sec_border acnt_secborder">
                      <div className="account_bannerimg">
                        <div className="account_profile_whole">
                          <div className="acnt_profileedit_align">
                            <div className="acnt_profile_edit">
                              <img
                                src={require("../../assets/images/icons/camera.png")}
                              />
                              <input
                                className="acnf_profile_editinp"
                                type="file"
                                id="Profile"
                                onChange={(e) => Onchange(e)}
                              />
                            </div>
                          </div>
                          <img
                            className="acnt_profile_img"
                            src={
                              profile?.Profile
                                ? typeof profile?.Profile == "object"
                                  ? window.URL.createObjectURL(profile?.Profile)
                                  : `${config.AccountImage}/${profile?._id}/profile/${profile?.Profile}`
                                : require("../../assets/images/profilelogo.png")
                            }
                          />
                        </div>
                      </div>
                      <span className="error-msg mt-4">{Error?.Profile}</span>
                    </div>

                    {/* profile details */}

                    <>
                      <div className="mdv_profiledtls">
                        <div className="mdv_border_div">
                          <p className="mdv_profilettl"></p>
                        </div>
                        <Row className="mdv_inputrow">
                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">First Name</p>
                            <input
                              className="mdv_cmninput"
                              autoComplete="off"
                              type="FirstName"
                              placeholder="john@delpick.com"
                              id="FirstName"
                              value={profile?.FirstName}
                              onChange={(e) => Onchange(e)}
                            />
                            {Error?.FirstName && (
                              <span className="error-msg">
                                {Error.FirstName}
                              </span>
                            )}
                          </Col>

                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">Last Name</p>
                            <input
                              className="mdv_cmninput"
                              autoComplete="off"
                              type="BrandDescription"
                              placeholder="ourshopdescribe..."
                              id="LastName"
                              value={profile?.LastName}
                              onChange={(e) => Onchange(e)}
                            />
                            {Error?.LastName && (
                              <span className="error-msg">
                                {Error.LastName}
                              </span>
                            )}
                          </Col>
                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">EmailId</p>
                            <input
                              className="mdv_cmninput"
                              autoComplete="off"
                              type="email"
                              placeholder="EmailId"
                              id="EmailId"
                              value={profile?.EmailId}
                              onChange={(e) => Onchange(e)}
                            />
                            {Error?.EmailId && (
                              <span className="error-msg">{Error.EmailId}</span>
                            )}
                          </Col>
                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">Mobile</p>
                            {/* <input className='mdv_cmninput' autoComplete="off" type="number" placeholder="Mobile" id='Mobile' value={profile?.Mobile} onChange={(e) => Onchange(e)} /> */}
                            <PhoneInput
                              className="new_phoneinp"
                              placeholder="Enter phone number"
                              id="Mobile"
                              value={profile?.Mobile}
                              onChange={(val) => Onchange(val, "Mobile")}
                              defaultCountry="US"
                              country="US"
                              countries={["US","IN"]}
                              // onCountryChange={(val)=>Onchange(val,"Mobile","country")}
                            />
                            {Error?.Mobile && (
                              <span className="error-msg">{Error.Mobile}</span>
                            )}
                          </Col>

                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">DOB</p>
                            <input
                              className="mdv_cmninput"
                              autoComplete="off"
                              type="date"
                              placeholder="DateofBirth"
                              id="DateofBirth"
                              value={profile?.DateofBirth}
                              onChange={(e) => Onchange(e)}
                            />
                            {Error?.DateofBirth && (
                              <span className="error-msg">
                                {Error.DateofBirth}
                              </span>
                            )}
                          </Col>
                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">Gender</p>
                            <Select
                              options={GenderOption}
                              value={{
                                label: profile?.Gender,
                                value: profile?.Gender,
                              }}
                              styles={stylesgraybg}
                              onChange={(e) => Onchange(e, "Gender")}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Category"
                            />
                            {Error?.Gender && (
                              <span className="error-msg">{Error.Gender}</span>
                            )}
                          </Col>
                          <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">
                              Password (The Entering Password will be your New
                              Password)
                            </p>
                            <div className="position-relative">
                              <input
                                className="mdv_cmninput"
                                autoComplete="off"
                                type={
                                  passwordText == "password"
                                    ? "password"
                                    : "text"
                                }
                                placeholder="Password"
                                id="Password"
                                value={profile?.Password}
                                onChange={(e) => Onchange(e)}
                              />
                              <i
                                onClick={() =>
                                  setPasswordText(
                                    passwordText == "text" ? "password" : "text"
                                  )
                                }
                                className={
                                  passwordText == "password"
                                    ? "fa-solid fa-eye-slash abso_eye"
                                    : "fa-solid fa-eye abso_eye"
                                }
                              ></i>
                            </div>
                            {Error?.Password && (
                              <span className="error-msg">
                                {Error.Password}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div className="mdv_update_dualbtn mt-3">
                        <button
                          className="blue_btn mdv_update"
                          onClick={() => {
                            onsubmit();
                          }}
                        >
                          Submit
                        </button>
                        {pathname.includes("add") && (
                          <button className="seconday_btn">Clear</button>
                        )}
                      </div>
                    </>
                    {/* end of notification settings details */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccounts;
