import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "../actions/common";
import { LoadToast, updateToast } from "../actions/common";
import useAxiosFile from "../actions/useaxios";

import delpick from "../assets/images/delpick.png";

function ForgotPassword() {
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const initdata = {
    EmailId: "",
  };
  const [formdata, SetFormdata] = useState(initdata);
  const [error, SetError] = useState({});
  const dispatch = useDispatch();
  const { EmailId } = useSelector((state) => state.seller_panel.User);

  {
    console.log("hdhhdh", formdata);
  }
  const OnChange = (e) => {
    const { id, value } = e.target;
    SetFormdata({ ...formdata, ...{ [id]: value } });
  };

  const formvalidation = async (data) => {
    console.log("validaion", data);

    var validationErr = {};
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (data.EmailId == "") {
      validationErr.EmailId = "Email cannot be empty";
    } else if (data.EmailId != "") {
      if (!emailRegex.test(data.EmailId)) {
        validationErr.EmailId = "Enter valid email";
      }
    }
    return validationErr;
  };

  const Submit = async () => {
    const toastid = LoadToast();
    var resp = await formvalidation(formdata);
    console.log("ggg", resp);
    if (!isEmpty(resp)) {
      SetError(resp);
      updateToast(toastid, "fix", "error");
      console.log("ugdfy7sdgfg", resp);
    }

    if (isEmpty(resp)) {
      console.log("fgdfgfdgdgdg");
      let senddata = formdata;
      senddata.action = "forgot";
      let Resp = await axiosFile.LoginReg(formdata);
      console.log("bgfdgdf", Resp);
      if (Resp.success == "success") {
        updateToast(toastid, "Otp Send To Mail", "info");
        dispatch({
          type: "Login",
          Login: {
            User: {
              EmailId: formdata?.EmailId,
            },
          },
        });
        navigate("/otpverification", { state: { EmailId: formdata?.EmailId } });
      } else {
        SetError(Resp?.error);
        updateToast(toastid, Resp?.validate ?? "fix", "error");
      }
    }
  };
  return (
    <div className="singin_bg">
      {/* <img
        className="singin_abs"
        src={require("../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs wd_ht_unst" src={delpick} alt="" />
      <div className="sigin_form">
        <div
          className="frgtpw_skip"
          onClick={() => {
            navigate("/");
          }}
        >
          <h5>Forgot Your password?</h5>
          <p className="sigin_skip">Skip</p>
        </div>
        <p className="frgtpw_hint">
          Enter the Email Address associated with your Delpick account.
        </p>
        <div className="centering_sigin centering_frgtpw">
          <div className="single_center">
            <label className="signin_labels" for="mobile">
              Email Address
            </label>
            <br />
            <input
              className="sigin_inputs"
              type="text"
              placeholder="Enter your EmailId"
              id="EmailId"
              name="EmailId"
              onChange={(e) => OnChange(e)}
            />
            {error?.EmailId && <span>{error?.EmailId}</span>}
          </div>
        </div>

        <div className="siginin_btn_dtl">
          {/* <Link to="/changepw"> */}
          <button className="blue_btn send_btn" onClick={() => Submit()}>
            Send
          </button>
          {/* </Link> */}
        </div>
        <p className="reg_bottomtxt">
          Do you remember it?{" "}
          <span>
            <Link to="/signin">Log In</Link>
          </span>
        </p>
      </div>
    </div>
  );
}

export default ForgotPassword;
