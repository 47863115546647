import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../actions/config";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import useAxiosFile from "../../actions/useaxios";

import delpick from "../../assets/images/delpick.png";
import locationCom from "../../assets/svg/Location.svg";
import homeCom from "../../assets/svg/home.svg";
import clockCom from "../../assets/svg/clock.svg";
import coolIconCom from "../../assets/svg/coolicon.svg";

function StoreImages() {
  const dispatch = useDispatch();
  const { home, location, clock, window } = useSelector(
    (state) => state.seller_panel.steppergo
  );
  const navigate = useNavigate();
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  console.log("Zzzx", payload);
  const locate = useLocation();
  const axiosFile = useAxiosFile();
  console.log("sgdvdg", locate);
  const [error, SetError] = useState({});
  const [form, setForm] = useState();
  const [showImages, setShowImages] = useState({ StoreImages: [] });
  const [load, Setload] = useState(false);

  console.log("sds", locate);

  const { Asxds } = localStorage;
  const [Error, setError] = useState({});
  const local = DecryptData(Asxds);
  console.log("fere", form);

  useEffect(() => {
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: true,
          clock: true,
          window: true,
          dummyimage: false,
        },
      },
    });
    setForm(locate?.state);
  }, []);

  const changeStepper = () => {
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: true,
          clock: true,
          window: true,
          dummyimage: false,
        },
      },
    });
  };

  const image = (data) => {
    Setload(false);
    if ([...data].some((it) => !it.type.includes("image"))) {
      toast("Except Image other formats are eliminated automatically", {
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
    }
    var data = [...data].filter((it) => it.type.includes("image"));
    setShowImages({ StoreImages: data });
  };
  const Validation = () => {
    let error = {};
    if (isEmpty(showImages.StoreImages))
      error.StoreImages = "Upload Atleast one image or skip";
    return error;
  };

  const onsubmit = async () => {
    Setload(true);
    var validate = Validation();

    const toastid = LoadToast();
    if (isEmpty(validate)) {
      var sendata = {
        StoreImages: showImages.StoreImages,
        action: "StoreImage",
        ShopId: local.shopid,
      };
      if (showImages?.StoreImages != "") {
        let Resp = await axiosFile.addsellershopimage(sendata);
        console.log("resppppp", Resp);
        if (Resp?.data) {
          console.log("hasvdgh");
          updateToast(toastid, Resp?.msg, "success");
          localStorage.removeItem("Asxds");
          navigate("/shoplist");
        } else {
          Setload(false);
          updateToast(toastid, "Try Again", "error");
        }
      }
    } else {
      Setload(false);
      setError(validate);
      updateToast(toastid, "Fix all validation", "error");
    }
  };

  return (
    <div className="regwhole_bg acnt_regwhole_bg">
      {/* <img
        className="singin_abs reg_abs "
        src={require("../../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs wd_ht_unst" src={delpick} alt="" />

      <Container>
        <Row className="justify-content-center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            <div className="reg_top_dtls">
              <h6 className="reg_top_title">Store Images</h6>
              {/* <p className="reg_skip_txt">
                <Link to="/">skip</Link>
              </p> */}
            </div>

            <Row className="mt-5">
              <Col className="mb-3" xxl={6} lg={6} md={6}>
                {console.log("gdsd", showImages)}

                {/* { form?.StoreImages?.map((val)=>{ */}
                <div className="mvv_griddtls storeimages_grid">
                  {
                    // showImages?.StoreImages.length > 0 &&
                    (showImages?.StoreImages).map((val) => {
                      console.log("sas", val);
                      return (
                        <div className="mvv_gridsingle">
                          <img
                            className="mvv_shopimg"
                            src={
                              val
                                ? URL.createObjectURL(val)
                                : require("../../assets/images/store.png")
                            }
                          />
                        </div>
                      );
                    })
                  }
                </div>
              </Col>

              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <div className="storeimage_uploadwhole">
                  <img
                    src={require("../../assets/images/icons/Addimage.png")}
                  />
                  <p className="strimg_uploadfile_hint">
                    <span className="error-msg">Upload a file </span>or drag and
                    drop
                    <br /> Png, Jpg
                  </p>

                  <input
                    type="file"
                    className="strimg_fileinput"
                    id="StoreImages"
                    onChange={(e) => image(e.target.files)}
                    multiple
                  />
                  <span className="error-msg">{Error?.StoreImages}</span>
                </div>
                <div className="w-100 text-end">
                  <button
                    disabled={load}
                    onClick={() => onsubmit()}
                    className="blue_btn storeimg_bluebtn"
                  >
                    {load ? "Loading..." : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="stepper">
        {home == true ? (
          // <img src={require("../../assets/images/icons/bluehome.png")} />
          <img src={homeCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/shop.png")} alt="" />
        )}

        <p className={home == true ? "blue_line" : ""}>------</p>
        {location == true ? (
          // <img src={require("../../assets/images/icons/bluelocation.png")} />
          <img src={locationCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/location.png")} />
        )}
        <p className={location == true ? "blue_line" : ""}>------</p>
        {clock == true ? (
          // <img src={require("../../assets/images/icons/blueclock.png")} />
          <img src={clockCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/Clock.png")} />
        )}
        <p className={clock == true ? "blue_line" : ""}>------</p>
        {window == true ? (
          // <img src={require("../../assets/images/icons/bluewindows.png")} />
          <img src={coolIconCom} />
        ) : (
          <img src={require("../../assets/images/icons/window.png")} />
        )}
        <p className={window == true ? "blue_line" : ""}>------</p>
        <img src={require("../../assets/images/icons/dummyimage.png")} />
      </div>
    </div>
  );
}

export default StoreImages;
