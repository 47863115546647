import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Dropdown,
  Accordion,
  Card,
  Offcanvas,
  Modal,
  DropdownButton,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";
import { Draggable } from "react-drag-reorder";
import useAxiosFile from "../../actions/useaxios";
import {
  CurrentTime,
  isDate,
  isEmpty,
  LoadToast,
  NumANdDotOnly,
  NumberOnly,
  updateToast,
} from "../../actions/common";
import config from "../../actions/config";
import { toast } from "react-toastify";

function MenuManager() {
  const { toggle, payload, token } = useSelector(
    (state) => state.seller_panel.User
  );
  const [maintabActive, setMaintabActive] = useState("overview");
  const [newaccordIcon, setNewaccordIcon] = useState("down");
  const [newaccordIconOne, setNewaccordIconOne] = useState("down");
  const [modalBodyContent, showModalBodyContent] = useState("");
  const [commonCanvaEdit, showCommonCanvaEdit] = useState(false);

  const [showcommonCanva, setShowcommonCanva] = useState(false);
  const [showOffcanvaDetails, setShowOffcanvaDetails] = useState("");

  const handleClosecommonCanva = () => {
    // setShowcommonCanva(false);
    setError({});
    setSaveChanges("unsavedChanges");
  };
  const handleShowcommonCanva = () => setShowcommonCanva(true);
  const [selectedOption, setSelectedOption] = useState(null);

  const [saveChanges, setSaveChanges] = useState(false);

  const handleCloseSaveChanges = () => {
    CancelCanva();
    setSaveChanges("");
  };
  const handleShowSaveChanges = () => setSaveChanges(true);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "35px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const columns = [
    { dataField: "Name", text: "Name" },
    {
      dataField: "Options",
      text: "Options",
      formatter: (cell, row) => (
        <div>
          {!isEmpty(cell)
            ? cell
                ?.reduce((acc, it) => acc + "," + it.key, "")
                ?.toString()
                ?.substr(
                  1,
                  cell?.reduce((acc, it) => acc + "," + it.key, "")?.toString()
                    ?.length
                )
            : "No options added"}
        </div>
      ),
    },
    {
      dataField: "ProductId",
      text: "Used In",
      formatter: (cell, row) => (
        <div>
          {!isEmpty(cell)
            ? cell
                ?.reduce((acc, it) => acc + "," + it.ProductName, "")
                ?.toString()
                ?.substr(
                  1,
                  cell?.reduce((acc, it) => acc + "," + it.key, "")?.toString()
                    ?.length
                )
            : "No options added"}
        </div>
      ),
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (cell, row) => (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li
              onClick={() => {
                // setShowcommonCanva(true)

                setShowOffcanvaDetails("edit");
                setForm({
                  ...form,
                  ...row,
                  ...{
                    name: "edit",
                    from: "modifiers",
                  },
                });
              }}
            >
              <a class="dropdown-item">
                <i class="fa-solid fa-pencil mm_moddro_tabic"></i>Edit Details
              </a>
            </li>
            {/* <li><hr class="dropdown-divider"/></li> */}
            <li
              onClick={() => {
                // setShowcommonCanva(true)

                setSaveChanges("Delete");
                setForm({
                  ...form,
                  ...row,
                  ...{
                    name: "Delete",
                    from: "modifiers",
                  },
                });
              }}
            >
              <a class="dropdown-item">
                <i class="fa-solid fa-trash-can mm_moddro_tabic"></i>Delete
              </a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        type="button"
        className="mm_dropdown_custbtn"
        onClick={() => {
          decoratedOnClick();
        }}
      >
        {children}
      </button>
    );
  }

  /**lp */
  const axiosFile = useAxiosFile();
  const [shoplist, SetShopList] = useState([]);
  const [SelectedShop, SetSelectedShop] = useState({});
  const [sellercat, setsellercat] = useState([]);
  const [SellerCategoryId, SetSellerCategoryId] = useState("");
  const [ProductList, SetProductList] = useState({});
  const [form, setForm] = useState({
    RequiredMultiple: {
      enable: false,
    },
  });
  const [Error, setError] = useState({});
  const [Trigger, setTrigger] = useState("");
  const [eyeshow, seteyeshow] = useState(false);
  const [search, setsearch] = useState("");
  const [Options, setOptions] = useState({});
  const [menuoption, setmenuoption] = useState([]);

  useEffect(() => {
    if (payload?._id) {
      getshop();
    }
  }, [payload?._id]);

  const getshop = async () => {
    let Resp = await axiosFile.getshop({
      SellerId: payload?._id,
      from: "sel_cat",
    });
    SetShopList(Resp?.data);
    console.log("slkjdlksfsf", Resp, payload);
    SetSelectedShop(Resp?.data?.[0]);
  };
  const getsellercat = async () => {
    var resp = await axiosFile.getsellercatList({
      from: "menu-seller",
      SellerId: payload?._id,
      ShopId: SelectedShop?._id,
      search,
    });
    setTrigger("");
    setsellercat(resp.data);
  };

  useEffect(() => {
    if (!isEmpty(showOffcanvaDetails)) {
      return setShowcommonCanva(true);
    }
    setShowcommonCanva(false);
  }, [showOffcanvaDetails]);

  useEffect(() => {
    if (
      maintabActive == "overview" &&
      (!isEmpty(SelectedShop) ||
        !isEmpty(Trigger) ||
        (!isEmpty(search) && maintabActive == "overview"))
    ) {
      getsellercat();
      setTrigger("");
    }

    if (
      maintabActive == "modifiers" &&
      (!isEmpty(SelectedShop) ||
        !isEmpty(Trigger) ||
        (!isEmpty(search) && maintabActive == "modifiers"))
    ) {
      getmenu();
      setTrigger("");
    }
  }, [SelectedShop?._id, Trigger, search, maintabActive]);
  useEffect(() => {
    if (
      SellerCategoryId &&
      typeof ProductList?.[SellerCategoryId] == "undefined"
    ) {
      getproduct();
    }
  }, [SellerCategoryId]);
  useEffect(() => {
    if (!isEmpty(showOffcanvaDetails)) {
      setShowcommonCanva(true);
    } else setShowcommonCanva(false);
  }, [showOffcanvaDetails]);
  const getproduct = async (cat) => {
    let SellerCategory = cat ? cat : SellerCategoryId;
    var resp = await axiosFile.getproduct({
      from: "seller",
      SellerCategory: cat ? cat : SellerCategoryId,
      SellerId: payload?._id,
      ShopId: SelectedShop?._id,
    });
    if (cat) {
      // let findindex = ProductList.findindex(it=>it)
      return SetProductList({
        ...ProductList,
        ...{
          [SellerCategoryId]: {
            List: ProductList?.[SellerCategoryId]?.["List"]
              ? cat
                ? resp?.data
                : [...ProductList?.[SellerCategoryId]?.["List"], ...resp?.data]
              : resp?.data,
            loader: resp?.loader,
            page: resp.page,
          },
        },
      });
    }
    SetProductList({
      ...ProductList,
      ...{
        [SellerCategoryId]: {
          List: ProductList?.[SellerCategoryId]?.["List"]
            ? [...ProductList?.[SellerCategoryId]?.["List"], ...resp?.data]
            : resp?.data,
          loader: resp?.loader,
          page: resp.page,
        },
      },
    });
  };
  const getmenu = async (ata) => {
    var resp = await axiosFile.menudetail({
      name: "list",
      ShopId: SelectedShop?._id,
      SellerId: payload?._id,
      ata: ata,
      search,
    });
    console.log("kjsdhjkshfs", resp, SelectedShop, ata);
    if (ata) setmenuoption(resp?.data);
    else setsellercat(resp?.data);
    setTrigger("");
  };
  const getChangedPos = (currentPos, newPos) => {
    setForm({
      ...form,
      ...{
        sortable: [...(form?.["sort"] ? form?.["sort"] : []), newPos],
      },
    });
  };

  const OnChange = (e) => {
    toast.dismiss();
    const { id, value, files } = e?.target;

    setError({});
    if (files?.length > 0) {
      if (id == "ProductImage") {
        if (files[0]?.type?.includes("image")) {
          let formData = {
            ...form,
            ...{ [e?.target?.id]: files[0] },
          };
          setForm(formData);
        } else {
          updateToast(null, "Upload Images Only", "error", "pos");
        }
      }
      if (id == "ProductImageMultiple") {
        if (
          files?.length > 0 &&
          files?.length +
            (form?.ProductImageMultiple?.length
              ? form?.ProductImageMultiple?.length
              : 0) <
            5 &&
          [...files].some((it) => it.type.includes("image"))
        ) {
          let formData = {
            ...form,
            ...{
              [id]: [
                ...(isEmpty(form.ProductImageMultiple)
                  ? []
                  : form.ProductImageMultiple),
                ...[...files].filter((it) => it.type.includes("image")),
              ],
            },
          };
          setForm(formData);
        }
        if (
          files?.length > 0 &&
          files?.length +
            (form?.ProductImageMultiple?.length
              ? form?.ProductImageMultiple?.length
              : 0) >
            4 &&
          [...files].some((it) => it.type.includes("image"))
        ) {
          updateToast(null, "Add Only 4 Images", "error", "pos");
        }
        if (
          files?.length > 0 &&
          files?.length <= 4 &&
          [...files].some((it) => !it.type.includes("image"))
        ) {
          updateToast(
            null,
            "Exept Image Other types of files will be terminated",
            "error",
            "pos"
          );
        }
      }
      // return;
    } else {
      if (id == "Tax" || id == "Price") {
        return setForm({
          ...form,
          ...{ [id]: NumANdDotOnly(value) },
        });
      } else if (id == "Quantity") {
        return setForm({
          ...form,
          ...{ [id]: NumberOnly(value) },
        });
      }
      return setForm({
        ...form,
        ...{ [id]: value },
      });
    }
  };

  const CancelCanva = () => {
    setShowcommonCanva("");
    setShowOffcanvaDetails("");
    setForm({});
    setSaveChanges("");
  };

  const Validation = () => {
    let validation = {};
    console.log("form>>>>", form);
    // if (form?.from == "overview") {
    if (form?.name == "Category" || form?.name == "Edit") {
      if (isEmpty(form?.["CategoryName"]))
        validation["CategoryName"] = "CategoryName required";
    }

    if (form?.name == "New Item" || form?.name == "Edit Item") {
      if (isEmpty(form?.["ProductName"]))
        validation["ProductName"] = "Product Name required";
      // if (isEmpty(form?.["Price"])) validation["Price"] = "Price required";
      if (isEmpty(form?.["Tax"])) validation["Tax"] = "Tax required";
      if (isEmpty(form?.["SellerCategory"]))
        validation["SellerCategory"] = "Category required";
      // if (isEmpty(form?.["AdditionalInfo"]))
      //   validation["AdditionalInfo"] = "Additional-Info required";

      //   if (
      //     isEmpty(form?.ProductImageMultiple)
      //  ) {
      //   validation.ProductImageMultiple = "Product Image Multiple Required";
      //  }

      //   if (
      //     form?._id&&
      //   !form?.ProductImage
      // ) {
      //   validation['ProductImage'] = "Product Image Required";
      // }
      // if (!form?.ProductImage?.type&&typeof(form?.ProductImage)!=='string') {
      //   validation['ProductImage'] = "Product Image Required";
      // }
    }
    // if (form?.from == "modifiers") {
    if (form?.name == "delete" || form?.name == "edit" || form?.name == "add") {
      let minimum = !isEmpty(form?.min) ? form?.min : form?.RequiredMultiple?.min;
      let maximum = !isEmpty(form?.max) ? form?.max : form?.RequiredMultiple?.max;

      if (isEmpty(form?.["Name"])) validation["Name"] = "Name required";
      if (isEmpty(form?.Options)) validation["Options"] = "Options required";
      if (
        form?.RequiredMultipleEnable &&
        form?.Required &&
        form?.Required != "false"
      ) {
        if(isEmpty(minimum)) {
          validation.min = "Enter Minimum Selection";
        }
        if (isEmpty(maximum)) {
          validation.max = "Enter Maximum Selection";
        }
        if (!isEmpty(minimum) && !isEmpty(maximum)) {
          if (Number(minimum > maximum)) {
            validation.min = "Minimum Selection must be less than Maximum Selection";
          }
        }
      }
    }
    // }

    if (!isEmpty(validation)) {
      console.log("errorrrr", validation);
      if (validation) {
        var scrollyoffset =
          document.querySelectorAll(".error_modal_red")[0].offsetTop;
        console.log(
          document.getElementById("mm_offcanva_itemscoll"),
          "niviiiiii"
        );
        if (document.getElementById("mm_offcanva_itemscoll")) {
          document
            .getElementById("mm_offcanva_itemscoll")
            .scrollTo(0, scrollyoffset);
        }
      }
      setError(validation);
      return true;
    }
    return false;
  };

  const SaveChanges = async () => {
    setError({});
    const id = LoadToast();

    if (Validation()) {
      setSaveChanges("");
      return updateToast(id, "fix", "", "pos");
    }
    // return;
    if (!isEmpty(form?.AdditionalInfo))
      form.AdditionalInfo = JSON.stringify(form?.AdditionalInfo);
    else form.AdditionalInfo = JSON.stringify([]);
    console.log("skjdhsdshjd", form);
    let senddata = form;
    let instfile = [];
    if (senddata?.ProductImageMultiple?.length > 0) {
      senddata?.ProductImageMultiple?.map((val) => {
        console.log("skfkskfsfs", val);
        if (val?._id) {
          if (isEmpty(senddata?.PrevImage)) {
            senddata.PrevImage = [];
          }
          senddata?.PrevImage.push(val);
        } else {
          instfile.push(val);
        }
      });
    }
    if (!isEmpty(senddata?.PrevImage)) {
      senddata.PrevImage = JSON.stringify(senddata?.PrevImage);
    }
    if (!isEmpty(senddata?.ProductImageMultiple)) {
      senddata.ProductImageMultiple = instfile;
    }
    console.log("skjkfhshfsj", senddata);
    let Resp = await axiosFile?.[
      form?.ProductName || form?.name.includes("Item")
        ? "AddProduct"
        : form?.from == "modifiers"
        ? "menudetail"
        : "addsellercat"
    ](senddata);

    if (Resp?.error) {
      setError(Resp?.error);
      return updateToast(id, "fix", "", "pos");
    }
    if (
      form?.ProductName ||
      form?.name == "New Item" ||
      form?.name == "Edit Item" ||
      form?.name == "Delete Item"
    ) {
      getproduct(form?.SellerCategory);
    }

    setTrigger("Category");
    CancelCanva();
    updateToast(id, Resp?.msg, Resp?.success);
  };

  const OnChangeMenuOption = (e, index) => {
    const { value, id } = e?.target;
    console.log("lsjfksfsf", id, id == "value");
    if (id == "value") {
      console.log("dskhdskfs");
      return setOptions({ ...Options, ...{ [id]: NumANdDotOnly(value) } });
    }
    setOptions({ ...Options, ...{ [id]: value } });
  };
  console.log("skhshjkfhsjs", Options);
  //

  /**lp */

  const RemoveImage = (type, ind) => {
    if (type == "single") {
      setForm({
        ...form,
        ...{
          ProductImageMultiple: [
            ...form?.ProductImageMultiple?.slice(0, ind),
            ...form?.ProductImageMultiple?.slice(
              ind + 1,
              form?.ProductImageMultiple?.length
            ),
          ],
        },
      });
    } else if (type == "all") {
      setForm({
        ...form,
        ...{
          ProductImageMultiple: [],
        },
      });
    }
  };
  // window.addEventListener("DOMContentLoaded", (event) => {
  // let down = document.getElementById('rules_accordion');
  // down.addEventListener('click',function () {
  //     down.scrollBottom += 10;
  // });
  // })

  const handleGetScrollPosition = () => {
    setTimeout(function () {
      const newScrollPosition =
        document.getElementById("rules_accordion").offsetTop;

      console.log("nivvvi", newScrollPosition);

      document.getElementById("modifier_scroll").scrollTo(0, newScrollPosition);
    }, 500);
  };
  return (
    <>
      {console.log("hjhhfjxhdhghj", form)}
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div className="menu_management_whole">
                      <p className="page_title">Menu Manager</p>
                      <div className="mm_maintabs">
                        {["overview", "modifiers"].map((it) => {
                          return (
                            <p
                              className={
                                maintabActive == it?.toLowerCase()
                                  ? "mm_maintab_name mm_activedmain"
                                  : "mm_maintab_name"
                              }
                              onClick={() => {
                                setsearch("");
                                setForm({ ...{}, ...{ from: it } });
                                setMaintabActive(it?.toLowerCase());
                              }}
                            >
                              {it}
                            </p>
                          );
                        })}
                      </div>
                      {maintabActive == "overview" ? (
                        <div className="mm_tabed_contents mt-4">
                          <Row>
                            <Col md={8}>
                              <p className="page_title">
                                {SelectedShop?.BrandName} ({CurrentTime()})
                              </p>
                            </Col>
                            <Col md={4}>
                              <div className="mm_right_roundedicons">
                                <button className="mm_round_btns">
                                  <i class="fa-solid fa-eye" />
                                </button>
                                <Link to="/account" state={{ from: "menu" }}>
                                  <button className="mm_round_btns mm_calendar_btn">
                                    <i class="fa-regular fa-calendar" />
                                  </button>
                                </Link>

                                <div class="dropdown table_viewdropdown">
                                  <button
                                    class="btn table_viewdrop gear_dropdown dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <i class="fa-solid fa-gear" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    {/* <li>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          setSaveChanges("");
                                          setSaveChanges(
                                            "Rearrange Categories"
                                          );
                                        }}
                                      >
                                        <i class="fa-solid fa-grip-vertical mm_moddro_tabic"></i>
                                        Rearrange Categories
                                      </a>
                                    </li> */}
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          setSaveChanges("linkedStores");
                                        }}
                                      >
                                        <i class="fa-solid fa-store mm_moddro_tabic"></i>
                                        View Linked Store(s)
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Col>
                            <div className="mm_searchdrop_align">
                              <div className="whole_searchbar white_bg">
                                <img
                                  className="search_glass"
                                  src={require("../../assets/images/icons/Search.png")}
                                />
                                <input
                                  value={search}
                                  onChange={(e) => setsearch(e?.target?.value)}
                                  placeholder="Search for an item overview"
                                />
                              </div>

                              <div className="top_two_dropdowns">
                                <DropdownButton
                                  title={SelectedShop?.BrandName}
                                  onSelect={(e) =>
                                    SetSelectedShop(JSON.parse(e))
                                  }
                                >
                                  {shoplist.map((it) => {
                                    return (
                                      <Dropdown.Item
                                        eventKey={JSON.stringify(it)}
                                      >
                                        {it.BrandName}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </DropdownButton>

                                <Dropdown
                                  onSelect={(e) => {
                                    if (e == "New Item") {
                                      getmenu(true);
                                    }
                                    setShowOffcanvaDetails(e);
                                    setForm({
                                      ...form,
                                      ...{
                                        ["name"]: e,
                                        ShopId: SelectedShop?._id,
                                        SellerId: payload?._id,
                                      },
                                      // ...(e == "New Item" ? { from: e } : {}),
                                    });
                                  }}
                                >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    Add
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item eventKey={"Category"}>
                                      New Category
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={"New Item"}>
                                      New Item
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Row>

                          {sellercat?.length > 0 ? (
                            <Row className="mt-4">
                              {sellercat.map((it) => {
                                return (
                                  <Accordion
                                    activeKey={SellerCategoryId}
                                    // onChange={(e,expanded)=>SetSellerCategoryId(expanded)}
                                    className="mm_newaccord"
                                  >
                                    <Card>
                                      <Card.Header>
                                        <Row>
                                          <Col sm={6}>
                                            <div className="mm_leftaccord_contents">
                                              <p className="mm_leftaccord_ttl">
                                                {it?.CategoryName}
                                              </p>
                                              <span className="mm_leftaccord_hint">
                                                {new Date(it.available) !=
                                                "Invalid Date"
                                                  ? new Date(
                                                      it.available
                                                    ).getDate() ==
                                                    new Date().getDate()
                                                    ? "Not available Today "
                                                    : ""
                                                  : it.available}{" "}
                                                Category. {it.ProductId?.length}{" "}
                                                item
                                              </span>
                                            </div>
                                          </Col>

                                          <Col
                                            sm={6}
                                            className="d-flex align-items-center justify-content-end"
                                          >
                                            <div className="mm_accordright_whole">
                                              <span className="mm_leftaccord_hint">
                                                Price
                                              </span>
                                              <span className="mm_leftaccord_hint mm_leftaccord_hintwid">
                                                Item Availability
                                              </span>

                                              <Dropdown
                                                className="mm_accord_plaindrop"
                                                onSelect={(e) => {
                                                  setForm({
                                                    ...it,
                                                    ...{ name: e },
                                                  });
                                                  if (
                                                    [
                                                      "Delete",
                                                      "Available",
                                                      "Unavailable Today",
                                                      "Unavailable Indefinitely",
                                                    ].includes(e)
                                                  ) {
                                                    setSaveChanges(e);
                                                  }
                                                  if (e == "Edit")
                                                    setShowOffcanvaDetails(e);
                                                }}
                                              >
                                                <Dropdown.Toggle id="dropdown-basic">
                                                  <i class="fa-solid fa-ellipsis" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    eventKey={"Available"}
                                                  >
                                                    Available
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    eventKey={
                                                      "Unavailable Today"
                                                    }
                                                  >
                                                    Unavailable Today
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    eventKey={
                                                      "Unavailable Indefinitely"
                                                    }
                                                  >
                                                    Unavailable Indefinitely
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item
                                                    className="mm_ellips_droplink"
                                                    eventKey={"Edit"}
                                                  >
                                                    <i class="fa-solid fa-pencil" />
                                                    Edit Details
                                                  </Dropdown.Item>
                                                  {/* <Dropdown.Item
                                                    className="mm_ellips_droplink"
                                                    eventKey={"Rearrange Items"}
                                                    onClick={() => {
                                                      handleShowSaveChanges();
                                                      showModalBodyContent(
                                                        "rearrangeItems"
                                                      );
                                                    }}
                                                  >
                                                    <i class="fa-solid fa-grip-vertical" />
                                                    Rearrange Items
                                                  </Dropdown.Item> */}
                                                  <Dropdown.Item
                                                    className="mm_ellips_droplink"
                                                    eventKey={"Delete"}
                                                  >
                                                    <i class="fa-solid fa-trash-can" />
                                                    Delete
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              {/* <CustomToggle eventKey="0"> */}
                                              <i
                                                onClick={() => {
                                                  SetSellerCategoryId(
                                                    SellerCategoryId == it?._id
                                                      ? ""
                                                      : it?._id
                                                  );
                                                }}
                                                className={
                                                  it?._id == SellerCategoryId
                                                    ? "fa-solid fa-chevron-up"
                                                    : "fa-solid fa-chevron-down"
                                                }
                                              />
                                              {/* </CustomToggle> */}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={it?._id}>
                                        <Card.Body className="mm_accord_cardbody">
                                          {ProductList?.[it._id]?.List?.map(
                                            (item) => {
                                              return (
                                                <Row className="mm_accordbody_botborder">
                                                  <Col lg={7}>
                                                    <div className="mm_accord_lefbody">
                                                      <img
                                                        className="mm_accord_leftimg"
                                                        src={
                                                          !isEmpty(
                                                            item?.ProductImage
                                                          )
                                                            ? `${config.IMG_URL}/Product/${item?.ProductImage}`
                                                            : require("../../assets/images/dummy.png")
                                                        }
                                                      />
                                                      <p className="mm_accord_leftname">
                                                        {item.ProductName}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                  <Col
                                                    lg={5}
                                                    className="d-flex align-items-center justify-content-end"
                                                  >
                                                    <div className="mm_accord_rightbody">
                                                      <p className="mm_accord_rightprice">
                                                        {
                                                          SelectedShop?.CurrencySymbol
                                                        }{" "}
                                                        {item.Price}
                                                      </p>

                                                      <Dropdown
                                                        className="mm_accord_rightdrop"
                                                        onSelect={
                                                          (e) => {
                                                            setForm({
                                                              ...item,
                                                              ...{ name: e },
                                                            });
                                                            setSaveChanges(e);
                                                          }
                                                          // SaveChanges({})
                                                        }
                                                      >
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                          <div className="d-flex justify-content-start">
                                                            <i class="fa-solid fa-circle text-success"></i>
                                                            <p className="mm_accord_boxtxt">
                                                              {!item?.available
                                                                ? "available"
                                                                : new Date(
                                                                    item.available
                                                                  ) !=
                                                                  "Invalid Date"
                                                                ? new Date(
                                                                    item.available
                                                                  ).getDate() ==
                                                                  new Date().getDate()
                                                                  ? "Not available Today "
                                                                  : ""
                                                                : item?.available}
                                                            </p>
                                                          </div>
                                                          <i class="fa-solid fa-chevron-down mm_accord_rightdown"></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="scroll_men">
                                                          <Dropdown.Item
                                                            eventKey={
                                                              "Available"
                                                            }
                                                          >
                                                            Available
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            eventKey={
                                                              "Unavailable Today"
                                                            }
                                                          >
                                                            Unavailable Today
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            eventKey={
                                                              "Unavailable Indefinitely"
                                                            }
                                                          >
                                                            Unavailable
                                                            Indefinitely
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>

                                                      <i
                                                        class="fa-solid fa-pencil mm_accord_rightic mm_accord_pencilic"
                                                        onClick={() => {
                                                          setShowOffcanvaDetails(
                                                            "Edit Item"
                                                          );
                                                          getmenu(true);
                                                          setForm({
                                                            ...form,
                                                            ...{
                                                              name: "Edit Item",
                                                              SellerId:
                                                                payload?._id,
                                                              ShopId:
                                                                SelectedShop?._id,
                                                            },
                                                            ...item,
                                                            dat: "cat",
                                                          });
                                                        }}
                                                      ></i>

                                                      <i
                                                        class="fa-solid fa-trash-can mm_accord_rightic"
                                                        onClick={() => {
                                                          setSaveChanges(
                                                            "Delete"
                                                          );
                                                          setForm({
                                                            ...form,
                                                            ...{
                                                              name: "Delete Item",
                                                              SellerId:
                                                                payload?._id,
                                                              ShopId:
                                                                SelectedShop?._id,
                                                            },
                                                            ...item,
                                                          });
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              );
                                            }
                                          )}
                                          <Row
                                            onClick={() => {
                                              getmenu(true);
                                              setShowOffcanvaDetails(
                                                "New Item"
                                              );
                                              setForm({
                                                ...form,
                                                ...{
                                                  name: "New Item",
                                                  dat: "cat",
                                                  SellerCategory: it._id,
                                                  ShopId: SelectedShop?._id,
                                                  SellerId: payload?._id,
                                                },
                                              });
                                            }}
                                          >
                                            <div className="mm_accordbody_addmore">
                                              <img
                                                className="mm_accordbody_addimg me-1"
                                                src={require("../../assets/images/icons/add.png")}
                                              />
                                              <p>Add Item</p>
                                            </div>
                                          </Row>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                              })}
                            </Row>
                          ) : (
                            <div className="text-center">
                              No Category Found For this Shop
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mm_tabed_contents mt-4">
                          <div className="mm_searchdrop_align">
                            <div className="whole_searchbar white_bg">
                              <img
                                className="search_glass"
                                src={require("../../assets/images/icons/Search.png")}
                              />
                              <input
                                placeholder="Search for modifier"
                                onChange={(e) => setsearch(e?.target?.value)}
                              />
                            </div>
                            <button
                              className="blue_btn mm_addmodifier_btn"
                              onClick={() => {
                                setForm({
                                  ...form,
                                  ...{
                                    name: "add",
                                    from: "modifiers",
                                    ShopId: SelectedShop?._id,
                                  },
                                });
                                setShowOffcanvaDetails("add");
                              }}
                            >
                              + New Modifier
                            </button>
                          </div>
                          <div className="ffd_table allfinance_table">
                            <BootstrapTable
                              bootstrap4
                              keyField="id"
                              columns={columns}
                              data={sellercat}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* common offcanva */}

      <Offcanvas
        show={!isEmpty(showOffcanvaDetails)}
        className="mm_common_offcanva"
        onHide={handleClosecommonCanva}
        backdrop="static"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mm_common_canvattl">
            {/* {showOffcanvaDetails} */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mm_offcanva_body">
          {showOffcanvaDetails == "Category" ||
          showOffcanvaDetails == "Edit" ? (
            <>
              <div className={"mm_offcanva_stretch"}>
                <div className="px-3">
                  <h5 className="mm_offcanva_ttl">
                    {showOffcanvaDetails == "Edit" ? "" : "New Category"}
                  </h5>

                  {showOffcanvaDetails == "Edit" ? (
                    <>
                      <div className="mm_editcontent_modifier ">
                        <i class="fa-solid fa-circle-check text-success" />
                        <div>
                          <p className="mm_editconn_ttl">Available</p>
                          <p className="mm_offcanva_excladtls">
                            Customers can view and order this item during store
                            hours.
                          </p>
                        </div>
                      </div>
                      <div className="mm_editcont_unavailable mt-3">
                        <button className="empty_border_btn">
                          Marked as{" "}
                          {new Date(form?.available) != "Invalid Date"
                            ? new Date(form?.available).getDate() ==
                              new Date().getDate()
                              ? "Not available Today "
                              : ""
                            : form?.available}
                        </button>

                        <div class="dropdown table_viewdropdown">
                          <button
                            class="btn table_viewdrop dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                          >
                            <i class="fa-solid fa-ellipsis" />
                          </button>
                          <ul class="dropdown-menu">
                            <li
                              onClick={() =>
                                setForm({
                                  ...form,
                                  ...{ available: "Available" },
                                })
                              }
                            >
                              <a class="dropdown-item">Available</a>
                            </li>
                            <li
                              onClick={() =>
                                setForm({
                                  ...form,
                                  ...{ available: "Unavailable Today" },
                                })
                              }
                            >
                              <a class="dropdown-item">Unavailable Today</a>
                            </li>
                            <li
                              onClick={() =>
                                setForm({
                                  ...form,
                                  ...{ available: "Unavailable Indefinitely" },
                                })
                              }
                            >
                              <a class="dropdown-item">
                                Unavailable Indefinitely
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p className="mm_offcanva_excladtls mt-3">
                      <i class="fa-solid fa-circle-exclamation me-2"></i> you
                      can edit the availability of this category from{" "}
                      <b>{SelectedShop?.BrandName}</b> Shop after saving.
                    </p>
                  )}

                  <div className="mm_offcanva_inpttle mt-4">
                    <p className="mm_offcanva_inpname">Name</p>
                    <span className="mm_offcanva_req">Required</span>
                  </div>
                  <input
                    type="text"
                    className="mm_offcanva_nameinp mt-2"
                    placeholder="Enter Name"
                    id="CategoryName"
                    value={form?.CategoryName}
                    onChange={OnChange}
                  />
                  <span className="error-msg error_modal_red">
                    {Error?.CategoryName}
                  </span>

                  <div className="mm_offcanva_inpttle mt-4">
                    <p className="mm_offcanva_inpname">Description</p>
                    <span className="mm_offcanva_req">optional</span>
                  </div>

                  <textarea
                    className="mm_offcanva_textarea mt-2"
                    placeholder="Add a description (optional)"
                    rows="4"
                    cols="50"
                    value={form?.CategoryDescription}
                    id="CategoryDescription"
                    onChange={OnChange}
                  ></textarea>
                </div>

                <div className="mm_offcanva_footer">
                  <button
                    className="empty_btn"
                    onClick={handleClosecommonCanva}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={SaveChanges}
                    className="blue_btn mm_canvabtn"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </>
          ) : showOffcanvaDetails == "add" || showOffcanvaDetails == "edit" ? (
            <div className="mm_offcanva_stretch">
              <div className="mm_offcanva_itemscoll" id="modifier_scroll">
                <h5 className="mm_offcanva_ttl">
                  {showOffcanvaDetails == "edit" ? form?.Name : "New Modifier"}
                </h5>

                {/* end of edit modifier content */}
                {form?._id ? (
                  <>
                    <div className="mm_editcontent_modifier ">
                      <i class="fa-solid fa-circle-check text-success" />
                      <div>
                        <p className="mm_editconn_ttl">Available</p>
                        <p className="mm_offcanva_excladtls">
                          Customers can view and order this item during store
                          hours.
                        </p>
                      </div>
                    </div>
                    <div className="mm_editcont_unavailable mt-3">
                      <button className="empty_border_btn">
                        Marked as{" "}
                        {new Date(form?.available) != "Invalid Date"
                          ? new Date(form?.available).getDate() ==
                            new Date().getDate()
                            ? "Not available Today "
                            : ""
                          : form?.available}
                      </button>

                      <div class="dropdown table_viewdropdown">
                        <button
                          class="btn table_viewdrop dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa-solid fa-ellipsis" />
                        </button>
                        <ul class="dropdown-menu">
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Available" },
                              })
                            }
                          >
                            <a class="dropdown-item">Available</a>
                          </li>
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Unavailable Today" },
                              })
                            }
                          >
                            <a class="dropdown-item">Unavailable Today</a>
                          </li>
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Unavailable Indefinitely" },
                              })
                            }
                          >
                            <a class="dropdown-item">
                              Unavailable Indefinitely
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="mm_offcanva_excladtls mt-3">
                    <i class="fa-solid fa-circle-exclamation me-2"></i> you can
                    edit the availability of this item after saving.
                  </p>
                )}

                <div className="mm_offcanva_inpttle mt-4">
                  <p className="mm_offcanva_inpname">Name</p>
                  <span className="mm_offcanva_req">Required</span>
                </div>
                <input
                  type="text"
                  className="mm_offcanva_nameinp mt-2"
                  placeholder='Eg."Nachos"'
                  id="Name"
                  value={form?.Name}
                  onChange={OnChange}
                />
                <span className="error-msg error_modal_red">{Error?.Name}</span>
                <div className="mm_offcanva_inpttle mt-4">
                  <p className="mm_offcanva_inpname">Description</p>
                  <span className="mm_offcanva_req">Optional</span>
                </div>
                <input
                  type="text"
                  className="mm_offcanva_nameinp mt-2"
                  placeholder='Eg."Nachos"'
                  id="Description"
                  value={form?.Description}
                  onChange={OnChange}
                />
                {form?.name == "edit" && (
                  <>
                    <div className="mm_offcanva_inpttle mt-4">
                      <p className="mm_offcanva_inpname">Used IN</p>
                      <span className="mm_offcanva_req">
                        {form?.ProductId?.length} items
                      </span>
                    </div>
                    {/* <p className="mm_offcanva_modhint">{form?.ProductId?.length} items</p> */}
                    {/* <div className="whole_searchbar mm_offcanva_addmodifier white_bg mt-2">
                      <img
                        className="search_glass"
                        src={require("../../assets/images/icons/Search.png")}
                      />
                      <input placeholder="Search for an item" />
                    </div> */}
                  </>
                )}
                <hr className="my-4" />

                <Accordion className="mm_newaccord">
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col sm={10}>
                          <div className="mm_leftaccord_contents">
                            <p className="mm_leftaccord_ttl">Options</p>
                            <span className="mm_leftaccord_hint">
                              Give your customers a list of options to choose
                              from.
                            </span>
                          </div>
                        </Col>

                        <Col
                          sm={2}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="mm_accordright_whole">
                            <CustomToggle eventKey="0">
                              <i
                                className={
                                  newaccordIcon == "down"
                                    ? "fa-solid fa-chevron-down"
                                    : "fa-solid fa-chevron-up"
                                }
                                onClick={() =>
                                  setNewaccordIcon(
                                    newaccordIcon == "down" ? "up" : "down"
                                  )
                                }
                              />
                            </CustomToggle>
                          </div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="">
                        <Row>
                          {form?.Options?.map((it, index) => {
                            return (
                              <div className="mm_accordbody_options mt-2">
                                <div className="d-flex justify-content-start">
                                  <i class="fa-solid fa-grip-vertical me-2" />
                                  <span className="mm_leftaccord_hint">
                                    {it?.key?.length > 10
                                      ? it?.key.slice(10).concat("...")
                                      : it?.key}
                                  </span>
                                </div>
                                <span className="mm_leftaccord_hint">
                                  {SelectedShop?.CurrencySymbol}
                                  {it?.value}
                                </span>
                                <div className="d-flex justify-content-start">
                                  <span className="mm_leftaccord_hint">
                                    {!it?.available
                                      ? "available"
                                      : new Date(it.available) != "Invalid Date"
                                      ? new Date(it.available).getDate() ==
                                        new Date().getDate()
                                        ? "Not available Today "
                                        : ""
                                      : it?.available}
                                  </span>
                                </div>
                                <div class="dropdown table_viewdropdown text-end">
                                  <button
                                    class="btn table_viewdrop dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    {console.log("sdlkjflksfgs", it)}
                                    <i class="fa-solid fa-ellipsis" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    {/* {it?.available != 'Available' && */}
                                    <li
                                      onClick={() =>
                                        setForm({
                                          ...form,
                                          ...{
                                            Options: [
                                              ...form?.Options.slice(0, index),

                                              {
                                                key: form?.Options[index]?.key,
                                                value:
                                                  form?.Options[index]?.value,
                                                available: "Available",
                                              },
                                              ...form?.Options.slice(
                                                index + 1,
                                                form?.Options?.length
                                              ),
                                            ],
                                          },
                                        })
                                      }
                                    >
                                      <a class="dropdown-item">Available</a>
                                    </li>
                                    <li
                                      onClick={() =>
                                        setForm({
                                          ...form,
                                          ...{
                                            Options: [
                                              ...form?.Options.slice(0, index),

                                              {
                                                key: form?.Options[index]?.key,
                                                value:
                                                  form?.Options[index]?.value,
                                                available: new Date(),
                                              },
                                              ...form?.Options.slice(
                                                index + 1,
                                                form?.Options?.length
                                              ),
                                            ],
                                          },
                                        })
                                      }
                                    >
                                      <a class="dropdown-item">
                                        Unavailable Today
                                      </a>
                                    </li>
                                    <li
                                      onClick={() =>
                                        setForm({
                                          ...form,
                                          ...{
                                            Options: [
                                              ...form?.Options.slice(0, index),

                                              {
                                                key: form?.Options[index]?.key,
                                                value:
                                                  form?.Options[index]?.value,
                                                available:
                                                  "Unavailable Indefinitely",
                                              },
                                              ...form?.Options.slice(
                                                index + 1,
                                                form?.Options?.length
                                              ),
                                            ],
                                          },
                                        })
                                      }
                                    >
                                      <a class="dropdown-item">
                                        Unavailable Indefinitely
                                      </a>
                                    </li>
                                    <li>
                                      <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        onClick={() =>
                                          setForm({
                                            ...form,
                                            ...{
                                              Options: [
                                                ...form?.Options,
                                                form?.Options[index],
                                              ],
                                            },
                                          })
                                        }
                                      >
                                        <i class="fa-regular fa-copy mm_moddro_tabic"></i>
                                        Duplicate
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        onClick={() =>
                                          setForm({
                                            ...form,
                                            ...{
                                              Options: [
                                                ...form?.Options.slice(
                                                  0,
                                                  index
                                                ),
                                                ...form?.Options.slice(
                                                  index + 1,
                                                  form?.Options?.length
                                                ),
                                              ],
                                            },
                                          })
                                        }
                                      >
                                        <i class="fa-solid fa-trash-can mm_moddro_tabic"></i>
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })}

                          <div className="mm_accordbody_modi">
                            <img
                              className="mm_accordbody_addimg"
                              src={require("../../assets/images/icons/add.png")}
                              onClick={() => {
                                setForm({
                                  ...form,
                                  ...{
                                    Options: [
                                      ...(form?.Options ?? []),
                                      Options,
                                    ],
                                  },
                                });
                                setOptions({});
                              }}
                            />
                            <input
                              type="text"
                              id="key"
                              value={Options?.key ?? ""}
                              className="mm_offcanva_nameinp mm_offcanva_modiinp"
                              placeholder="Add an Option"
                              onChange={OnChangeMenuOption}
                            />
                            <input
                              id="value"
                              value={Options?.value ?? ""}
                              onChange={OnChangeMenuOption}
                              type="text"
                              className="mm_offcanva_nameinp mm_offcanva_modiprice"
                              placeholder="$0.00"
                            />
                          </div>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <span className="error-msg error_modal_red">
                  {Error?.Options}
                </span>

                <hr className="my-4" />
                {form?.Options?.length > 0 && (
                  <Accordion
                    onClick={handleGetScrollPosition}
                    // defaultActiveKey="0"
                    className="mm_newaccord"
                    id="rules_accordion"
                  >
                    <Card>
                      <Card.Header>
                        <Row>
                          <Col sm={10}>
                            <div className="mm_leftaccord_contents">
                              <p className="mm_leftaccord_ttl">Rules</p>
                              <span className="mm_leftaccord_hint">
                                Set rules for the way your customers can select
                                the options in this modifier.
                              </span>
                            </div>
                          </Col>

                          <Col
                            sm={2}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <div className="mm_accordright_whole">
                              <CustomToggle eventKey="0">
                                <i
                                  className={
                                    newaccordIconOne == "down"
                                      ? "fa-solid fa-chevron-down"
                                      : "fa-solid fa-chevron-up"
                                  }
                                  onClick={() =>
                                    setNewaccordIconOne(
                                      newaccordIconOne == "down" ? "up" : "down"
                                    )
                                  }
                                />
                              </CustomToggle>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="">
                          <Row>
                            <div className="mm_offcanva_inpttle">
                              <p className="mm_offcanva_inpname">
                                Require a selection{" "}
                              </p>
                              <div class="daynight">
                                <label for="checkbox">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="checkbox"
                                    checked={
                                      isEmpty(form?.Required) ? false : true
                                    }
                                    onChange={() =>
                                      {setForm({
                                        ...form,
                                        ...{
                                          ["Required"]: isEmpty(form?.Required)
                                            ? true
                                            : false,
                                          min: '',
                                        },
                                      });
                                      
                                    }
                                    }
                                  />
                                  <div class="toggle">
                                    <div class="star1"></div>
                                    <div class="star2"></div>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <span className="mm_leftaccord_hint">
                              If you turn on this switch, customers will have to
                              select at least one option in order to add the
                              item to the cart.
                            </span>

                            <hr className="my-4" />

                            <div className="mm_offcanva_inpttle">
                              <p className="mm_offcanva_inpname">
                                Allow multiple selections{" "}
                              </p>

                              <div class="daynight">
                                <label for="checkbox2">
                                  <input
                                    type="checkbox"
                                    name=""
                                    checked={
                                      form?.RequiredMultiple?.enable ||
                                      form?.RequiredMultipleEnable
                                    }
                                    id="checkbox2"
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ...{
                                          ["RequiredMultipleEnable"]:
                                            form?.RequiredMultipleEnable
                                              ? false
                                              : true,
                                          ["RequiredMultiple"]: {
                                            ...form?.RequiredMultiple,
                                            ...{
                                              enable: form?.RequiredMultiple
                                                ?.enable
                                                ? false
                                                : true,
                                            },
                                          },
                                        },
                                      })
                                    }
                                  />
                                  <div class="toggle">
                                    <div class="star1"></div>
                                    <div class="star2"></div>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <span className="mm_leftaccord_hint">
                              If you turn on this switch, customers will be able
                              to select more than one option.
                            </span>

                            {form?.RequiredMultipleEnable ||
                            form?.RequiredMultiple?.enable ? (
                              <>
                                {!isEmpty(form?.Required) && (
                                  <div className="mm_switch_selects mt-3">
                                    <span className="mm_leftaccord_hint">
                                      Minimum:
                                    </span>


                                    <div className="rightside_select me-5">
                                      {/* <Select
                                      options={options}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="No max"
                                    /> */}

                                      <input
                                        type="text"
                                        className="mm_offcanva_nameinp mt-2"
                                        placeholder={`Enter Count`}
                                        id="min"
                                        value={
                                          form?.min ??
                                          form?.RequiredMultiple?.min
                                        }
                                        onChange={(e) => {
                                          setForm({
                                            ...form,
                                            ...{
                                              ["min"]: (
                                                form?.max
                                                  ? (form?.Options?.length ??
                                                      0) >= e?.target.value &&
                                                    (form?.max ?? 0) >=
                                                      e?.target?.value
                                                  : (form?.Options?.length ??
                                                      0) >= e?.target.value
                                              )
                                                ? e?.target?.value
                                                : "",
                                            },
                                          });
                                        }}
                                      />
                                      {Error?.min && (
                                        <span className="error-msg error_modal_red">
                                          {Error?.min}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}

                                <div className="mm_switch_selects mt-3">
                                  <span className="mm_leftaccord_hint">
                                    Maximum:
                                  </span>

                                  <div className="rightside_select me-5">
                                    {/* <Select
                                      options={options}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="No max"
                                    /> */}
                                    <input
                                      type="text"
                                      className="mm_offcanva_nameinp mt-2"
                                      placeholder={`Enter Count`}
                                      id="max"
                                      value={
                                        form?.max ?? form?.RequiredMultiple?.max
                                      }
                                      onChange={(e) => {
                                        setForm({
                                          ...form,
                                          ...{
                                            ["max"]: (
                                              form?.min
                                                ? (form?.Options?.length ??
                                                    0) >= e?.target.value &&
                                                  (form?.min ?? 0) <=
                                                    e?.target?.value
                                                : (form?.Options?.length ??
                                                    0) >= e?.target.value
                                            )
                                              ? e?.target?.value
                                              : "",
                                          },
                                        });
                                        // if (
                                        //   ((form?.Options?.length ?? 0) >
                                        //     e?.target.value )||
                                        //   (form?.min ?? 0) > e?.target?.value
                                        // ) {
                                        //   return;
                                        // } else OnChange(e);
                                      }}
                                    />
                                    {Error?.max && (
                                      <span className="error-msg error_modal_red">
                                        {Error?.max}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                  <div className="mm_switch_selects mt-3">
                                    <span className="mm_leftaccord_hint">
                                      Maximum Free:
                                    </span>

                                    <div className="rightside_select me-5">
                                      <input
                                        type="text"
                                        className="mm_offcanva_nameinp mt-2"
                                        placeholder={`Enter Count`}
                                        id="max"
                                        value={
                                          form?.maxfree ??
                                          form?.RequiredMultiple?.maxfree
                                        }
                                        onChange={(e) => {
                                          setForm({
                                            ...form,
                                            ...{
                                              ["maxfree"]:
                                                (form?.Options?.filter(
                                                  function (item) {
                                                    return isEmpty(item.value);
                                                  }
                                                ).length ?? 0) >=
                                                e?.target.value
                                                  ? e?.target?.value
                                                  : "",
                                            },
                                          });
                                          // if (
                                          //   ((form?.Options?.length ?? 0) >
                                          //     e?.target.value )||
                                          //   (form?.min ?? 0) > e?.target?.value
                                          // ) {
                                          //   return;
                                          // } else OnChange(e);
                                        }}
                                      />
                                      {Error?.maxfree && (
                                        <span className="error-msg error_modal_red">
                                          {Error?.maxfree}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                <div className="mm_switch_selects mt-3">
                                  {/* <span className="mm_leftaccord_hint">
                                  Maximum Free:
                                </span> */}

                                  {/* <div className="rightside_select me-5"> */}
                                  {/* <Select
                                      options={options}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="No max"
                                    /> */}
                                  {/* <input
                                    type="text"
                                    className="mm_offcanva_nameinp mt-2"
                                    placeholder={`Enter Count`}
                                    id="MaxFree"
                                    value={form?.MaxFree}
                                    onChange={(e) => {
                                      if (
                                        (form?.Options?.length ?? 0) <
                                        e?.target.value
                                      ) {
                                        return;
                                      } else OnChange(e);
                                    }}
                                  /> */}
                                  {/* </div> */}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              </div>
              <div className="mm_offcanva_footer">
                <button className="empty_btn" onClick={handleClosecommonCanva}>
                  Cancel
                </button>
                <button onClick={SaveChanges} className="blue_btn mm_canvabtn">
                  Save Changes
                </button>
              </div>
            </div>
          ) : showOffcanvaDetails == "New Item" ||
            showOffcanvaDetails == "Edit Item" ? (
            <div className="mm_offcanva_stretch">
              <div className="mm_offcanva_itemscoll" id="mm_offcanva_itemscoll">
                <h5 className="mm_offcanva_ttl">{showOffcanvaDetails}</h5>

                {form?._id ? (
                  <>
                    <div className="mm_editcontent_modifier ">
                      <i class="fa-solid fa-circle-check text-success" />
                      <div>
                        <p className="mm_editconn_ttl">Available</p>
                        <p className="mm_offcanva_excladtls">
                          Customers can view and order this item during store
                          hours.
                        </p>
                      </div>
                    </div>
                    <div className="mm_editcont_unavailable mt-3">
                      <button className="empty_border_btn">
                        Marked as{" "}
                        {new Date(form?.available) != "Invalid Date"
                          ? new Date(form?.available).getDate() ==
                            new Date().getDate()
                            ? "Not available Today "
                            : ""
                          : form?.available}
                      </button>

                      <div class="dropdown table_viewdropdown">
                        <button
                          class="btn table_viewdrop dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa-solid fa-ellipsis" />
                        </button>
                        <ul class="dropdown-menu">
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Available" },
                              })
                            }
                          >
                            <a class="dropdown-item">Available</a>
                          </li>
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Unavailable Today" },
                              })
                            }
                          >
                            <a class="dropdown-item">Unavailable Today</a>
                          </li>
                          <li
                            onClick={() =>
                              setForm({
                                ...form,
                                ...{ available: "Unavailable Indefinitely" },
                              })
                            }
                          >
                            <a class="dropdown-item">
                              Unavailable Indefinitely
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="mm_offcanva_excladtls mt-3">
                    <i class="fa-solid fa-circle-exclamation me-2"></i> you can
                    edit the availability of this item after saving.
                  </p>
                )}

                <div className="mm_imageadd_hinter mt-4">
                  <img
                    className="mm_twinklestar"
                    src={require("../../assets/images/twinklestar.svg").default}
                  />
                  <div className="mm_imageadd_hinter_hint">
                    <span className="mm_offcanva_req">
                      Add a appetizing photo to draw attention to your item and
                      help increase the conversion rate.
                    </span>
                    <div className="twinkle_image_add">
                      <button className="mm_whited_btn mt-2">
                        Add a photo
                        <input
                          type="file"
                          id="ProductImage"
                          onChange={OnChange}
                        />
                      </button>
                      <img
                        height={50}
                        width={50}
                        src={
                          form?.ProductImage
                            ? form?.ProductImage?.type
                              ? URL.createObjectURL(form?.ProductImage)
                              : `${config.IMG_URL}/Product/${form?.ProductImage}`
                            : require("../../assets/images/2.png")
                        }
                      />
                    </div>
                    <span className="error-msg error_modal_red">
                      {Error?.ProductImage}
                    </span>
                  </div>
                  <i class="fa-solid fa-xmark" />
                </div>
                <div className={""}>
                  <div className="mm_offcanva_inpttle mt-4">
                    <p className="mm_offcanva_inpname">Name</p>
                    <span className="mm_offcanva_req">Required</span>
                  </div>
                  <input
                    type="text"
                    className="mm_offcanva_nameinp mt-2"
                    placeholder='Eg."Nachos"'
                    id="ProductName"
                    value={form?.ProductName}
                    onChange={OnChange}
                  />
                  <span className="error-msg error_modal_red">
                    {Error?.ProductName}
                  </span>

                  <div className="mm_offcanva_inpttle mt-4">
                    <p className="mm_offcanva_inpname">Price</p>
                  </div>
                  <input
                    type="text"
                    className="mm_offcanva_nameinp mm_offcanva_priceinp mt-2"
                    placeholder="$0.00"
                    id="Price"
                    value={form?.Price}
                    onChange={OnChange}
                  />
                </div>
                <span className="error-msg error_modal_red">
                  {Error?.Price}
                </span>
                <p className="mm_offcanva_req mt-2 notecanva">
                  <b className="mm_offcanva_inpname">Note:</b> You can also set
                  price 0.
                </p>
                <div className={""}>
                  <div className="mm_offcanva_inpttle mt-4">
                    <p className="mm_offcanva_inpname">Quantity</p>
                  </div>
                  <input
                    type="text"
                    className="mm_offcanva_nameinp mm_offcanva_priceinp mt-2"
                    placeholder="Ex.1"
                    id="Quantity"
                    value={form?.Quantity}
                    onChange={OnChange}
                  />
                  <span className="error-msg error_modal_red">
                    {Error?.Quantity}
                  </span>
                </div>

                <div className="mm_offcanva_inpttle mt-4">
                  <p className="mm_offcanva_inpname">
                    Item Tax Rate{" "}
                    <sup>
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </sup>
                  </p>
                </div>
                <div className="mm_offcanva_percent mt-2">
                  <input
                    type="text"
                    className="mm_offcanva_nameinp mm_offcanva_priceinp"
                    placeholder=""
                    id="Tax"
                    value={form?.Tax}
                    onChange={OnChange}
                  />
                  <p>%</p>
                </div>
                <span className="error-msg error_modal_red">{Error?.Tax}</span>

                <div className="mm_offcanva_inpttle mt-4">
                  <p className="mm_offcanva_inpname">Description</p>
                  <span className="mm_offcanva_req">Optional</span>
                </div>

                <textarea
                  id="ProductDescription"
                  name=""
                  className="mm_offcanva_textarea mt-2"
                  placeholder="Add a description (optional)"
                  rows="4"
                  cols="50"
                  value={form?.ProductDescription}
                  onChange={OnChange}
                ></textarea>

                <div className=" mt-4">
                  <p className="mm_offcanva_inpname">Category</p>
                  {form?.dat == "cat" ? (
                    <input
                      disabled={true}
                      type="text"
                      className="mm_offcanva_nameinp mm_offcanva_priceinp"
                      placeholder=""
                      // id="Tax"
                      value={
                        sellercat.find((it) => it.value == form?.SellerCategory)
                          ?.CategoryName
                      }
                      // onChange={OnChange}
                    />
                  ) : (
                    <Select
                      // isMulti
                      options={sellercat}
                      styles={stylesgraybg}
                      className="border_blue_select mt-2"
                      classNamePrefix="react-select"
                      placeholder="Select a Category"
                      // value={form?.SellerCategory?{value:form?.SellerCategory,label:sellercat.map(it=>it.value==form?.SellerCategory)}:null}
                      onChange={(e) => {
                        setForm({ ...form, ...{ SellerCategory: e?.value } });
                      }}
                    />
                  )}

                  <span className="error-msg error_modal_red">
                    {Error?.SellerCategory}
                  </span>
                </div>

                <div className="mt-4">
                  <h5 className="mm_offcanva_ttl">Modifiers</h5>
                  {/* <div className="whole_searchbar mm_offcanva_addmodifier white_bg mt-2"> */}

                  {form?._id ? (
                    <Select
                      isMulti
                      options={menuoption}
                      styles={stylesgraybg}
                      className="border_blue_select mt-2"
                      classNamePrefix="react-select"
                      placeholder="Select Modifier"
                      defaultValue={
                        form?._id
                          ? !isEmpty(form?.AdditionalInfo) &&
                            Array.isArray(form?.AdditionalInfo)
                            ? form?.AdditionalInfo?.map((its) => {
                                return {
                                  value: its,
                                  label: menuoption?.find((ui) => ui._id == its)
                                    ?.Name,
                                };
                              })
                            : undefined
                          : undefined
                      }
                      onChange={(e) => {
                        e?.length == 0
                          ? setForm({ ...form, ...{ AdditionalInfo: [] } })
                          : e.map((it) =>
                              setForm({
                                ...form,
                                ...{
                                  AdditionalInfo: [
                                    ...(form?.AdditionalInfo ?? []),
                                    it?._id,
                                  ],
                                },
                              })
                            );
                      }}
                    />
                  ) : (
                    <Select
                      isMulti
                      options={menuoption}
                      styles={stylesgraybg}
                      className="border_blue_select menudrop_height mt-2"
                      classNamePrefix="react-select"
                      placeholder="Select Modifier"
                      onChange={(e) => {
                        e.map((it) =>
                          setForm({
                            ...form,
                            ...{
                              AdditionalInfo: [
                                ...(form?.AdditionalInfo ?? []),
                                it?._id,
                              ],
                            },
                          })
                        );
                      }}
                    />
                  )}
                  <span className="error-msg error_modal_red">
                    {Error?.AdditionalInfo}
                  </span>

                  {/* <input placeholder="Search for an item" /> */}
                  {/* </div> */}
                </div>
                <div className="mvv_gridsingle">
                  <div className="mvv_uploadfile_dtl mm_mvv_uploadfile mt-3">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/icons/cloud.png")}
                    />
                    <p className="mvv_cloudtext">Upload File</p>
                    <input
                      type="file"
                      className="mvv_fileupload"
                      id="ProductImageMultiple"
                      onChange={OnChange}
                      // onChange={(e) => Onchange(e, "StoreImages")}
                      multiple
                    />
                  </div>
                  <span className="error-msg error_modal_red">
                    {Error?.ProductImageMultiple}
                  </span>
                  {!isEmpty(form?.ProductImageMultiple) && (
                    <div className="addproduct_foodimg multiple mt-3">
                      {form?.ProductImageMultiple?.map((it, ind) => {
                        return it?.type ? (
                          <div className="single_closable_al">
                            <button onClick={() => RemoveImage("single", ind)}>
                              x
                            </button>
                            <img
                              src={
                                it?.type
                                  ? URL.createObjectURL(it)
                                  : require("../../assets/images/dummy.png")
                              }
                            />
                          </div>
                        ) : (
                          <div className="single_closable_al">
                            <button onClick={() => RemoveImage("single", ind)}>
                              x
                            </button>
                            <img
                              src={
                                !isEmpty(it)
                                  ? `${config.IMG_URL}/Product/sub/${it.ProductImageName}`
                                  : require("../../assets/images/dummy.png")
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="mt-3 text-end">
                    <button
                      className="mm_offcanva_clearall"
                      type="button"
                      onClick={() => RemoveImage("all")}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </div>
              <div className="mm_offcanva_footer">
                <button className="empty_btn" onClick={handleClosecommonCanva}>
                  Cancel
                </button>
                <button className="blue_btn mm_canvabtn" onClick={SaveChanges}>
                  Save Changes
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* end of common offcanva */}

      {/* offcanva closing modal */}
      <Modal
        show={[
          "unsavedChanges",
          "linkedStores",
          "Rearrange Categories",
          "Delete",
          "Available",
          "Unavailable Today",
          "Unavailable Indefinitely",
        ]?.includes(saveChanges)}
        onHide={handleCloseSaveChanges}
        backdrop="static"
        keyboard={false}
        centered
        className="reg_gender_modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {saveChanges == "Delete" ? (
            <>
              <h5 className="mm_offcanva_ttl">Are You Sure to Delete</h5>
              <p>if you Delete ,it will be removed from everywhere</p>
            </>
          ) : [
              "Available",
              "Unavailable Today",
              "Unavailable Indefinitely",
            ]?.includes(saveChanges) ? (
            <>
              <h5 className="mm_offcanva_ttl">Are You Make {saveChanges}</h5>
              <p>{saveChanges} will be reflect on everywhere</p>
            </>
          ) : saveChanges == "Rearrange Categories" ? (
            <div>
              <h5 className="mm_offcanva_ttl">Rearrange Items</h5>
              <div className="mt-4 mm_draggable_contalign">
                <Draggable onPosChange={getChangedPos}>
                  {sellercat.map((item) => (
                    <div
                      className="mm_rearrangeitems_list"
                      key={item?._id}
                      onClick={() => setForm({ ...form, ...item })}
                    >
                      <i class="fa-solid fa-grip-vertical me-3" />
                      <span className="mm_offcanva_req">
                        {item?.CategoryName}
                      </span>
                    </div>
                  ))}
                </Draggable>
              </div>
            </div>
          ) : saveChanges == "unsavedChanges" ? (
            <>
              <h5 className="mm_offcanva_ttl">You have unsaved changes</h5>
              <p>
                Your changes will be lost if you leave the page. Do you want to
                save your changes in this item?
              </p>
            </>
          ) : (
            saveChanges == "linkedStores" && (
              <div>
                <h5 className="mm_offcanva_ttl">
                  {SelectedShop?.BrandName} ({CurrentTime()})
                </h5>
                <div className="mt-4">
                  <div className="mm_modal_linkedstores mt-2">
                    <div>
                      {/* <p className="mm_editconn_ttl">
                  Organica Pizza (E Colorado Bivd)
                </p> */}
                      <p className="mm_offcanva_req m-0">
                        Store ID {eyeshow ? SelectedShop?._id : "********"}
                      </p>
                      <span className="mm_offcanva_req">
                        {SelectedShop?.Address?.StreetName} ,{" "}
                        {SelectedShop?.Address?.City},{" "}
                        {SelectedShop?.Address?.State},{" "}
                        {SelectedShop?.Address?.Country},{" "}
                        {SelectedShop?.Address?.Zipcode}
                      </span>
                    </div>
                    <button
                      className="mm_round_btns"
                      onClick={() => seteyeshow(!eyeshow)}
                    >
                      <i class="fa-solid fa-eye" />
                    </button>
                  </div>
                </div>
              </div>
            )
          )}
          {!["linkedStores", ""]?.includes(saveChanges) && (
            <div className="mm_canvaclose_modalbtns mt-4">
              <button className="empty_btn" onClick={CancelCanva}>
                Cancel
              </button>
              <button className="blue_btn mm_saveexitbtn" onClick={SaveChanges}>
                Confirm
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* end of offcanva closing modal */}
    </>
  );
}

export default MenuManager;
