// {
//   StartTime: "",
//   EndTime: "",
//   StartHour: "",
//   StartMinute: "",
//   EndHour: "",
//   EndMinute: "",
// },

import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import useAxiosFile from "../../actions/useaxios";

import delpick from "../../assets/images/delpick.png";
import locationCom from "../../assets/svg/Location.svg";
import homeCom from "../../assets/svg/home.svg";
import clockCom from "../../assets/svg/clock.svg";
import coolIconCom from "../../assets/svg/coolicon.svg";

function StoreTiming() {
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  console.log("xdsdd", payload);
  const dispatch = useDispatch();
  const locate = useLocation();
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();

  console.log("wqqweqwe", locate);
  const { home, location, clock } = useSelector(
    (state) => state.seller_panel.steppergo
  );
  const [data, setlocate] = useState();
  const [load, Setload] = useState(false);

  const [timing, settiming] = useState({
    Sunday: {
      day: "Sunday",
      Status: false,
      hours: false,
      error: "",

      timing: [],
    },

    Monday: {
      day: "Monday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },

    Tuesday: {
      day: "Tuesday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Wednesday: {
      day: "Wednesday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Thursday: {
      day: "Thursday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Friday: {
      day: "Friday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Saturday: {
      day: "Saturday",

      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  });
  const { Asxds } = localStorage;
  const local = DecryptData(Asxds);
  console.log("time", timing);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: true,
          clock: false,
          window: false,
          dummyimage: false,
        },
      },
    });
    setlocate(locate.state);
  }, []);
  console.log("sxsx", timing);
  var obj = {
    locate: data,
    timing: timing,
  };

  const click = (e, data) => {
    Setload(false);
    console.log(
      "data",
      e.target.hours,
      e.target.value,
      timing[e.target?.id]?.timing[e.target.name],
      data,
      e.target.name,
      e.target.checked,
      e.target?.id
    );
    settiming({
      ...timing,
      ...{
        [e.target?.id]: {
          ...timing[e.target?.id],
          ...(data
            ? {
                timing: [
                  ...timing[e?.target?.id]?.timing.slice(0, e.target.name),
                  ...timing[e?.target?.id]?.timing.slice(
                    e.target.name,
                    timing[e?.target?.id]?.timing.length - 1
                  ),

                  {
                    ...timing[e.target?.id]?.timing[e.target.name],
                    ...(data == "Start"
                      ? timing[e.target?.id]?.timing[e.target.name].EndTime
                        ? e.target.value?.split(":")[0] <
                            timing[e.target?.id]?.timing[e.target.name]
                              .EndHour &&
                          e.target.value?.split(":")[1] <
                            timing[e.target?.id]?.timing[e.target.name]
                              .EndMinute
                          ? {
                              [`${data}Time`]: e.target.value,
                              [`${data}Hour`]: e.target.value?.split(":")[0],
                              [`${data}Minute`]: e.target.value?.split(":")[1],
                            }
                          : {}
                        : {
                            [`${data}Time`]: e.target.value,
                            [`${data}Hour`]: e.target.value?.split(":")[0],
                            [`${data}Minute`]: e.target.value?.split(":")[1],
                          }
                      : data == "End"
                      ? timing[e.target?.id]?.timing[e.target.name].StartTime
                        ? e.target.value?.split(":")[0] >
                            timing[e.target?.id]?.timing[e.target.name]
                              .StartHour &&
                          e.target.value?.split(":")[1] >
                            timing[e.target?.id]?.timing[e.target.name]
                              .StartMinute
                          ? {
                              [`${data}Time`]: e.target.value,
                              [`${data}Hour`]: e.target.value?.split(":")[0],
                              [`${data}Minute`]: e.target.value?.split(":")[1],
                            }
                          : // e.target.value?.split(":")[0] > timing[e.target?.id]?.timing[e.target.name].StartHour ?
                            //   {
                            //   // [`${data}Time`]:e.target.value,
                            //   [`${data}Hour`]: e.target.value?.split(":")[0],
                            //   [`${data}Minute`]: "",
                            //   }:
                            //   e.target.value?.split(":")[1] > timing[e.target?.id]?.timing[e.target.name].StartMinute ?
                            //   {
                            //   // [`${data}Time`]:e.target.value,
                            //   [`${data}Hour`]: "",
                            //   [`${data}Minute`]: e.target.value?.split(":")[1],
                            //   }:
                            {}
                        : {
                            [`${data}Time`]: e.target.value,
                            [`${data}Hour`]: e.target.value?.split(":")[0],
                            [`${data}Minute`]: e.target.value?.split(":")[1],
                          }
                      : {}),
                  },
                ],
              }
            : {
                Status: e.target.checked,
                hours: !e.target.checked ? false : timing[data]?.hours,
                timing: e.target.checked
                  ? [
                      ...timing[e?.target?.id]?.timing,
                      {
                        StartTime: "",
                        EndTime: "",
                        StartHour: "",
                        StartMinute: "",
                        EndHour: "",
                        EndMinute: "",
                      },
                    ]
                  : [],
              }),
        },
      },
    });
  };

  const table = (data, index) => {
    console.log("come inside", index, data);
    Setload(false);
    settiming({
      ...timing,
      ...{
        [data]: {
          ...timing[data],
          ...(index
            ? {
                timing: [
                  ...timing[data]?.timing?.slice(0, index),
                  ...timing[data]?.timing?.slice(
                    index,
                    timing[data]?.timing.length - 1
                  ),
                ],
              }
            : {
                timing: [
                  ...timing[data]?.timing,
                  {
                    [`${"Start"}Time`]: "",
                    [`${"Start"}Hour`]: "",
                    [`${"Start"}Minute`]: "",
                    [`${"End"}Time`]: "",
                    [`${"End"}Hour`]: "",
                    [`${"End"}Minute`]: "",
                  },
                ],
              }),
        },
      },
    });
  };

  const _24hours = (e) => {
    Setload(false);
    let id = e.target.id.split("1")[0];
    console.log("dshdksadhsahdsa", e.target.id, e.target.checked);
    settiming({
      ...timing,
      ...{
        [id]: {
          ...timing[id],
          ...{
            hours: e?.target?.checked,
            timing: !e?.target?.checked
              ? timing[id]?.timing?.filter(
                  (ito) => ito.EndTime != "23:59" && ito.StartTime != "00.00"
                ).length == 0
                ? [
                    ...timing[id]?.timing?.filter(
                      (ito) =>
                        ito.EndTime != "23:59" && ito.StartTime != "00.00"
                    ),
                    {
                      [`${"End"}Time`]: "",
                      [`${"End"}Hour`]: "",
                      [`${"End"}Minute`]: "",
                      [`${"Start"}Time`]: "",
                      [`${"Start"}Hour`]: "",
                      [`${"Start"}Minute`]: "",
                    },
                  ]
                : timing[id]?.timing?.filter(
                    (ito) => ito.EndTime != "23:59" && ito.StartTime != "00.00"
                  )
              : [
                  ...(timing[id]?.timing?.filter(
                    (ito) => !isEmpty(ito.EndTime) && !isEmpty(ito.StartTime)
                  ).length > 0
                    ? timing[id]?.timing
                    : []),
                  ...timing[id]?.timing?.filter(
                    (ito) => !isEmpty(ito.EndTime) && !isEmpty(ito.StartTime)
                  ),
                  {
                    [`${"End"}Time`]: "23:59",
                    [`${"End"}Hour`]: "23",
                    [`${"End"}Minute`]: "59",
                    [`${"Start"}Time`]: "00:00",
                    [`${"Start"}Hour`]: "0",
                    [`${"Start"}Minute`]: "00",
                  },
                ],
          },
        },
      },
    });
  };

  const Validation = () => {
    return Object.values(timing)
      .map((it) => {
        if (
          it.Status &&
          it.timing.filter(
            (itd) => isEmpty(itd.StartTime) || isEmpty(itd.EndTime)
          ).length > 0
        )
          return it.day;
      })
      .filter((it) => !isEmpty(it) && it);
  };

  const Continue = async () => {
    Setload(true);
    var validate = Validation();
    const toastid = LoadToast();
    if (Object.values(timing).every((it) => !it.Status)) {
      navigate("/bankdetails");
      updateToast(toastid, "Moving next page", "success");
    } else {
      Setload(false);
      console.log(
        "Object.values(timing).every(it=>it.Status)",
        Object.values(timing).every((it) => !it.Status)
      );
      if (isEmpty(validate)) {
        let daytime = {
          Sunday: {
            day: "Sunday",
            Timing: timing.Sunday,
            Delivery: {
              day: "Sunday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Sunday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Sunday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Monday: {
            day: "Monday",
            Timing: timing.Monday,
            Delivery: {
              day: "Monday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Monday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Monday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Tuesday: {
            day: "Tuesday",
            Timing: timing.Tuesday,
            Delivery: {
              day: "Tuesday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Tuesday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Tuesday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Wednesday: {
            day: "Wednesday",
            Timing: timing.Wednesday,
            Delivery: {
              day: "Wednesday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Wednesday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Wednesday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Thursday: {
            day: "Thursday",
            Timing: timing.Thursday,
            Delivery: {
              day: "Thursday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Thursday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Thursday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Friday: {
            day: "Friday",
            Timing: timing.Friday,
            Delivery: {
              day: "Friday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Friday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Friday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
          Saturday: {
            day: "Saturday",
            Timing: timing.Saturday,
            Delivery: {
              day: "Saturday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            Pickup: {
              day: "Saturday",
              Status: false,
              hours: false,
              error: "",
              timing: [],
            },
            DeliveryTimeCharge: {
              day: "Saturday",
              Status: false,
              AfterPreparation: [
                { AfterPreparation: "", ChargeAmount: "", Currency: "" },
              ],
            },
          },
        };
        var resp = await axiosFile.addsellershop({
          action: "TimeAdd",
          timing: daytime,
          ShopId: local.shopid,
        });
        if (resp?.success == "success") {
          updateToast(toastid, resp?.msg, "success");
          navigate("/bankdetails");
        } else {
          updateToast(toastid, "Try Again", "error");
        }
      } else {
        var th = validate.map((it) => {
          return {
            ...timing,
            ...{
              [it]: {
                ...timing[it],
                ...{ error: `${it} time required or disable store time` },
              },
            },
          };
        });
        console.log("dsakdlasdkasjd", th);
        updateToast(toastid, "Try Again", "error");
        settiming(th[0]);
      }
    }
  };

  console.log("dsadsajdasdsa", timing, timing.Sunday);
  return (
    <div className="regwhole_bg acnt_regwhole_bg">
      {/* <img
        className="singin_abs reg_abs "
        src={require("../../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs wd_ht_unst" src={delpick} alt="" />
      <Container>
        <Row className="justify-content-center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            <div className="reg_top_dtls">
              <h6 className="reg_top_title">Store Timing</h6>
              {/* <p className="reg_skip_txt">
                <Link to="/">skip</Link>
              </p> */}
            </div>
            <Row className="mt-5">
              {[
                "Sunday",
                "Thursday",
                "Monday",
                "Friday",
                "Tuesday",
                "Saturday",
                "Wednesday",
              ].map((it, index) => {
                return (
                  <Col xxl={6} lg={6} md={6}>
                    <Row>
                      <Col className="mb-3" xxl={12} lg={12} md={12}>
                        <div className="storetiming_flexing">
                          <p className="storetiming_label">{it}</p>
                          <div className="togswit togswit_violetgray">
                            <input
                              type="checkbox"
                              id={it}
                              checked={timing?.[it]?.Status}
                              onChange={(e) => {
                                click(e);
                              }}
                            />
                            <label for={it}></label>
                          </div>
                        </div>
                        {timing?.[it]?.Status && (
                          <div className="togswit togswit_violetgray">
                            <input
                              type="checkbox"
                              id={`${it}1`}
                              checked={timing?.[it]?.hours}
                              onClick={() => console.log("seeri adicha")}
                              onChange={(e) => {
                                _24hours(e);
                              }}
                            />
                            <label for={`${it}1`}></label>
                          </div>
                        )}
                        {timing?.[it]?.Status &&
                          timing?.[it]?.timing.map((its, indexs) => {
                            return (
                              <div className="storetiming_flexing mt-2">
                                {!timing?.[it]?.hours && (
                                  <>
                                    <input
                                      className="sigin_inputs timing_inputs"
                                      type="time"
                                      value={
                                        timing[it]?.timing[indexs]?.StartTime ||
                                        ""
                                      }
                                      name={indexs}
                                      placeholder="10:00 AM"
                                      id={it}
                                      max={timing[it]?.timing[indexs]?.EndTime}
                                      // min={"09:00"}
                                      // max={"10:00"}

                                      onChange={(e) => click(e, "Start")}
                                    />
                                    <div className="right_inp_increase">
                                      <input
                                        className="sigin_inputs timing_inputs me-2"
                                        type="time"
                                        value={
                                          timing[it]?.timing[indexs]?.EndTime ||
                                          ""
                                        }
                                        name={indexs}
                                        placeholder="1:00 PM"
                                        id={it}
                                        min={
                                          timing[it]?.timing[indexs]?.StartTime
                                        }
                                        onChange={(e) => click(e, "End")}
                                      />

                                      <img
                                        key={it}
                                        src={require("../../assets/images/icons/plusround.png")}
                                        onClick={() => table(it)}
                                      />
                                      {indexs >= 1 && (
                                        <img
                                          key={it}
                                          src={require("../../assets/images/icons/plusround.png")}
                                          onClick={() => table(it, indexs)}
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </Col>
                      <span className="error-msg">{timing[it]?.error}</span>
                    </Row>
                  </Col>
                );
              })}
            </Row>

            <Col className="mb-3" xl={12} lg={12} md={12}>
              <div className="w-100 text-center">
                <button
                  onClick={() => Continue()}
                  disabled={load}
                  className="blue_btn storeimg_bluebtn"
                >
                  {load ? "Loading ..... " : "Continue"}
                </button>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <div className="stepper">
        {home == true ? (
          // <img src={require("../../assets/images/icons/bluehome.png")} />
          <img src={homeCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/shop.png")} />
        )}

        <p className={home == true ? "blue_line" : ""}>------</p>
        {location == true ? (
          // <img src={require("../../assets/images/icons/bluelocation.png")} />
          <img src={locationCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/location.png")} />
        )}
        <p className={location == true ? "blue_line" : ""}>------</p>
        {clock == true ? (
          // <img src={require("../../assets/images/icons/blueclock.png")} />
          <img src={clockCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/Clock.png")} />
        )}
        <p>------</p>
        <img src={require("../../assets/images/icons/window.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/dummyimage.png")} />
      </div>
    </div>
  );
}

export default StoreTiming;
