import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineBell } from "@react-icons/all-files/hi/HiOutlineBell";
// import pf_img from "../assests/images/profile.png"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadToast, updateToast } from "../actions/common";
import config from "../actions/config";

import delpick from "../assets/images/delpick.png";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  console.log("fhdbf", payload, token);
  // const dispatch = useDispatch()
  const logout = () => {
    document.cookie = "token_s" + "=" + "" + ";" + ";path=/";
    localStorage.clear();
    dispatch({
      type: "Logout",
    });
    navigate("/signin");
  };
  return (
    <div className="headers_pages">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container className="custom-container">
          <Link to="/">
            {/* <img
              src={require("../assets/images/mainlogo.png")}
              alt="logo"
              width="100%"
            /> */}
            <img src={delpick} alt="logo" width="100%" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-3 mt-lg-0">
              {/* <div class="search-input">
    <span class="fa fa-search"></span>
    <input placeholder="Search term"/>
     </div> */}

              {/* <div className='whole_searchbar'>
     <img className='search_glass' src={require('../assets/images/icons/Search.png')}/>
    <input placeholder="Search term"/>
     </div> */}
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="noti_btn dp_not_btn"
                >
                  <HiOutlineBell />
                </Dropdown.Toggle>

                {/* <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu> */}
              </Dropdown>
              <div className="pf_drop mt-3 mt-lg-0">
                <div>
                  <img
                    src={
                      payload?.Profile
                        ? `${config.IMG_URL}/user/${payload?._id}/profile/${payload?.Profile}`
                        : require("../assets/images/tablefood.png")
                    }
                    className="pf_img"
                    alt="pf_img"
                  />
                </div>
                <NavDropdown title={payload?.FirstName} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
