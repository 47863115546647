// import React from "react";
// import { isEmpty, isToday } from "../actions/common";
// import moment from "moment";

// function OrderStatus(props) {
//   const { Orderdata, OrderAction ,customerName,SellerName} = props;

//   const Accept = ({Status,Time,color,By,Details}) =>{
//    return <div className="rv_rowborder">
//             <div className="ov_pendingbtn">
//               <button className={color} disabled={true}>
//                 {Status}
//               </button>
//             </div>
//             <p className="ov_shippingttl mt-2">At</p>
//             <div className="ov_rightdtls accepted">
//               <div>
//                 <p className="ov_address">
//                   {isToday(new Date(Time))
//                     ? "Today"
//                     : moment(Time).format("DD/MM/YYYY")}
//                 </p>
//                 <p className="ov_address mt-2">
//                   {moment(new Date(Time)).format(
//                     "HH:MM A"
//                   )}
//                 </p>
//               </div>
//               <p className="value_datetime greentxt">By {By}</p>
//             </div>
//             {!isEmpty(Details) && (
//               <>
//                 <div className="">
//                   <p className="ov_shippingttl mt-2">Bio about {By}</p>
//                   <p className="ov_shippingttl mt-2">
//                     {Orderdata?.DeliveryAddress?.FullName}
//                   </p>
//                   <p className="ov_shippingttl mt-2">
//                     {Orderdata?.DeliveryAddress?.Mobile}
//                   </p>
//                   <p className="ov_shippingttl mt-2">
//                     {Orderdata?.DeliveryAddress?.EmailId}
//                   </p>
//                   <p className="ov_address mt-1">
//                     {Orderdata?.DeliveryAddress?.Doornumber},{" "}
//                     {Orderdata?.DeliveryAddress?.Street},{" "}
//                     {Orderdata?.DeliveryAddress?.City}{" "}
//                     {Orderdata?.DeliveryAddress?.Zipcode},{" "}
//                     {Orderdata?.DeliveryAddress?.Area}
//                   </p>
//                 </div>
//               </>
//             )}
//           </div>
//   }

//   return (
//     <>
//       {console.log("ldkfljdljfkjda", Orderdata, props)}
//       {

//       Orderdata?.Time?.Paid &&
//       <Accept Status = {"Paid"}
//                Time  = {Orderdata?.Time?.Paid}
//                color = {"orange_btn"}
//                By   =   {customerName}
//       />}

//        { Orderdata?.Time?.Paid && Orderdata?.Time?.ApprovedBySeller && (
//           <Accept Status = {"Accepted"}
//           Time  = {Orderdata?.Time?.ApprovedBySeller}
//           color = {"blue_btn"}
//           By = {SellerName}
//           />
//       )}

//        { Orderdata?.Time?.Pickup && Orderdata?.Time?.ApprovedBySeller && (
//           <Accept Status = {"Pickup - Completed"}
//           Time  = {Orderdata?.Time?.Pickup}
//           color = {"green_btn"}
//           By = {SellerName}
//           />
//       )}
//         { Orderdata?.Time?.Deliver && Orderdata?.Time?.ApprovedBySeller && (
//           <Accept Status = {"In transit"}
//           Time  = {Orderdata?.Time?.Deliver}
//           color = {"orange_btn"}
//           By = {"Jamske Driver"}
//           Details = {
//            { name : "Jamske" , Mobile : "00000"}
//           }
//           />
//       )}
//        { Orderdata?.Time?.Paid && Orderdata?.Time?.cancelbyseller && (
//           <Accept Status = {"Cancelled By Seller"}
//           Time  = {Orderdata?.Time?.cancelbyseller}
//           color = {"orange_btn"}
//           By = {SellerName}

//           />
//       )}

// { Orderdata?.Time?.Paid && Orderdata?.Time?.cancelbyuser && (
//           <Accept Status = {"Cancelled By Seller"}
//           Time  = {Orderdata?.Time?.cancelbyuser}
//           color = {"orange_btn"}
//           By = {SellerName}

//           />
//       )}
//       { Orderdata?.Time?.completed && (
//           <Accept Status = {"Completed"}
//           Time  = {Orderdata?.Time?.completed}
//           color = {"green_btn"}
//           By = {"Jamske Driver"}

//           />
//       )}
//         { Orderdata?.Time?.preparing && Orderdata?.Time?.ApprovedBySeller&& (
//           <Accept Status = {"Prepaired"}
//           Time  = {Orderdata?.Time?.preparing}
//           color = {"orange_btn"}
//           By = {SellerName}

//           />
//       )}

//     </>
//   );
// }

// export default OrderStatus;

import React from "react";
import { DateTimeForm, isEmpty, isToday } from "../actions/common";
import moment from "moment";

function OrderStatus(props) {
  const { Orderdata, OrderAction } = props;

  return (
    <>
      {Orderdata?.Time?.ApprovedBySeller && (
        <>
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button className="blue_btn" disabled={true}>
                Accepted
              </button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(new Date(Orderdata?.Time?.ApprovedBySeller))
                    ? "Today"
                    : moment(Orderdata?.DeliveryTime).format("DD/MM/YYYY")}
                </p>
                <p className="ov_address mt-2">
                  {moment(new Date(Orderdata?.Time?.ApprovedBySeller)).format(
                    "HH:MM A"
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
              <>
                <div className="">
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                </div>
              </>
            )}
          </div>
          <br />
        </>
      )}

      {isEmpty(Orderdata?.Time?.ApprovedBySeller) &&
        isEmpty(Orderdata?.Time?.cancelbyseller) && (
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button
                className="orange_btn"
                onClick={() => OrderAction("approvebyseller")}
              >
                Approve Pending
              </button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls">
              <div>
                <p className="ov_address">Today</p>
                <p className="ov_address mt-2">8:30 PM - 9:00 PM</p>
              </div>
              <p className="value_datetime greentxt">Pick Up</p>
            </div>
          </div>
        )}
      {console.log("sjdjsgfhsgfs", Orderdata)}
      {!isEmpty(Orderdata?.Time?.preparing) &&
        isEmpty(Orderdata?.Time?.cancelbyseller) && (
          <>
            {/* Orderdata?.Time?.cancelbyseller */}
            <div>
              <div className="rv_rowborder">
                <div className="ov_pendingbtn">
                  <button
                    className={
                      isEmpty(Orderdata?.Time?.Dispatch)
                        ? "orange_btn"
                        : "blue_btn"
                    }
                    onClick={() =>
                      !isEmpty(Orderdata?.Time?.Dispatch)
                        ? null
                        : OrderAction("dispatch")
                    }
                    disabled={isEmpty(Orderdata?.Time?.Dispatch) ? false : true}
                  >
                    {!isEmpty(Orderdata?.Time?.Dispatch)
                      ? "Order is Ready"
                      : "Preparing"}
                  </button>
                </div>
                <p className="ov_shippingttl mt-2">Shipping Method</p>
                <div className="ov_rightdtls accepted">
                  <div>
                    <p className="ov_address">Today</p>
                    <p className="ov_address mt-2">
                      {moment(new Date(Orderdata?.Time?.preparing)).format(
                        "HH:MM A"
                      )}
                    </p>
                  </div>
                  <p className="value_datetime greentxt">
                    {Orderdata?.OrderType}
                  </p>
                </div>
                {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
                  <>
                    <p className="ov_shippingttl mt-2">Delpick Driver</p>
                    <div className="os_driverdtls">
                      <div className="os_drivernames">
                        {/* <img className='os_driverimg' src={require('../assests/images/drivers.png')}/> */}
                        <div>
                          <p className="ov_shippingttl m-0">
                            {Orderdata?.Driver?.FirstName}{" "}
                            {Orderdata?.Driver?.LastName}
                          </p>
                          <p className="ov_address">
                            {Orderdata?.Driver?.Mobile}
                          </p>
                        </div>
                      </div>
                      {/* <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/> */}
                    </div>
                  </>
                )}
                <div className="">
                  {(Orderdata?.OrderType).toLocaleLowerCase() == "delivery" && (
                    <>
                      <p className="ov_shippingttl mt-2">Delivery Address</p>
                      <p className="ov_shippingttl mt-2">
                        {Orderdata?.DeliveryAddress?.FullName}
                      </p>
                      <p className="ov_address mt-1">
                        {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                        {Orderdata?.DeliveryAddress?.Street},{" "}
                        {Orderdata?.DeliveryAddress?.City}{" "}
                        {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                        {Orderdata?.DeliveryAddress?.Area}
                      </p>
                    </>
                  )}

                  <div className="os_greentick_txt mt-1">
                    {/* <img className='me-2' src={require('../assests/images/greentick.png')}/> */}
                    <p className="ov_address">
                      Order will be{" "}
                      {Orderdata?.OrderType == "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {moment(Orderdata?.DeliveryTime).format(
                        "Do MMM , HH:MM A"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </>
        )}
      {console.log("skjhdjsfjsfs", Orderdata)}
      {Orderdata?.Time?.cancelbyseller && (
        <>
          <div className="rv_rowborder">
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(new Date(Orderdata?.Time?.cancelbyseller))
                    ? "Today"
                    : moment(Orderdata?.DeliveryTime).format("DD/MM/YYYY")}
                </p>
                <p className="ov_address mt-2">
                  {moment(new Date(Orderdata?.Time?.cancelbyseller)).format(
                    "HH:MM A"
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
              <>
                <div className="">
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                </div>
              </>
            )}
            <div className="ov_pendingbtn">
              <button className="blue_btn" disabled={true}>
                Cancelled
              </button>
            </div>
          </div>
          <br />
        </>
      )}

      {((Orderdata?.Time?.Pickup &&
        Orderdata?.OrderType?.toLocaleLowerCase() == "delivery") ||
        Orderdata?.Time?.completed ||
        Orderdata?.Time?.returned) && (
        <>
          <div className="rv_rowborder">
            {Orderdata?.Status != "completed" ? (
              <div className="ov_pendingbtn">
                <button className="orange_btn">
                  {" "}
                  {Orderdata?.Status == "returned" ? "Returned" : "In transit"}
                </button>
              </div>
            ) : (
              <div className="ov_pendingbtn">
                <button className="green_btn">Completed</button>
              </div>
            )}
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {Orderdata?.Time?.completed
                    ? "Delivered"
                    : Orderdata?.Time?.returned
                    ? "Returned"
                    : "Picked"}{" "}
                  {isToday(
                    new Date(
                      Orderdata?.Time?.completed
                        ? Orderdata?.Time?.completed
                        : Orderdata?.Time?.returned
                        ? Orderdata?.Time?.returned
                        : Orderdata?.Time?.Pickup
                    )
                  )
                    ? "Today"
                    : DateTimeForm(
                        new Date(
                          Orderdata?.Time?.completed
                            ? Orderdata?.Time?.completed
                            : Orderdata?.Time?.returned
                            ? Orderdata?.Time?.returned
                            : Orderdata?.Time?.Pickup
                        ),
                        true,
                        undefined,
                        undefined
                      )}
                </p>
                <p className="ov_address mt-2">
                  {DateTimeForm(
                    new Date(
                      Orderdata?.Time?.completed
                        ? Orderdata?.Time?.completed
                        : Orderdata?.Time?.returned
                        ? Orderdata?.Time?.returned
                        : Orderdata?.Time?.Pickup
                    ),
                    undefined,
                    true,
                    true
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>

            <p className="ov_shippingttl mt-2">Delpick Driver</p>
            <div className="os_driverdtls">
              <div className="os_drivernames">
                {/* <img className='os_driverimg' src={require('../assests/images/drivers.png')}/> */}
                <div>
                  <p className="ov_shippingttl m-0">
                    {Orderdata?.Driver?.FirstName} {Orderdata?.Driver?.LastName}
                  </p>
                  <p className="ov_address">{Orderdata?.Driver?.Mobile}</p>
                </div>
              </div>
              {/* <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/> */}
            </div>
            <div className="">
              {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
                <>
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                </>
              )}
              <div className="os_greentick_txt mt-1">
                <p className="ov_address">
                  {Orderdata.Status == "completed" ||
                  Orderdata.Status == "returned" ? (
                    <>
                      Order{" "}
                      {Orderdata.Status == "returned"
                        ? "returned"
                        : Orderdata?.OrderType?.toLocaleLowerCase() ==
                          "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {DateTimeForm(
                        Orderdata?.Time?.completed
                          ? Orderdata?.Time?.completed
                          : Orderdata?.Time?.returned,
                        undefined,
                        true,
                        true
                      )}
                    </>
                  ) : (
                    <>
                      Order will be{" "}
                      {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {DateTimeForm(
                        Orderdata?.DeliveryTime,
                        undefined,
                        true,
                        true
                      )}
                    </>
                  )}
                </p>
              </div>
              {/* <div className="os_greentick_txt mt-1">
                
                <p className="ov_address">
                  Order will be{" "}
                  {Orderdata?.OrderType == "Delivery" ? "delivered" : "pickup"}{" "}
                  at{" "}
                  {DateTimeForm(Orderdata?.DeliveryTime,undefined,true,true)}
                </p>
              </div> */}
            </div>
          </div>
          <br />
        </>
      )}

      {/* {Orderdata?.Time?.completed && (
        <>
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button className="green_btn">Completed</button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(new Date(Orderdata?.Time?.completed))
                    ? "Today"
                    : moment(Orderdata?.completed).format("DD/MM/YYYY")}
                </p>
                <p className="ov_address mt-2">
                  {moment(new Date(Orderdata?.Time?.completed)).format(
                    "HH:MM A"
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {
              Orderdata?.Driver?._id &&
              <>
              <p className="ov_shippingttl mt-2">Jamske Driver</p>
            <div className="os_driverdtls">
              <div className="os_drivernames">
                <div>
                  <p className="ov_shippingttl m-0">{Orderdata?.Driver?.FirstName} {Orderdata?.Driver?.LastName}</p>
                  <p className="ov_address">{Orderdata?.Driver?.Mobile}</p>
                </div>
              </div>
            </div>
              </>
            }
            
            <div className="">
              {Orderdata?.OrderType == "delivery" && (
                <>
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                </>
              )}
              <div className="os_greentick_txt mt-1">
                <p className="ov_address">
                  Order{" "}
                  {Orderdata?.OrderType == "Delivery" ? "delivered" : "pickup"}{" "}
                  at{" "}
                  {moment(Orderdata?.Time?.completed).format(
                    "Do MMM , HH:MM A"
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default OrderStatus;
