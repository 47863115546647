import React, { useEffect, useState, useCallback, useMemo } from "react";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import Select from "react-select";
import { isEmpty, LoadToast, updateToast } from "../../actions/common";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import useAxiosFile from "../../actions/useaxios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";

function CategoryPage() {
  const { toggle, payload } = useSelector((state) => state.seller_panel.User);

  const axiosFile = useAxiosFile();
  const [sellercat, setsellercat] = useState();
  const [ValidateError, SetValidateError] = useState({});

  const [shoplist, SetShopList] = useState([]);

  const [form, setForm] = useState({
    _id: "",
    CategoryName: "",
    ShopId: "",
  });

  useEffect(() => {
    console.log("kjhfjhkskfs", payload);
    if (payload?._id) {
      getsellercat();
      getshop();
    }
  }, [payload?._id]);

  const getshop = async () => {
    let Resp = await axiosFile.getshop({
      SellerId: payload?._id,
      from: "sel_cat",
    });
    console.log("dfjkdjfhkdlshfd", Resp);
    SetShopList(Resp?.data);
  };

  const OnChange = (e, ids) => {
    SetValidateError({});
    console.log("kjskjfsjkfs", e, ids);
    const id = e?.target?.id ?? ids,
      value = e?.target?.value ?? e?.value;
    setForm({
      ...form,
      ...{ [id]: value },
    });
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  console.log("dsahdjdkasdsa", form);

  const columns = [
    {
      dataField: "hash",
      text: "S.no",
      formatter: (cell, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    { dataField: "CategoryName", text: "Category Name", sort: true },
    {
      dataField: "ShopId",
      text: "Shop Name",
      formatter: (cell, row) => <div>{cell?.BrandName}</div>,
    },
    {
      dataField: "hideShow",
      text: "Edit",
      formatter: (cell, row) => (
        <button
          className="tabled_bluebtn"
          onClick={() =>
            setForm({
              ...form,
              ...{
                _id: row?._id,
                CategoryName: row?.CategoryName,
                ShopId: row?.ShopId?._id,
              },
            })
          }
        >
          {"Edit"}
        </button>
      ),
    },
    {
      dataField: "hideShow",
      text: "Hide/Show",
      formatter: (cell, row) => (
        <div>
          <button
            className="tabled_bluebtn"
            onClick={() => hideShowCategory(row?._id)}
          >
            {cell == "visible" ? "Hide" : "Show"}
          </button>
        </div>
      ),
    },
  ];

  const Validation = useCallback(
    (data) => {
      let ValidateError = {};

      if (isEmpty(data.CategoryName))
        ValidateError.CategoryName = "CategoryName Required";
      if (isEmpty(data.ShopId)) ValidateError.ShopId = "Shop Required";

      return ValidateError;
    },
    [ValidateError]
  );

  const onsubmit = async () => {
    var load = LoadToast("Validating Form");
    let error = Validation(form);
    if (!isEmpty(error)) {
      updateToast(load, "fix", "error");
      SetValidateError(error);
    } else {
      if (form?._id) {
        let senddata = form;
        senddata.CategoryId = form?._id;
        senddata.from = "edit";
        let Resp = await axiosFile.addsellercat(senddata);
        console.log("resppppp", Resp);
        if (Resp?.success == "success") {
          console.log("hasvdgh");
          setForm({
            ...form,
            ...{ id: "", BrandName: "", CategoryName: "" },
          });
          getsellercat();
          updateToast(load, Resp?.success, Resp?.success);
        } else {
          toast.error(Resp?.msg);
        }
      } else {
        let senddata = form;
        senddata.from = "Add";
        senddata.SellerId = payload._id;
        console.log("dskashdasdsadas", senddata);
        let Resp = await axiosFile.addsellercat(senddata);
        console.log("rcarp", Resp);
        console.log("asasa", load);
        if (Resp?.success == "success") {
          setForm({ ...form, ...{ CategoryName: "" } });
          updateToast(load, Resp?.success, Resp?.success);
          console.log("hasvdgh");
          getsellercat();
          setForm({
            ...form,
            ...{ id: "", BrandName: "", CategoryName: "" },
          });
          // toast.success(Resp?.msg)
        } else {
          updateToast(load, Resp?.success ?? "Can't save now", Resp?.success);
        }
      }
    }
  };

  const getsellercat = async () => {
    console.log("bdhsbdh");
    var resp = await axiosFile.getsellercatList({
      from: "seller",
      SellerId: payload?._id,
    });
    console.log("sellrert", resp);
    setsellercat(resp.data);
  };

  const hideShowCategory = async (_id) => {
    const toastid = LoadToast();
    var payload = { _id };
    var resp = await axiosFile.hideShowCat(payload);
    console.log("sds", resp);
    if (resp?.status) {
      updateToast(toastid, "Visibility Changed Successfully", "success");
      getsellercat();
    } else updateToast(toastid, "Try Again ", "error");
  };

  return (
    <div>
      {console.log("JSIUADHUISOHD", form)}
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Seller Category</p>
                        </div>
                        <div className="rightside"></div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="sec_border catset_border">
                          <div className="cat_lefttab_head">
                            <p className="cat_lefthead_txt">
                              Create new category
                            </p>
                          </div>

                          <div className="cat_creatingcates">
                            <p className="cat_newcreate_ttls">Category Name</p>
                            <input
                              className="sigin_inputs cat_newinput"
                              type="text"
                              id="CategoryName"
                              value={form.CategoryName}
                              placeholder="Enter category name"
                              onChange={(e) => OnChange(e)}
                            />
                            {ValidateError && ValidateError.CategoryName && (
                              <span className="error-msg">
                                {ValidateError.CategoryName}
                              </span>
                            )}
                            <p className="cat_newcreate_ttls">Shop Name</p>
                            <Select
                              menuIsOpen={true}
                              value={{
                                label: (shoplist || []).filter(
                                  (it) => it._id == form?.ShopId
                                )[0]?.BrandName,
                                value: form?.ShopId,
                              }}
                              options={shoplist}
                              styles={stylesgraybg}
                              onChange={(e) => OnChange(e, "ShopId")}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Choose Type"
                            />
                            {ValidateError && ValidateError.CategoryType && (
                              <span className="error-msg">
                                {ValidateError.CategoryType}
                              </span>
                            )}
                            {/* <p className="cat_newcreate_ttls">Sub Categories</p>
                            <Select
                              options={SubCategorys}
                              styles={stylesgraybg}
                              value={subcat}
                              // onChange={setSelectedOption}
                              onChange={(e) => Onchangesubcategory(e)}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Choose Type"
                            />
                            {ValidateError && ValidateError.SubCategory && (
                              <span className="error-msg">
                                {ValidateError.SubCategory}
                              </span>
                            )} */}
                            {/* <p className='cat_newcreate_ttls'>Status</p> */}
                            {/* <input className='sigin_inputs cat_newinput' type="text" placeholder='status' value={form?.Status} id="Status" onChange={(e) => OnChange(e)}/> */}
                            <div className="cp_createclear_btns">
                              <button
                                className="blue_btn createclear_btn"
                                onClick={() => onsubmit()}
                              >
                                {form?._id ? "Update" : "Create"}
                              </button>
                              <button
                                className="blue_btn createclear_btn"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    ...{
                                      _id: "",
                                      CategoryName: "",
                                      ShopId: "",
                                    },
                                  })
                                }
                              >
                                {"Clear"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {useMemo(
                        () => (
                          <div className="col-lg-8 col-md-8 col-12">
                            {sellercat?.length > 0 && sellercat && (
                              <div className="ffd_table selcat_tabled m-0">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={sellercat}
                                />
                              </div>
                            )}
                          </div>
                        ),
                        [sellercat]
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryPage;
