import { Route } from "react-router-dom";
import Protected from "./Protected";
import SignIn from "../Views/SignIn";
import ForgotPassword from "../Views/ForgotPassword";
import OtpVerification from "../Views/OtpVerification";
import Register from "../Views/Register";
import Changepass from "../Views/changepassword";
export const UserRoutes = (Auth, stripe, elements) => {
  // console.log("Auth>>>>>>>>>>",Auth)
  return (
    <>
      <Route
        path="/signin"
        element={
          <Protected isSignedIn={Auth}>
            <SignIn />
          </Protected>
        }
      />
      <Route
        path="/forgotpw"
        element={
          <Protected isSignedIn={Auth}>
            <ForgotPassword />
          </Protected>
        }
      />
      <Route
        path="/otpverification"
        element={
          <Protected isSignedIn={Auth}>
            <OtpVerification />
          </Protected>
        }
      />
      <Route
        path="/"
        element={
          <Protected isSignedIn={Auth}>
            <Register />
          </Protected>
        }
      />

      <Route
        path="/Changepass"
        element={
          <Protected isSignedIn={Auth}>
            <Changepass />
          </Protected>
        }
      />
    </>
  );
};
