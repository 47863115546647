import React from 'react'
import Datetime from 'react-datetime';

function DateTime() {
  return (
    <>
    <div className='w-100'>
    <Datetime />
    </div>
    </>
  )
}

export default DateTime