
import * as CryptoJs from 'crypto-js'
import Config from '../actions/config'
import { toast } from "react-toastify"
export const isEmpty = value =>
value === undefined ||
value === null ||
value == false ||
value == 'false' ||
(typeof value === 'object' && Object.keys(value).length === 0) ||
(typeof value === 'string' && value.trim().length === 0) ||
(typeof value === 'string' && value === '0')||
(typeof value === 'number' && value === 0)||
(Array.isArray(value)  && value.length === 0);


// Address cut function like 0x123...345
export const address_showing=(item)=>{
    if(item&&item.toString().length>30){
    var slice_front = item.slice(0,8)
    var slice_end  = item.slice(item.length-6,item.length-1)
    return slice_front+'...'+slice_end
    }
    else return item
}

// Copy Text
export const copydata = (data) =>{
    var copyText = data;
     navigator?.clipboard?.writeText(copyText);
     //toast.success("Copied Successfully")
}
export const EncryptData = (data) => {
  if(typeof(data)=='string'){
    return  CryptoJs.AES.encrypt(data,Config.KEY).toString()
  }
  else{
    return  CryptoJs.AES.encrypt(JSON.stringify(data),Config.KEY).toString()
  }
}

export const DecryptData = (data) => {
  try{
  var bytes = CryptoJs.AES.decrypt(data, Config.KEY);
  console.log('llllll', bytes)
  var decryptedData;
  try {
    decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
  }
  catch (err) {
    console.log('error', err)
    try {
      decryptedData = bytes.toString(CryptoJs.enc.Utf8);
      console.log('ksdkdsfsfa',decryptedData)

    }
    catch (dcpterr) {
      console.log('error',dcpterr)
      return undefined;
    }
  }
  return decryptedData;
  }
  catch(error){
    console.log('DecryptDataerror',error)
    return undefined;
  }
}

export const NumANdDotOnly = (data) => {
  var data = data.toString()
   var str = data ? data.includes('.') ? data.split('.').length >=3 ? (data.split('.')[0] + '.' + data.split('.')[1]).toString() : data : data : data
   return str.toString().replace(Config.NumDigitOnly,'')
 }
export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly,'')
}



const LoadToast = (data) => {return toast.loading(data??"Loading.....")}
const updateToast = (id,data,type,pos) => {
  try{
    
    if (id) {
      if (data == "fix") {
        data = "Fix All Validation"
        type = "error"
      }
      if (type == "error") type = toast.TYPE.ERROR
      if(pos) pos = toast.POSITION.TOP_CENTER
      if (type == "success") type = toast.TYPE.SUCCESS
      if (type == "info") type = toast.TYPE.INFO
      console.log("===========", type, data, id)
      return toast.update(id, { render: data, type: type, isLoading: false, autoClose: 1000 ,position:pos})
      // console.log("===========", type, data, id)
      // return toast.update(id, { render: data, type: type, autoClose: 1000 })
    }
    else {
      console.log('ksjgldkfjgkldf',type,data,pos)
      return toast[type]( data ,  {
        position: pos,
         autoClose: 700
      })
    }
}
catch(e){
    console.error("tpast update",e)
    return false
}
}


export {LoadToast , updateToast}

export const isToday = (dateToCheck) => {
  const today = new Date();
   
  const isSameDate =
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear();

  return isSameDate;
};

export const MinuteHourFormat = (data) => {
  return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
}



export const CurrentTime=(data) => {
  data = data ?? ''
  return `${new Date().getDate()}.${new Date().getMonth()+1}.${new Date().getFullYear()}`
}

export let isDate = function (input) {
  if (Object.prototype.toString.call(input) === "[object Date]")
    return true;
  return false;
};

export  const DateTimeForm = (date,datealone,timealone,ampm) => {
  try{
    console.log('kshdjshfsk',date,datealone)
    if(datealone){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
    }
    else if(timealone){
      if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM' }`
      }
      else{
        return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
      }
    }
    else if(ampm){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
    }
    return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
  }
  catch(err){
    return "No Date"
  }
}