import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import OrderStatus from "../Components/OrderStatus";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { DecryptData, isEmpty } from "../actions/common";
import moment from "moment";
import { toast } from "react-toastify";

function OrdersView() {
  const { toggle, payload, token } = useSelector(
    (state) => state.seller_panel.User
  );
  const location = useLocation();

  const axiosfile = useAxiosFile();

  const [Id, Setid] = useState("");
  const [Detail, SetDetail] = useState({});

  function createData(no, orderid, name, type, date) {
    return { no, orderid, name, type, date };
  }

  const latestorders = [
    createData(1, "Coco Strawberry Pancake", "$9.49", "1", "$9.49"),
    createData(2, "Coco Strawberry Pancake", "$2.50", "2", "$5.00"),
    createData(3, "Coco Strawberry Pancake", "$2.50", "2", "$5.00"),
  ];

  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?id="));
    if (location?.search) {
      let paramdata = location.search.split("?id=")[1];
      console.log("llsflklfk", DecryptData(paramdata));
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  useEffect(() => {
    console.log("ksjksjkf", Id);
    if (Id) {
      GetOrderDetail();
    }
  }, [Id, payload]);

  const GetOrderDetail = async () => {
    let Resp = await axiosfile.OrderDetail({ id: Id });
    console.log("kdjklsklj", Resp);
    SetDetail(Resp?.data?.Order);
  };

  console.log("ljdskgdskgs", Detail);

  const OrderAction = async (action) => {
    console.log("kfsjgdflgdsf", action);
    let senddata = {
      OrderId: Detail._id,
      Status: action,
    };
    let Resp = await axiosfile.OrderStatusUpdate(senddata);
    console.log("kjjfdjfkjskj", Resp, senddata);
    if (Resp?.success == "success") {
      toast.success("Status Updated Successfully");
    } else {
      toast.error(Resp?.msg);
    }
    GetOrderDetail();
    console.log("ljglkdfgldfgfds", Resp);
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            {console.log("ksfhskhfsfs", Detail)}
            <div className="container custom_container">
              <div className="row">
                <div className="innerpages_wid">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset"
                        : "content-sec-inner"
                    }
                  >
                    <section id="content-wrapper">
                      <Row>
                        <Col
                          xxl={9}
                          xl={9}
                          lg={8}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          
                          <div className="rv_rowborder">
                          <div className="logo_invoice">
                            {/* <img className='mainlogo_al' src={require('../assests/images/jamske.png')}/> */}
                            <p className="invoice_txt">
                              invoice <b>#{Detail?.OrderNumber}</b>
                            </p>
                          </div>
                          <div className="ov_adrsdate_dtls">
                            <p className="ov_address">
                              {Detail?.ShopId?.Address?.StreetNumber
                                ? Detail?.ShopId?.Address?.StreetNumber + ","
                                : ""}
                              {Detail?.ShopId?.Address?.StreetName
                                ? Detail?.ShopId?.Address?.StreetName + ","
                                : ""}
                              {Detail?.ShopId?.Address?.City
                                ? Detail?.ShopId?.Address?.City + ","
                                : ""}
                              {Detail?.ShopId?.Address?.Country
                                ? Detail?.ShopId?.Address?.Country + "-"
                                : ""}
                              {Detail?.ShopId?.Address?.Zipcode
                                ? Detail?.ShopId?.Address?.Zipcode
                                : ""}
                              <br />
                              {Detail?.ShopId?.Mobile}
                              <br />
                              {Detail?.ShopId?.EmailId}
                            </p>
                            <div className="ov_adrsdate_right">
                              <div>
                                <p className="ov_address">Date Issued : </p>
                                <p className="ov_address mt-2">Time : </p>
                              </div>
                              <div>
                                <p className="value_datetime">
                                  {moment(Detail?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p className="value_datetime mt-2">
                                  {moment(Detail?.createdAt).format("HH:MM A")}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="ov_invoicepayment_dtls">
                            <div className="ov_invoicepayment">
                              <p>Invoice to</p>
                              <p className="ov_invoicename mt-2">
                                {Detail?.DeliveryAddress?.FullName}
                              </p>
                              <p className="mt-2">
                                {isEmpty(Detail?.Address) ? (
                                  <>
                                    {Detail?.DeliveryAddress?.Doornumber
                                      ? Detail?.DeliveryAddress?.Doornumber +
                                        ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Street
                                      ? Detail?.DeliveryAddress?.Street + ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Street2
                                      ? Detail?.DeliveryAddress?.Street2 + ","
                                      : ""}
                                    <br />
                                    {Detail?.DeliveryAddress?.City
                                      ? Detail?.DeliveryAddress?.City + ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Area
                                      ? Detail?.DeliveryAddress?.Area + ","
                                      : ""}
                                    -
                                    {Detail?.DeliveryAddress?.Zipcode
                                      ? Detail?.DeliveryAddress?.Zipcode + ","
                                      : ""}
                                    <br />
                                    {Detail?.DeliveryAddress?.Mobile}
                                    <br />
                                    {Detail?.DeliveryAddress?.EmailId}
                                  </>
                                ) : (
                                  <>
                                    {Detail?.Address?.Doornumber
                                      ? Detail?.Address?.Doornumber + ","
                                      : ""}
                                    {Detail?.Address?.Street
                                      ? Detail?.Address?.Street + ","
                                      : ""}
                                    {Detail?.Address?.Street2
                                      ? Detail?.Address?.Street2 + ","
                                      : ""}
                                    <br />
                                    {Detail?.Address?.City
                                      ? Detail?.Address?.City + ","
                                      : ""}
                                    {Detail?.Address?.Area
                                      ? Detail?.Address?.Area + ","
                                      : ""}
                                    -
                                    {Detail?.Address?.Zipcode
                                      ? Detail?.Address?.Zipcode + ","
                                      : ""}
                                    <br />
                                    {Detail?.Address?.Mobile}
                                    <br />
                                    {Detail?.Address?.EmailId}
                                  </>
                                )}
                              </p>
                            </div>
                            <div>
                              <p className="ov_paymentdtl_title">
                                Payment Details
                              </p>
                              <div className="ov_paymentdtls">
                                <div>
                                  <p className="ov_address ov_address_wrap">
                                    Total Amount:
                                  </p>
                                  <p className="ov_address ov_address_wrap mt-2">
                                    Payment Status:
                                  </p>
                                  <p className="ov_address ov_address_wrap mt-2">
                                    Transaction ID:
                                  </p>
                                </div>
                                <div className="ps-2">
                                  <p className="value_datetime">
                                    {Detail?.ShopId?.CurrencySymbol}
                                    {Detail?.Price?.PayAmount}
                                  </p>
                                  <p className="value_datetime greentxt mt-2">
                                    {isEmpty(Detail?.Time?.Paid)
                                      ? "Pending"
                                      : "Paid"}
                                  </p>
                                  {isEmpty(Detail?.Time?.Paid) ? (
                                    <p className="value_datetime mt-2">
                                      Pending
                                    </p>
                                  ) : (
                                    <p className="value_datetime mt-2">
                                      #{Detail?.TransactionHistory?.object?.id}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className='order_tables_cmn'>
                  <StyledDataGrid
                    
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rows={rows}
                    columns={columns}
                    components={{
                      Pagination: CustomPagination,
                    }}
                    className='campaign_muitbl'
                   

                  />


                </div> */}

                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow className="ov_tablehead">
                                  <TableCell>No</TableCell>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="left">Rate</TableCell>
                                  <TableCell align="left">Quantity</TableCell>
                                  <TableCell align="left">Totals</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Detail?.CartId?.length > 0 &&
                                  Detail?.CartId?.map((latestorders, ind) => (
                                    <TableRow
                                      className="ov_tabledtls"
                                      key={latestorders._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {ind + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {latestorders?.ProductId?.ProductName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {Detail?.ShopId?.CurrencySymbol}{" "}
                                        {latestorders?.ProductId?.Price}
                                      </TableCell>
                                      <TableCell align="left">
                                        {latestorders.Quantity}
                                      </TableCell>
                                      <TableCell align="left">
                                        {Number(latestorders.Quantity) *
                                          Number(
                                            latestorders?.ProductId?.Price
                                          )}
                                      </TableCell>
                                      {/* <TableCell className='price_clr' align="center">{latestorders.price} <i class="fa fa-angle-right" aria-hidden="true"></i></TableCell> */}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <div className="ov_totalamnt_full">
                            <div className="ov_bottomsug">
                              <p className="ov_totalamnt_left mt-0"></p>
                              <p className="ov_address"></p>
                            </div>
                            <div className="ov_totalamnt_whole">
                              <div className="ov_totalamnt_top">
                                <div>
                                  <p className="ov_address">Subtotal</p>
                                  <p className="ov_address mt-2">Taxes</p>
                                  <p className="ov_address mt-2">Commission</p>
                                  {/* <p className='ov_address mt-2'>Discount</p> */}
                                  <p className="ov_address mt-2">Seller Tip</p>
                                </div>
                                <div>
                                  <p className="value_datetime">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Detail?.Price?.CartValue}
                                  </p>
                                  <p className="value_datetime mt-2">
                                    +{Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Detail?.Price?.Taxes}
                                  </p>
                                  <p className="value_datetime mt-2">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Detail?.Price?.CartValue}
                                  </p>
                                  {/* <p className='value_datetime mt-2'>{Detail?.ShopId?.CurrencySymbol} {Detail?.Price?.Coupon}</p> */}
                                  <p className="value_datetime mt-2">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Detail?.Price?.SellerTipsInValue}
                                  </p>
                                </div>
                              </div>
                              <div className="ov_totalamnt_bottom">
                                <p className="ov_totalamnt_left">
                                  Total Amount
                                </p>
                                <p className="ov_totalamnt_right">
                                  {Detail?.ShopId?.CurrencySymbol}{" "}
                                  {Detail?.Price?.PayAmount}
                                </p>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div className="rv_rowborder mt-3">
                          {Detail?.Driver?._id && (
                                <div>
                                  <p className="ov_paymentdtl_title mar_tp_lg">
                                    Choosen Driver For Pickup
                                  </p>

                                  <div className="ov_paymentdtls payment_width">
                                    <div>
                                      <p className="ov_address wht_spc_nowrap">
                                        Driver Name
                                      </p>
                                      <p className="ov_address mt-2 wht_spc_nowrap">
                                        Email-Id
                                      </p>
                                      <p className="ov_address mt-2 wht_spc_nowrap">
                                        Mobile
                                      </p>
                                    </div>
                                    <div>
                                      <p className="value_datetime no_wrap_tx_rig">
                                        : {Detail?.Driver?.FirstName}{" "}
                                        {Detail?.Driver?.LastName}
                                      </p>
                                      <p className="value_datetime greentxt mt-2 no_wrap_tx_rig">
                                        : {Detail?.Driver?.EmailId}
                                      </p>
                                      <p className="value_datetime mt-2 no_wrap_tx_rig">
                                        : {Detail?.Driver?.Mobile}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col
                          xxl={3}
                          xl={3}
                          lg={4}
                          md={12}
                          sm={12}
                          xs={12}
                          className="ov_reducepad"
                        >
                          <OrderStatus
                            Orderdata={Detail}
                            OrderAction={OrderAction}
                          />
                        </Col>
                      </Row>

                   
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrdersView;
