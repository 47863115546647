import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useSelector } from "react-redux";
import useAxiosFile from "../actions/useaxios";
import { DateTimeForm, EncryptData, isEmpty } from "../actions/common";
import { toast } from "react-toastify";

function Orders() {
  const { toggle, payload, token } = useSelector(
    (state) => state.seller_panel.User
  );

  const axiosFile = useAxiosFile();

  const [selectedOption, setSelectedOption] = useState(null);
  const [TabName, SetTabName] = useState("neworder");
  const [Tabdatas, SetTabDatas] = useState({
    neworder: { page: 1, loader: true, data: [] },
  });
  const [shoplist, SetShopList] = useState([]);
  const [currentShop, SetCurrentShop] = useState({ label: "", value: "" });

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const columns = [
    { dataField: "OrderNumber", text: "Order Id" },
    // {dataField:"vendorName",text:"Vendor Name",sort:true},
    {
      dataField: "UserId",
      text: "Customer Name",
      sort: false,
      formatter: (cell, val) => (
        <div>
          {cell?.FirstName
            ? cell?.FirstName + " " + cell?.LastName
            : cell?.EmailId}
        </div>
      ),
    },
    {
      dataField: "Price",
      text: "Items",
      sort: false,
      formatter: (cell, row) => <div>{cell?.totalItems}</div>,
    },
    {
      dataField: "Price",
      text: "Type",
      sort: false,
      formatter: (cell, row) => <div>{cell?.Type}</div>,
    },
    // {dataField:"operationtime",text:"Operation Time",sort:true},

    {
      dataField: "TransactionHistory",
      text: "Payment",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {console.log("ldsjljsflskfsf", cell)}
          {cell?.object?.payment_method_types?.[0]
            ? cell?.object?.payment_method_types?.[0]
            : "card"}
        </div>
      ),
    },
    {
      dataField: "DeliveryTime",
      text: "Delivered Time",
      sort: true,
      formatter: (cell, row) => (
        <div>{DateTimeForm(cell, null, null, true)}</div>
      ),
    },
    {
      dataField: "Price",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {console.log("lskfjsfs", row)}
          {row?.ShopId?.CurrencySymbol} {cell?.PayAmount}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, val) => (
        <div class="dropdown table_viewdropdown">
          {console.log("jdhjshdsjhfs", val)}
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu men_indi_tab">
            <li>
              <Link
                class="dropdown-item"
                to={`/orderdetail?id=${EncryptData(val?._id)}`}
              >
                View
              </Link>
            </li>
            {isEmpty(val?.Time?.ApprovedBySeller) &&
              isEmpty(val?.Time?.cancelbyseller) &&
              isEmpty(val?.Time?.returned) && (
                <>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li onClick={() => OrderAction(val, "approvebyseller")}>
                    <Link class="dropdown-item" to="">
                      Accept
                    </Link>
                  </li>
                </>
              )}
            {!isEmpty(val?.Time?.ApprovedBySeller) &&
              isEmpty(val?.Time?.cancelbyseller) &&
              isEmpty(val?.Time?.Dispatch) &&
              isEmpty(val?.Time?.preparing) &&
              isEmpty(val?.Time?.returned) && (
                <>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li onClick={() => OrderAction(val, "preparing")}>
                    <Link class="dropdown-item" to="">
                      Preparing
                    </Link>
                  </li>
                </>
              )}
            {!isEmpty(val?.Time?.ApprovedBySeller) &&
              isEmpty(val?.Time?.Dispatch) &&
              isEmpty(val?.Time?.cancelbyseller) &&
              isEmpty(val?.Time?.returned) && (
                <>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li onClick={() => OrderAction(val, "dispatch")}>
                    <Link class="dropdown-item" to="">
                      Order Is Ready
                    </Link>
                  </li>{" "}
                </>
              )}
            {val?.Time?.Dispatch &&
              isEmpty(val?.Time?.completed) &&
              isEmpty(val?.Time?.Shipping) &&
              isEmpty(val?.Time?.Pickup) &&
              isEmpty(val?.Time?.returned) && (
                <>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li onClick={() => OrderAction(val, "picked")}>
                    <Link class="dropdown-item" to="">
                      Pickup
                    </Link>
                  </li>
                </>
              )}
            {isEmpty(val?.Time?.Dispatch) &&
              isEmpty(val?.Time?.cancelbyseller) &&
              isEmpty(val?.Time?.returned) && (
                <>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li onClick={() => OrderAction(val, "cancelbyseller")}>
                    <Link class="dropdown-item" to="">
                      <p className="drpdw_redtxt">Cancel Order</p>
                    </Link>
                  </li>
                </>
              )}
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    Tabdatas[TabName] = { page: 1, data: [], loader: false };
    SetTabDatas(Tabdatas);
    GetOrderList(1, TabName);
  }, [TabName]);

  useEffect(() => {
    console.log("skjfskfs", currentShop);
    if (currentShop?.value) {
      GetOrderList();
    }
  }, [currentShop?.value]);

  useEffect(() => {
    console.log("sifisifosjkflsj", payload);
    if (payload?._id) {
      GetShoplist();
    }
  }, [payload?._id]);

  const GetShoplist = async () => {
    let Resp = await axiosFile.getshop({ SellerId: payload._id });
    console.log("dfjkdjfhkdlshfd", Resp);
    SetShopList(Resp?.data);
    if (Resp?.data?.length > 0) {
      SetCurrentShop({
        label: Resp?.data[0]?.label,
        value: Resp?.data[0]?.value,
      });
    }
  };

  console.log("ksjfksjfs", shoplist, currentShop);

  const GetOrderList = async (page, tab) => {
    let senddata = {
      page: (page ? page : Tabdatas[TabName]?.page) ?? 1,
      TabName: tab ? tab : TabName,
      limit: 500,
      UserId: payload?._id,
      ShopId: currentShop?.value,
      Type: "seller",
    };
    console.log("kdshkdsfdsfsd", currentShop, senddata);
    let Resp = await axiosFile.GetOrders(senddata);
    console.log("kjdhfjhadkfhjda", Resp);

    console.log("lfdkflkgjklsg", page);
    if (senddata.page == 1) {
      SetTabDatas({
        ...Tabdatas,
        ...{
          [TabName]: {
            data: Resp?.data,
            page: page,
            loader: Resp?.data?.length > 0 ? true : false,
          },
        },
      });
    } else {
      SetTabDatas({
        ...Tabdatas,
        ...{
          [TabName]: {
            data: [...Tabdatas[TabName].data, ...Resp?.data],
            page: page,
            loader: Resp?.data?.length > 0 ? true : false,
          },
        },
      });
    }
  };

  const ShopChange = (e) => {
    if (e?.value) {
      SetCurrentShop({ label: e.label, value: e.value });
      SetTabDatas({ [TabName]: { page: 1, loader: true, data: [] } });
    }
  };

  const OrderAction = async (val, action) => {
    console.log("kfsjgdflgdsf", val, action);

    if (
      action == "picked" &&
      isEmpty(val?.Driver) &&
      val?.OrderType == "Delivery"
    ) {
      return toast.error("Delivery Partner Not Assigned", 1000);
    }
    let senddata = {
      OrderId: val._id,
      Status: action,
    };
    let Resp = await axiosFile.OrderStatusUpdate(senddata);
    console.log("kjjfdjfkjskj", Resp, senddata);
    if (Resp?.success == "success") {
      toast.success("Status Updated Successfully");
    } else {
      toast.error(Resp?.msg);
    }
    GetOrderList(1, TabName);
    console.log("ljglkdfgldfgfds", Resp);
  };

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Orders</p>
                        </div>
                        <div className="rightside">
                          {/* <div className='order_calendar_div'>
                              <p>Start Date</p>
                              <img className='order_calendar_ic' src={require('../assets/images/icons/calendar.png')}/>
                              <Datetime className='order_datepick'/>
                            </div>

                            <p className='order_totxt'>To</p>
                            <div className='order_calendar_div'>
                              <p>End Date</p>
                              <img className='order_calendar_ic' src={require('../assets/images/icons/calendar.png')}/>
                              <Datetime className='order_datepick'/>
                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="sec_border">
                        <div className="row">
                          {/* <div className='col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12'>
          <div className='whole_searchbar white_bg'>
     
    <input placeholder="Search term"/>
     </div>
          </div> */}

                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                            <Select
                              // menuIsOpen={true}
                              className="cust_rct_slt"
                              options={shoplist}
                              onChange={(e) => {
                                ShopChange(e);
                              }}
                              value={currentShop}
                              styles={stylesgraybg}
                            />
                          </div>
                          {console.log("kjdhfjkdshfds", Tabdatas)}
                          <div className="orders_tabs">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey={TabName}
                            >
                              <Row>
                                <Col sm={12}>
                                  <Nav
                                    variant="pills"
                                    className="flex orders_tabs"
                                  >
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_orangetab"
                                        eventKey="neworder"
                                        onClick={() => SetTabName("neworder")}
                                      >
                                        New orders
                                        {/* <span className='orders_tabpop orange_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_violettab"
                                        eventKey="upcomingorder"
                                        onClick={() =>
                                          SetTabName("upcomingorder")
                                        }
                                      >
                                        Upcoming orders
                                        {/* <span className='orders_tabpop violet_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_bluetab"
                                        eventKey="approvebyseller"
                                        onClick={() =>
                                          SetTabName("approvebyseller")
                                        }
                                      >
                                        Accepted
                                        {/* <span className='orders_tabpop blue_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_blurbluetab"
                                        eventKey="preparing"
                                        onClick={() => SetTabName("preparing")}
                                      >
                                        Preparing
                                        {/* <span className='orders_tabpop blurblue_tabbg'>5</span>  */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_skybluetab"
                                        eventKey="dispatch"
                                        onClick={() => SetTabName("dispatch")}
                                      >
                                        Dispatched
                                        {/* <span className='orders_tabpop  skyblue_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_orangetab"
                                        eventKey="shipping"
                                        onClick={() => SetTabName("shipping")}
                                      >
                                        Out for Delivery
                                        {/* <span className='orders_tabpop  orange_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_greentab"
                                        eventKey="completed"
                                        onClick={() => SetTabName("completed")}
                                      >
                                        Completed
                                        {/* <span className='orders_tabpop green_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_redtab"
                                        eventKey="cancelbyseller"
                                        onClick={() =>
                                          SetTabName("cancelbyseller")
                                        }
                                      >
                                        Cancel
                                        {/* <span className='orders_tabpop  red_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                                <Col sm={12}>
                                  <Tab.Content>
                                    {console.log(
                                      "dskjhdjshfghsgjfs",
                                      Tabdatas?.[TabName]
                                    )}
                                    {Tabdatas[TabName]?.data?.length > 0 && (
                                      <Tab.Pane eventKey={TabName}>
                                        <div className="ffd_table row orders_altable">
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            columns={columns}
                                            data={Tabdatas?.[TabName]?.data}
                                            pagination={pagination}
                                          />
                                        </div>
                                      </Tab.Pane>
                                    )}
                                    {/* <Tab.Pane eventKey="second">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={schedulecolumns} data={scheduleddata} pagination={pagination}/>
          </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={acceptedcolumns} data={accepteddata} pagination={pagination}/>
          </div> 
            </Tab.Pane>

            <Tab.Pane eventKey="fourth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={preparecolumns} data={preparedata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={orderreadyecolumns} data={orderreadydata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="sixth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={intransitcolumns} data={intransitdata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="seventh">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={completedcolumns} data={completeddata} pagination={pagination}/>
          </div>
            </Tab.Pane>
            <Tab.Pane eventKey="eight">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={cancelcolumns} data={canceldata} pagination={pagination}/>
          </div>
            </Tab.Pane> */}
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
