import React ,{useEffect, useState} from 'react'
import Sidebar from '../../Components/Sidebar'
import Header from '../../Components/Header'
import { Link , NavLink} from 'react-router-dom';
import Select from 'react-select';
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import {Nav,Tab,Row,Col} from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { ReactReduxContext, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import config from '../../actions/config'
import useAxiosFile from '../../actions/useaxios';
import { EncryptData } from '../../actions/common';

function ProductList() {

  const { toggle, payload } = useSelector((state)=>state.seller_panel.User)
  const navigate = useNavigate()
  const axiosFile = useAxiosFile()
const[product,setproduct]=useState()
    const [selectedOption, setSelectedOption] = useState(null);

useEffect(()=>{
  if(payload?._id){
    getProducts();
  }
    
},[payload?._id])


  const getProducts = async () => {
    console.log("bdhsbdh",);
    var resp = await axiosFile.getproduct({SellerId:payload._id});
    console.log("cat", resp.data);
    resp?.data?.map((val,idx)=>{
        console.log("vbnvb",val);
        val.hash=<img className='tab_dummyimg'  src={val?.ProductImage? `${config.IMG_URL}/Product/${val.ProductImage}` :  require('../../assets/images/tablefood.png')}/>
        val.action =  <div class="dropdown table_viewdropdown">
    <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="fa-solid fa-ellipsis"/>
    </button>
    <ul class="dropdown-menu">
      <li><Link class="dropdown-item" to={`/editproduct?Id=${EncryptData(val?._id)}`}>Edit</Link></li>
       <li><a class="dropdown-item" onClick={()=>hideShowCategory(val?.ShowHide == "show"?"hidden":"show",val?.ProductName)}>{val.ShowHide == "hidden"?"Show":"Hide"}</a></li>
    </ul>
  </div> 
    })
    setproduct(resp.data)
  }

  const hideShowCategory = async(data,name)=>{
    var payload = {hideShow:data,catName:name}
    var resp = await axiosFile.hideshowproduct(payload);
    console.log("sds",resp);
    if(resp?.status){
      getProducts()
    }
   
  }




    const pagination = paginationFactory({
      page:1,
      sizePerPage:5,
      lastPageText:">>",
      firstPageText:"<<",
      nextPageText:">",
      prePageText:"<",
      showTotal:true,
      alwaysShowAllBtns:true,
      onPageChange: function (page,sizePerPage){
          console.log('page',page);
          console.log('sizePerPage',sizePerPage)
      },
      onSizePerPageChange: function (page,sizePerPage){
          console.log('page',page);
          console.log('sizePerPage',sizePerPage)
      },
  
  });

  const columns = [
    {dataField:"hash",text:"ProductImage"},
    {dataField:"ProductName",text:"ProductName",sort:true},
    {dataField:"Price",text:"Price",sort:true},
    {dataField:"action",text:"Action"},
  ]

  const data = [
    {id:"1",vendorName:<p className='table_secondarytxt'>McDonald's</p>, customerName:<p className='table_secondarytxt'>John</p>,items:'1',operationtime:'Today 1pm - 2 pm',type:<p className='table_blurblue'>Delivery</p>,payment:<p className='table_green'>Online</p>,time:<p className='table_secondarytxt'>1/17/2022  8:20 PM</p>,amount:<p className='table_secondarytxt'>$5.00</p>,
    action:
    <div class="dropdown table_viewdropdown">
    <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="fa-solid fa-ellipsis"/>
    </button>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" href=''>View</a></li>
      <li><hr class="dropdown-divider"/></li>
      <li><a class="dropdown-item" href=''>Accept</a></li>
      <li><a class="dropdown-item" href=''>Preparing</a></li>
      <li><a class="dropdown-item" href=''>Orders Is Ready</a></li>
      <li><a class="dropdown-item" href=''><p className='drpdw_redtxt'>Cancel Order</p></a></li>
    </ul>
  </div>},
  {id:"1",vendorName:<p className='table_secondarytxt'>McDonald's</p>, customerName:<p className='table_secondarytxt'>John</p>,items:'1',operationtime:'Today 1pm - 2 pm',type:<p className='table_blurblue'>Delivery</p>,payment:<p className='table_green'>Online</p>,time:<p className='table_secondarytxt'>1/17/2022  8:20 PM</p>,amount:<p className='table_secondarytxt'>$5.00</p>,action:
  <div class="dropdown table_viewdropdown">
  <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="fa-solid fa-ellipsis"/>
  </button>
  <ul class="dropdown-menu">
      <li><a class="dropdown-item" href=''>View</a></li>
      <li><hr class="dropdown-divider"/></li>
      <li><a class="dropdown-item" href=''>Accept</a></li>
      <li><a class="dropdown-item" href=''>Preparing</a></li>
      <li><a class="dropdown-item" href=''>Orders Is Ready</a></li>
      <li><a class="dropdown-item" href=''><p className='drpdw_redtxt'>Cancel Order</p></a></li>
    </ul>
  </div>},
  {id:"1",vendorName:<p className='table_secondarytxt'>McDonald's</p>, customerName:<p className='table_secondarytxt'>John</p>,items:'1',operationtime:'Today 1pm - 2 pm',type:<p className='table_blurblue'>Delivery</p>,payment:<p className='table_green'>Online</p>,time:<p className='table_secondarytxt'>1/17/2022  8:20 PM</p>,amount:<p className='table_secondarytxt'>$5.00</p>,action:
  <div class="dropdown table_viewdropdown">
  <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="fa-solid fa-ellipsis"/>
  </button>
  <ul class="dropdown-menu">
      <li><a class="dropdown-item" href=''>View</a></li>
      <li><hr class="dropdown-divider"/></li>
      <li><a class="dropdown-item" href=''>Accept</a></li>
      <li><a class="dropdown-item" href=''>Preparing</a></li>
      <li><a class="dropdown-item" href=''>Orders Is Ready</a></li>
      <li><a class="dropdown-item" href=''><p className='drpdw_redtxt'>Cancel Order</p></a></li>
    </ul>
  </div>},
  {id:"1",vendorName:<p className='table_secondarytxt'>McDonald's</p>, customerName:<p className='table_secondarytxt'>John</p>,items:'1',operationtime:'Today 1pm - 2 pm',type:<p className='table_blurblue'>Delivery</p>,payment:<p className='table_green'>Online</p>,time:<p className='table_secondarytxt'>1/17/2022  8:20 PM</p>,amount:<p className='table_secondarytxt'>$5.00</p>,action:
  <div class="dropdown table_viewdropdown">
  <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="fa-solid fa-ellipsis"/>
  </button>
  <ul class="dropdown-menu">
      <li><a class="dropdown-item" href=''>View</a></li>
      <li><hr class="dropdown-divider"/></li>
      <li><a class="dropdown-item" href=''>Accept</a></li>
      <li><a class="dropdown-item" href=''>Preparing</a></li>
      <li><a class="dropdown-item" href=''>Orders Is Ready</a></li>
      <li><a class="dropdown-item" href=''><p className='drpdw_redtxt'>Cancel Order</p></a></li>
    </ul>
  </div>},
  {id:"1",vendorName:<p className='table_secondarytxt'>McDonald's</p>, customerName:<p className='table_secondarytxt'>John</p>,items:'1',operationtime:'Today 1pm - 2 pm',type:<p className='table_blurblue'>Delivery</p>,payment:<p className='table_green'>Online</p>,time:<p className='table_secondarytxt'>1/17/2022  8:20 PM</p>,amount:<p className='table_secondarytxt'>$5.00</p>,
  action:
  <div class="dropdown table_viewdropdown">
  <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="fa-solid fa-ellipsis"/>
  </button>
  <ul class="dropdown-menu">
      <li><a class="dropdown-item" href=''>View</a></li>
      <li><hr class="dropdown-divider"/></li>
      <li><a class="dropdown-item" href=''>Accept</a></li>
      <li><a class="dropdown-item" href=''>Preparing</a></li>
      <li><a class="dropdown-item" href=''>Orders Is Ready</a></li>
      <li><a class="dropdown-item" href=''><p className='drpdw_redtxt'>Cancel Order</p></a></li>
    </ul>
  </div>},
    
   
  ]





  return(



    <div>
      
        <div className='innerpages'>
          <div className='dashboard_pages'>
        <Header/>
        <div className='container custom_container'>
          <div className='row'>
            <div className='innerpages_wid'>
                <Sidebar/>
              <div className={ toggle ? 'content-sec-inner right_widthset' : 'content-sec-inner'}>
              <section id="content-wrapper">
                <div className='row'>
                <div className='top_title_sec'>
                <div>
        <p className='page_title'>Product List</p>
      </div>
      {/* <div className='rightsec'>
        <p>Delivery Services 
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                </div>
                </div>
<div className='row'>
<div className='orders_tabs'>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="flex orders_tabs">
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
            <button type="button" className='blue_btn add_deliveryprsn' onClick={()=>navigate("/addproduct")}>
       Add Product
     </button>
            <div className='ffd_table row'>
                {product&&
          <BootstrapTable bootstrap4 keyField='id' columns={columns} data={product} pagination={pagination}/>}
          </div>
       
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
          </div>
</div>


              </section>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>

  
  )
}
export default ProductList