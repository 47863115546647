import { combineReducers } from 'redux';
export  const Login = 'Login';
export  const Logout = 'Logout';
export const Stepper = 'stepper';

const initial_login = {
User:{
        token : '',
        payload : null,
        logincheck: false,
        toggle: false,
        EmailId:"",
        shopid:"",
        BrandImage:""
    },
    steppergo:{
        home:false,
        location:false,
        clock:false,
        window:false,
        dummyimage:false
    }
   
}





//console.log("cnnjdbvhcfbihvbdfsj",initial_wallet_connect);
function seller_panel(state=initial_login, action) {
    console.log("vathutan",action)
    switch (action.type) {
        case Login: {
            //console.log("chech come here")

            return {
                ...state,
                ...action.Login
            }
        };
        case Stepper: {
            return {
                ...state,
                ...action.stepper
            }
        }
        case Logout:
            return {
                ...state,
                ...initial_login
            };
        default: {
            //console.log("chech come here not ",action)
            return state;

        }
  }
}
const birdApp = combineReducers({
    seller_panel
      });
      
      export default birdApp;