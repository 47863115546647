import axios from "axios";
import config from "./config";
import { DecryptData, EncryptData, isEmpty } from "./common";
import { useSelector, useDispatch } from "react-redux";
import { data } from "jquery";
import { handleCallback } from "./errorHandler";

const useAxiosFile = () => {
  const usertoken = ("; " + document.cookie)
    .split(`; token_s=`)
    .pop()
    .split(";")[0];
  const authToken = useSelector((state) => state.LoginReducer?.User?.token);
  console.log("authToken", authToken, localStorage?.userauth);
  var token = usertoken ? usertoken : authToken;

  axios.defaults.baseURL = config.BACK_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Authorization"] = token;
  const dispatch = useDispatch();

  const axiosFunc = async (data) => {
    console.log("fytugjhgyju", data);
    try {
      let Resp = await axios(data);
      console.log("datttaaaa", Resp.data, DecryptData(Resp.data));
      if (Resp?.data) {
        Resp.data = isEmpty(DecryptData(Resp.data))
          ? Resp.data
          : DecryptData(Resp.data);
      }
      console.log("kdjsgjsdkgsd", Resp.data);

      return Resp.data;
    } catch (e) {
      console.log("axiosFunc error", e);
      return { success: "error", msg: null };
    }
  };

  const AppenData = (data) => {
    var formdata = new FormData();
    var SendDta = Object.entries(data).map((item) => {
      console.log(
        "gfgfsgfgfdgs",
        item[0],
        item[1],
        typeof item[0],
        typeof item[1]
      );
      if (Array.isArray(item[1])) {
        var come = item[1].map((data) => {
          console.log("sdgsdgs", data);

          if (data?.type && data?.size) {
            formdata.append(item[0], data);
          } else {
            formdata.append(item[0], EncryptData(data));
          }
          return formdata;
        });
        console.log("jhfgfjkgfbbomi", come);

        return come;
      } else {
        if (item[1]?.type && item[1]?.size) {
          formdata.append(item[0], item[1]);
        } else {
          formdata.append(item[0], EncryptData(item[1]));
        }
        return formdata;
      }
    });
    return SendDta;
  };

  const AlreadyExits = handleCallback(async (data) => {
    var senddata = {
      method: "get",
      url: `/seller/AlreadyExits`,
      params: data,
      headers: { Authorization: token },
    };

    let Resp = await axiosFunc(senddata);
    console.log("Resp.data>><<123", Resp);
    if (Resp?.token) {
      document.cookie = "token_s" + "=" + Resp?.token + ";" + ";path=/";
      localStorage.setItem("token_s", Resp?.token);
      dispatch({
        type: "Login",
        Login: {
          User: {
            payload: Resp?.Data[0],
            token: Resp?.token,
          },
        },
      });
    } else if (Resp?.error?.name == "JsonWebTokenError") {
      document.cookie = "token_s" + "=" + "" + ";" + ";path=/";
      localStorage.removeItem("token_s");
      localStorage.removeItem("_id_s");
    }
  });

  const LoginReg = handleCallback(async (data) => {
    console.log("dfjdjfdf", data);

    var formdata = AppenData(data);
    var senddata = {
      method: "post",
      url: `/seller/sellerreg`,
      data: formdata[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    var Resp = await axiosFunc(senddata);
    let _id = Array.isArray(Resp.data) ? Resp.data[0]?._id : Resp.data?._id,
      type = data.Type;
    console.log("Resp.data>><<123login", Resp, senddata, data, _id);
    if (Resp?.token) {
      document.cookie = "token_s" + "=" + Resp?.token + ";" + ";path=/";
    }
    if (_id) {
      localStorage.setItem("_id_s", EncryptData(_id));
      await AlreadyExits({ data: EncryptData({ _id: _id }) });
    }
    // if(Resp?.type == "otp"){
    //   localStorage.setItem("WcZyumloQ",encr(Resp.data))
    // }

    return Resp;
  });

  const getcat = async (data) => {
    try {
      console.log("getoderreview", data);
      var senddata = {
        method: "get",
        url: `/seller/getsubcategory`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const getcatList = async (data) => {
    console.log("gdgdgdg");
    try {
      var senddata = {
        method: "get",
        url: `/admin/getcategory`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const addsellershop = async (data) => {
    var formdata = AppenData(data);
    console.log("lkdsjfkjdslfsd", formdata);
    try {
      var senddata = {
        method: "POST",
        url: `/seller/addseller`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("AddDriver error", err);
      return false;
    }
  };

  const addsellershopimage = async ({ StoreImages, action, ShopId }) => {
    console.log("Adddata", data);
    var formdata = AppenData({ action, ShopId, StoreImages });
    try {
      var senddata = {
        method: "POST",
        url: `/seller/addseller`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("AddDriver error", err);
      return false;
    }
  };
  const getsellercatList = async (data) => {
    console.log("gdgdgdg");
    try {
      var senddata = {
        method: "get",
        params: { data: EncryptData(data) },
        url: `/seller/getsellercategory`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };
  //  createoffer
  const getsubcat = async () => {
    console.log("gdgdgdg");
    try {
      var senddata = {
        method: "get",
        url: `/seller/getsellercategory`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const AddOffer = async (data) => {
    console.log("addoffer", data);

    try {
      var senddata = {
        method: "POST",
        url: `/seller/createoffer`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("Addoffer error", err);
      return false;
    }
  };

  const getshop = async (data) => {
    console.log("data==========", data);
    try {
      var senddata = {
        method: "get",
        url: `/seller/getshop`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const getfindshop = async (data) => {
    console.log("saadsada", data);
    try {
      var senddata = {
        method: "get",
        url: `/seller/getfindshop`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const getmaincat = async () => {
    console.log("gdgdgdg");
    try {
      var senddata = {
        method: "get",
        url: `/seller/getmaincategory`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const addsellercat = async (data) => {
    console.log("addoffer", data);

    try {
      var senddata = {
        method: "POST",
        url: `/seller/categoryaction`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("Addoffer error", err);
      return false;
    }
  };

  const getmaincategory = async (data) => {
    try {
      console.log("getoderreview", data);
      var senddata = {
        method: "get",
        url: `/seller/getmaincat`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const getsubcategory = async (data) => {
    try {
      console.log("getoderreview", data);
      var senddata = {
        method: "get",
        url: `/seller/getsubcat`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const hideShowCat = async (data) => {
    console.log("datacat", data);
    try {
      var resp = {
        method: "POST",
        url: `/seller/hideshowcategory`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(resp);
      console.log("gfgfg", Resp);
      return Resp;
    } catch (err) {
      console.log("err in hide cat", err);
    }
  };

  const AddProduct = async (data) => {
    console.log("AddProductaaaaa", data);
    var formdata = AppenData(data);
    try {
      var senddata = {
        method: "POST",
        url: `/seller/addproduct`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("AddProduct error", err);
      return false;
    }
  };

  const getsubcater = async () => {
    try {
      var senddata = {
        method: "get",
        url: `/seller/getsubcater`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const TestApi = async (data) => {
    var encdata = EncryptData(JSON.stringify(data));
    var senddata = {
      method: "post",
      url: `/user/test`,
      data: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);
    return Resp.data;
  };

  const getproduct = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/seller/getprodut`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };
  const getproductedit = async (data) => {
    try {
      console.log("getoderreview", data);
      var senddata = {
        method: "get",
        url: `/seller/getproductedit`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };
  const hideshowproduct = async (data) => {
    console.log("datacat", data);
    try {
      var resp = {
        method: "POST",
        url: `/seller/hideshowproduct`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(resp);
      console.log("gfgfg", Resp);
      return Resp;
    } catch (err) {
      console.log("err in hide cat", err);
    }
  };

  const getsub = async (data) => {
    try {
      console.log("getoderreview", data);
      var senddata = {
        method: "get",
        url: `/seller/getsubcater`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const Emailvalidation = async (data) => {
    try {
      var senddata = {
        method: "post",
        url: `/seller/emailvalidation`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const GetOrders = handleCallback(async (data) => {
    var resp = {
      method: "get",
      url: `/seller/getorders`,
      params: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const OrderStatusUpdate = handleCallback(async (data) => {
    var resp = {
      method: "post",
      url: `/seller/orderaction`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const OrderDetail = handleCallback(async (data) => {
    var resp = {
      method: "get",
      url: `/seller/orderdetail`,
      params: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const menudetail = handleCallback(async (data) => {
    var resp = {
      method: "post",
      url: `/seller/menudetail`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const sellerDetails = handleCallback(async (data) => {
    console.log("fgser", data);
    var resp = {
      method: "get",
      url: `/seller/sellerDetails`,
      params: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("hgyt", Resp);
    return Resp;
  });

  return {
    LoginReg,
    addsellershopimage,
    Emailvalidation,
    TestApi,
    getcat,
    getcatList,
    AlreadyExits,
    addsellershop,
    AddOffer,
    getsellercatList,
    getshop,
    getfindshop,
    getmaincat,
    addsellercat,
    getmaincategory,
    getsubcategory,
    hideShowCat,
    AddProduct,
    getsubcater,
    getproduct,
    getproductedit,
    hideshowproduct,

    getsub,

    GetOrders,
    OrderStatusUpdate,
    OrderDetail,
    menudetail,
    sellerDetails,
  };
};

export default useAxiosFile;
