import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import useAxiosFile from "../../actions/useaxios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import config from "../../actions/config";

import delpick from "../../assets/images/delpick.png";
import locationCom from "../../assets/svg/Location.svg";
import homeCom from "../../assets/svg/home.svg";
import clockCom from "../../assets/svg/clock.svg";
import coolIconCom from "../../assets/svg/coolicon.svg";

function SellerDetails() {
  const dispatch = useDispatch();

  const { home } = useSelector((state) => state.seller_panel.steppergo);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: false,
          clock: false,
          window: false,
          dummyimage: false,
        },
      },
    });
  }, []);

  const changeStepper = () => {
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: true,
          clock: false,
          window: false,
          dummyimage: false,
        },
      },
    });
  };

  const [sellerdetail, setseller] = useState();
  const [ValidateError, SetValidateError] = useState("");
  const location = useLocation();
  const [load, Setload] = useState(false);

  const navigate = useNavigate();
  // const { BrandImage ,shopid } = useSelector((state) => state.seller_panel.User);
  const { Asxds } = localStorage;
  const local = DecryptData(Asxds);
  console.log("errrroe", local);
  useEffect(() => {
    window.scrollTo(0, 0);
    setseller(location.state);
  }, []);
  const axiosFile = useAxiosFile();

  const OnChange = (e) => {
    Setload(false);
    SetValidateError({});
    console.log("kjskjfsjkfs", e);
    const { id, value } = e.target;
    setseller({
      ...sellerdetail,
      ...{ [id]: value },
    });
  };

  const Validation = useCallback(
    (data) => {
      console.log("profileloooo", data);
      let ValidateError = {};
      if (isEmpty(data?.Country)) ValidateError.Country = "Country Required";
      if (isEmpty(data?.StreetName))
        ValidateError.StreetName = "StreetName Required";
      if (isEmpty(data?.Zipcode)) ValidateError.Zipcode = "Zipcode Required";
      if (isEmpty(data?.City)) ValidateError.City = "City Required";
      if (isEmpty(data?.State)) ValidateError.State = "State Required";
      SetValidateError(ValidateError);
      return ValidateError;
    },
    [ValidateError]
  );
  console.log("rtdegyergertg", sellerdetail);

  const Addseller = async () => {
    Setload(true);
    const toastid = LoadToast();

    let error = Validation(sellerdetail);
    console.log("rtdegyergertg", error);

    // return
    if (!isEmpty(error)) {
      SetValidateError(error);
      updateToast(toastid, "fix", "error");
      Setload(false);
    } else {
      var SendData = {
        Address: {
          StreetNumber: sellerdetail.StreetNumber,
          Country: sellerdetail.Country,
          StreetName: sellerdetail.StreetName,
          Zipcode: sellerdetail.Zipcode,
          City: sellerdetail.City,
          State: sellerdetail.State,
        },
        ShopId: local.shopid,
        action: "AddressUpdate",
      };
      var resp = await axiosFile.addsellershop(SendData);
      console.log("sellerdetail", resp);
      if (resp?.success == "success") {
        updateToast(toastid, resp?.msg, "success");
        navigate("/storetiming");
      } else {
        Setload(false);
      }
    }
  };

  return (
    <div className="regwhole_bg acnt_regwhole_bg">
      {/* <img
        className="singin_abs reg_abs"
        src={require("../../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs wd_ht_unst" src={delpick} alt="" />
      <Container>
        <Row className="justify-content-center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            <div className="reg_top_dtls">
              <h6 className="reg_top_title">Seller Address</h6>
              {/* <p className="reg_skip_txt">
                <Link to="/">skip</Link>
              </p> */}
            </div>
            <p className="reg_welcome_txt">Provide a Vendor Address.</p>

            <Row className="mt-5">
              <Col xxl={3} lg={3} md={6}>
                <img
                  className="img-fluid sellerdetails_img"
                  src={
                    local?.BrandImage
                      ? `${config?.IMG_URL}/shop/${local.BrandImage}`
                      : require("../../assets/images/dummy.png")
                  }
                />
              </Col>
              <Col xxl={9} lg={9} md={6}>
                <Row>
                  <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        Seller Location Address
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex. 123 , XYZ Street"
                        id="StreetName"
                        name="StreetName"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">
                        {ValidateError?.StreetName}
                      </span>
                    </div>
                  </Col>

                  <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        Country
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex.USA"
                        id="Country"
                        name="Country"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">
                        {ValidateError?.Country}
                      </span>
                    </div>
                  </Col>
                  {/* <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        Street Name
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex. Best Burger"
                        id="StreetName"
                        name="StreetName"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">{ValidateError?.StreetName}</span>

                    </div>
                  </Col> */}
                  <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        Zipcode
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex.10036"
                        id="Zipcode"
                        name="Zipcode"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">
                        {ValidateError?.Zipcode}
                      </span>
                    </div>
                  </Col>

                  <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        City
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex.NY"
                        id="City"
                        name="City"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">{ValidateError?.City}</span>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={6}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        State
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex. New York"
                        id="State"
                        name="State"
                        onChange={(e) => OnChange(e)}
                      />
                      <span className="error-msg">{ValidateError?.State}</span>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={6}></Col>
                  <Col lg={6} md={12} sm={6}>
                    <div className="w-100 text-end">
                      <Link>
                        <button
                          onClick={() => Addseller()}
                          className="blue_btn account_bluebtn"
                          disabled={load}
                        >
                          {load ? "Loading" : "Continue"}
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="stepper">
        {home == true ? (
          // <img src={require("../../assets/images/icons/bluehome.png")} />
          <img src={homeCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/shop.png")} alt="" />
        )}

        <p className={home == true ? "blue_line" : ""}>------</p>
        {location == true ? (
          <img src={require("../../assets/images/icons/bluelocation.png")} />
        ) : (
          <img src={require("../../assets/images/icons/location.png")} />
        )}
        <p className={location == true ? "blue_line" : ""}>------</p>
        <img src={require("../../assets/images/icons/Clock.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/window.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/dummyimage.png")} />
      </div>
    </div>
  );
}

export default SellerDetails;
