import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Tab, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useSelector } from "react-redux";
import useAxiosFile from "../../actions/useaxios";
import config from "../../actions/config";
import noimg from "../../assets/images/dummy.png";
import { EncryptData } from "../../actions/common";

function ShopList() {



  const { toggle, payload ,token} = useSelector(
    (state) => state.seller_panel.User
  );

  const axiosFile = useAxiosFile();

 
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const columns = [
    {
      dataField: "",
      text: "SNO",
      formatter: (cell, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    { dataField: "BrandName", text: "Brand Name", sort: false },
    {
      dataField: "BrandImage",
      text: "Brand Image",
      sort: false,
      formatter: (cell, row) => (
        <img
          className="tab_dummyimg"
          src={cell ? `${config.shopimg}/${cell}` : noimg}
        />
      ),
    },
    { dataField: "BrandDescription", text: "Description", sort: false },
    { dataField: "Category", text: "Category", sort: false ,
    formatter: (cell, row) => (
      <>{cell?.Categoryname}</>
    ),},
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => (
          // <button className="table_grnavtivebtn" onClick={()=>navigate(`/addaccount/${EncryptData(row._id)}`)}>
 <button className="table_grnavtivebtn" onClick={()=>nav(row)}>
            <span>
            Edit
            </span>
          {/* </button> */}
          </button>
      ),
    },
    // {
    //   dataField: "",
    //   text: "Remove",
    //   formatter: (cell, row) => (
    //     <button
    //       class="btn table_viewdrop"
    //       type="button"
    //       data-bs-toggle="dropdown"
    //       aria-expanded="false"
    //     >
    //       <button className="table_sandalactbtn">Remove</button>
    //     </button>
    //   ),
    // },
  ];






  /** lp */

  const [shoplist, SetShopList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Dsadasdasdasdasdsadas",payload?._id,token)
   if( payload?._id ) GetShoplist();
  }, [payload?._id,token]);

  const GetShoplist = async () => {
    let Resp = await axiosFile.getshop({ SellerId: payload?._id ,from:"admin"});
    console.log("dfjkdjfhkdlshfd", Resp);
    SetShopList(Resp?.data);
  };


  const nav =(row) => {
    console.log("DsjdadlkJLJL13132",EncryptData(row._id))
    navigate(`/addaccount/?Id=${EncryptData(row._id)}`)
  }
  /** lp */
  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Shops</p>
                        </div>

                        {/* <div className="rightside">
                        <div className='order_calendar_div'>
                              <p className={opacity == "true" ? "hiding_caltxt" : ""}>Start Date</p>
                              <img className='order_calendar_ic' src={require('../../assets/images/icons/calendar.png')}/>
                              <Datetime onChange={()=>{setOpacity("true")}} className='order_datepick'/>
                            </div>

                            <p className='order_totxt'>To</p>
                            <div className='order_calendar_div'>
                              <p className={opacityEnd == "true" ? "hiding_calendtxt" : ""}>End Date</p>
                              <img className='order_calendar_ic' src={require('../../assets/images/icons/calendar.png')}/>
                              <Datetime onChange={()=>{setEndOpacity("true")}} className='order_datepick'/>
                            </div>
                        </div> */}
                      </div>
                      <button
                        type="button"
                        className="blue_btn add_deliveryprsn"
                        onClick={() => navigate("/addaccount")}
                      >
                        Add Shop
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="sec_border">
                        <div className="row">
                          {/* <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="whole_searchbar white_bg">
                              
                              <input placeholder="Search term" />
                            </div>
                          </div> */}
{console.log('kjshjfkdshkjgds',shoplist)}
                          <div className="orders_tabs">
                          
                              <Row>
                                <Col sm={12}>
                               
                                    {shoplist && (
                                    <div className="ffd_table row">
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            columns={columns}
                                            data={shoplist}
                                            options={{
                                              onExportToCSV: shoplist,
                                            }}
                                            exportCSV
                                            pagination={pagination}
                                          />
                                        </div>
                                   
                                    )}
                                 
                                </Col>
                              </Row>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopList;
