import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import Select from "react-select";
import useAxiosFile from "../../actions/useaxios";
import config from "../../actions/config";
import { DecryptData, EncryptData, isEmpty } from "../../actions/common";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LoadToast, updateToast } from "../../actions/common";
import { useNavigate, useHistory } from "react-router-dom";

import delpick from "../../assets/images/delpick.png";

function CreateAccountPage() {
  const dispatch = useDispatch();
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  const { home } = useSelector((state) => state.seller_panel.steppergo);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: false,
          location: false,
          clock: false,
          window: false,
          dummyimage: false,
        },
      },
    });
  }, []);

  const axiosFile = useAxiosFile();

  const multi = useRef();

  const [Categorys, setsubcat] = useState([]);
  const [SubCategorys, setSubCategory] = useState([]);
  const [error, SetError] = useState({});
  const [load, Setload] = useState(false);

  const [form, setForm] = useState({
    BrandName: "",
    Category: "",
    SubCategory: [],
    BrandImage: "",
  });

  useEffect(() => {
    getcat();
  }, [payload?._id]);
  const OnChange = (e, ids) => {
    SetError({});
    Setload(false);
    console.log("kjskjfsjkfs", e);
    let id = e?.target?.id,
      value = e?.target?.value,
      files = e?.target?.files;
    console.log("kjskjfsjkfs", e?.value, files?.length > 0 && files[0]);

    setForm({
      ...form,
      ...{
        [id ? id : ids]:
          files?.length > 0
            ? files[0]
            : value
            ? value
            : e?.value
            ? e?.value
            : e,
      },
    });
    if (ids == "Category") {
      // multi.current.clear()
      setForm({
        ...form,
        ...{ Category: e?.value, ["SubCategory"]: "" },
      });
      setSubCategory(e.SubCategory);
    }
  };
  console.log("form?.BrandImage", form?.BrandImage);
  const Validate = () => {
    let Error = {};
    if (isEmpty(form?.BrandName)) {
      Error.BrandName = "BrandName Required";
    }
    if (isEmpty(form?.Category)) {
      Error.Category = "Category Required";
    }
    if (isEmpty(form?.SubCategory)) {
      Error.SubCategory = "SubCategory Required";
    }

    if (isEmpty(form?.BrandImage?.type)) {
      Error.BrandImage = "BrandImage Required";
    }
    if (form?.BrandImage && !form?.BrandImage?.type?.includes("image")) {
      Error.BrandImage = "BrandImage Must be Image";
    }
    return Error;
  };

  console.log("subcategoru", SubCategorys);

  const getcat = async () => {
    var resp = await axiosFile.getcatList({ from: "seller" });
    console.log("bdhsbdh", resp?.data);
    setsubcat(resp?.data);
  };
  console.log("form", form);

  const Submit = async () => {
    try {
      Setload(true);
      const toastid = LoadToast();

      let errorvalid = Validate(form);
      if (isEmpty(errorvalid)) {
        let Resp = await axiosFile.addsellershop({
          ...form,
          ...{
            SubCategory: JSON.stringify(form.SubCategory),
            SellerId: payload?._id ?? DecryptData(localStorage?._id_s),
            action: "createAccount",
          },
        });
        console.log("hasvdgh", Resp, Resp?.data?.BrandImage);
        if (Resp?.success == "success") {
          updateToast(toastid, "Data Stored", "success");
          localStorage.setItem(
            "Asxds",
            EncryptData({
              shopid: Resp?.data?._id,
              BrandImage: Resp?.data?.BrandImage,
            })
          );
          dispatch({
            type: "Login",
            Login: {
              User: {
                shopid: Resp?.data?._id,
                BrandImage: Resp?.data?.BrandImage,
              },
            },
          });
          navigate("/sellerdetail", { state: form });
        } else {
          SetError(Resp?.error);
          updateToast(toastid, "fix", "error");
          Setload(false);
        }
      } else {
        Setload(false);
        SetError(errorvalid);
        updateToast(toastid, "fix", "error");
      }
    } catch (e) {
      console.error("Err", e);
    }
  };

  console.log("sdsad", form);
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #232222 ",
      borderRadius: 30,
      marginTop: "10px",
      cursor: "pointer",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "44px",
      borderRadius: 30,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div className="regwhole_bg">
      {/* <img
        className="singin_abs reg_abs"
        src={require("../../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs wd_ht_unst" src={delpick} alt="" />
      <Container>
        <Row className="justify-content-center">
          <Col
            xxl={7}
            xsl={7}
            lg={7}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            {/* <div className='reg_top_dtls'>
              <h6 className='reg_top_title'>Let's <br /> Create An Account</h6>
              <p className='reg_skip_txt'><Link to="/">skip</Link></p>

            </div> */}
            <Row>
              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <Row>
                  <Col xxl={12} xsl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        Brand Name
                      </label>
                      <br />
                      <input
                        className="sigin_inputs"
                        type="text"
                        placeholder="Ex.John"
                        id="BrandName"
                        name="BrandName"
                        onChange={(e) => OnChange(e)}
                      />
                      {error && error.BrandName && (
                        <span className="error-msg">{error.BrandName}</span>
                      )}
                    </div>
                  </Col>
                  {console.log(
                    "(Categorys||[])?.filter(it=>it?.value == form?.Category).length>0?Categorys.filter(it=>it?.id == form?.Category)[0]?.label: form?.Category,",
                    Categorys,
                    form?.Category,
                    (Categorys || [])?.filter(
                      (it) => it?.value == form?.Category
                    ).length > 0
                      ? Categorys.filter((it) => it?.id == form?.Category)[0]
                          ?.label
                      : form?.Category
                  )}

                  <Col xxl={12} xsl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="single_center">
                      <label className="signin_labels">Store Categories</label>
                      <br />
                      <Select
                        options={Categorys}
                        value={{
                          label:
                            Categorys.length > 0 &&
                            Categorys?.filter(
                              (it) => it?.value == form?.Category
                            ).length > 0
                              ? Categorys.filter(
                                  (it) => it?._id == form?.Category
                                )[0]?.Categoryname
                              : form?.Category,
                          value: form?.Category,
                        }}
                        styles={stylesgraybg}
                        onChange={(e) => {
                          OnChange(e, "Category");
                        }}
                        className="border_blue_select"
                        classNamePrefix="react-select"
                        placeholder="Choose your store Categories"
                      />
                      {error && error.Category && (
                        <span className="error-msg">{error.Category}</span>
                      )}
                    </div>
                    <div className="single_center">
                      <label className="signin_labels" for="mobile">
                        SubCategory
                      </label>
                      <br />
                      <Select
                        ref={multi}
                        isMulti
                        closeMenuOnSelect={false}
                        value={(form.SubCategory || [])?.map((it) => {
                          return {
                            label: SubCategorys.filter(
                              (its) => it == its.value
                            ).pop().label,
                            value: it,
                          };
                        })}
                        options={SubCategorys}
                        styles={stylesgraybg}
                        onChange={(e) =>
                          OnChange(
                            e.map((i) => i.value),
                            "SubCategory"
                          )
                        }
                        className="border_blue_select"
                        classNamePrefix="react-select"
                        placeholder="A...."

                        // disabled={Formdata.BuyProductId.length>0}
                      />
                      {/* <input className='sigin_inputs' type="text" placeholder='Ex.John' id="SubCategory" value={form.SubCategory} name="SubCategory" onChange={(e) => OnChange(e)} /> */}
                      {error && error.SubCategory && (
                        <span className="error-msg">{error.SubCategory}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="mvv_uploadfile_dtl crtacnt_uploadfile_dtl">
                  <img
                    className="img-fluid"
                    src={
                      form?.BrandImage
                        ? window.URL.createObjectURL(form?.BrandImage)
                        : require("../../assets/images/icons/cloud.png")
                    }
                  />
                  {form?.BrandImage ? (
                    <></>
                  ) : (
                    <p className="mvv_cloudtext">Upload File</p>
                  )}
                  <input
                    type="file"
                    id="BrandImage"
                    className="mvv_fileupload"
                    onChange={(e) => OnChange(e)}
                  />
                  {error && error.BrandImage && (
                    <span className="error-msg">{error.BrandImage}</span>
                  )}
                </div>
              </Col>
            </Row>
            <div className="w-100 text-center">
              <button
                onClick={() => Submit()}
                disabled={load}
                type="submit"
                className="blue_btn register_btn account_bluebtn"
              >
                {load ? "Loading..." : "Continue"}
              </button>
            </div>
          </Col>
        </Row>

        {/* start of gender modal */}
        {/* end of DOB modal */}
      </Container>

      <div className="stepper">
        {home == true ? (
          <img src={require("../../assets/images/icons/bluehome.png")} />
        ) : (
          <img src={require("../../assets/images/icons/shop.png")} />
        )}

        <p className={home == true ? "blue_line" : ""}>------</p>
        <img src={require("../../assets/images/icons/location.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/Clock.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/window.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/dummyimage.png")} />
      </div>
    </div>
  );
}

export default CreateAccountPage;
