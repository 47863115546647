import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import useAxiosFile from "../actions/useaxios";
import config from "../actions/config";
import { useNavigate } from "react-router-dom";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
  MinuteHourFormat,
} from "../actions/common";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import DateTime from "../Components/DateTime";
import {
  GetAddressBasedOnLatLng,
  GetLatLngBasedOnAddress,
} from "./Map/geocode";
import GMap from "./Map/GMap";

import delIcn from "../assets/svg/delete.svg";

// import ApexCharts from 'apexcharts'

function Account() {
  const { pathname, search, state } = useLocation();
  const { toggle, payload } = useSelector((state) => state.seller_panel.User);
  const axiosFile = useAxiosFile();
  let id = DecryptData(search.split("?Id=")[1]);

  console.log("_icsadasdasdasdasdasdd", id);

  const [activeTab, setActiveTab] = useState(
    pathname?.includes("operation") ? "operationtime" : "profile"
  );
  const [subactiveTab, setSubActiveTab] = useState("");
  const [AcceptingOrders, setAcceptingOrders] = useState(false);
  const [Error, SetError] = useState({});
  console.log("Error", Error);
  const navigate = useNavigate();
  const [HourMinuteDisplay, SetHourMinuteDisplay] = useState({
    Hours: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    Minutes: [1, 2, 3, 4],
  });
  const [DayTimings, setDayTimings] = useState({
    Sunday: {
      day: "Sunday",
      Timing: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Sunday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Monday: {
      day: "Monday",
      Timing: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Monday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Tuesday: {
      day: "Tuesday",
      Timing: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Tuesday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Wednesday: {
      day: "Wednesday",
      Timing: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Wednesday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Thursday: {
      day: "Thursday",
      Timing: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Thursday",
        Status: false,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Friday: {
      day: "Friday",
      Timing: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Friday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
    Saturday: {
      day: "Saturday",
      Timing: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      DeliveryTimeCharge: {
        day: "Saturday",
        Status: true,
        AfterPreparation: [
          { AfterPreparation: "", ChargeAmount: "", Currency: "" },
        ],
      },
    },
  });

  const [show_map, setShow_map] = useState(false);
  const [Address, SetAddress] = useState({});
  const [disabledstatus,SetDisabledstatus] = useState({});

  const click = (e, data, from) => {
    setDayTimings({
      ...DayTimings,
      ...{
        [e?.target?.id]: {
          ...DayTimings[e?.target?.id],
          ...(from == "DeliveryTimeCharge"
            ? data
              ? {
                  [from]: {
                    ...DayTimings[e?.target?.id][from],
                    ...{
                      AfterPreparation: [
                        ...DayTimings[e?.target?.id][
                          from
                        ]?.AfterPreparation.slice(0, e.target.name),
                        ...DayTimings[e?.target?.id][
                          from
                        ]?.AfterPreparation.slice(
                          e.target.name,
                          DayTimings[e?.target?.id][from]?.AfterPreparation
                            .length - 1
                        ),
                        {
                          ...DayTimings[e?.target?.id][from]?.AfterPreparation[
                            e.target.name
                          ],
                          ...{
                            [data]: e.target.value,
                          },
                        },
                      ],
                    },
                  },
                }
              : {
                  [from]: {
                    ...DayTimings[e?.target?.id][from],
                    ...{
                      Status: e.target.checked,
                      AfterPreparation: e.target.checked
                        ? [
                            {
                              AfterPreparation: "",
                              ChargeAmount: "",
                              Currency: "",
                            },
                          ]
                        : [],
                    },
                  },
                }
            : data
            ? {
                [from]: {
                  ...DayTimings[e?.target?.id][from],
                  ...{
                    timing: [
                      ...DayTimings[e?.target?.id][from]?.timing.slice(
                        0,
                        e.target.name
                      ),
                      ...DayTimings[e?.target?.id][from]?.timing.slice(
                        e.target.name,
                        DayTimings[e?.target?.id][from]?.timing.length - 1
                      ),
                      {
                        ...DayTimings[e?.target?.id][from]?.timing[
                          e.target.name
                        ],
                        ...(data == "Start"
                          ? DayTimings[e?.target?.id][from]?.timing[
                              e.target.name
                            ].EndTime
                            ? e.target.value?.split(":")[0] <
                              DayTimings[e?.target?.id][from]?.timing[
                                e.target.name
                              ].EndHour
                              ? {
                                  [`${data}Time`]: e.target.value,
                                  [`${data}Hour`]:
                                    e.target.value?.split(":")[0],
                                  [`${data}Minute`]:
                                    e.target.value?.split(":")[1],
                                }
                              : {}
                            : {
                                [`${data}Time`]: e.target.value,
                                [`${data}Hour`]: e.target.value?.split(":")[0],
                                [`${data}Minute`]:
                                  e.target.value?.split(":")[1],
                              }
                          : data == "End"
                          ? DayTimings[e?.target?.id][from]?.timing[
                              e.target.name
                            ].StartTime
                            ? e.target.value?.split(":")[0] >
                              DayTimings[e?.target?.id][from]?.timing[
                                e.target.name
                              ].StartHour
                              ? {
                                  [`${data}Time`]: e.target.value,
                                  [`${data}Hour`]:
                                    e.target.value?.split(":")[0],
                                  [`${data}Minute`]:
                                    e.target.value?.split(":")[1],
                                }
                              : {}
                            : {
                                [`${data}Time`]: e.target.value,
                                [`${data}Hour`]: e.target.value?.split(":")[0],
                                [`${data}Minute`]:
                                  e.target.value?.split(":")[1],
                              }
                          : {}),
                      },
                    ],
                  },
                },
              }
            : {
                [from]: {
                  ...DayTimings[e?.target?.id][from],
                  ...{
                    Status: e.target.checked,
                    hours: !e.target.checked
                      ? false
                      : DayTimings[e?.target?.id][from]?.hours,
                    timing: e.target.checked
                      ? [
                          ...DayTimings[e?.target?.id][from]?.timing,
                          {
                            StartTime: "",
                            EndTime: "",
                            StartHour: "",
                            StartMinute: "",
                            EndHour: "",
                            EndMinute: "",
                          },
                        ]
                      : [],
                  },
                },
              }),
        },
      },
    });
  };

  const _24hours = (e, from) => {
    let id = e.target.id.split("1")[0];

    setDayTimings({
      ...DayTimings,
      ...{
        [id]: {
          ...DayTimings[id],

          ...{
            [from]: {
              ...DayTimings[id][from],
              ...{
                hours: e?.target?.checked,
                timing: !e?.target?.checked
                  ? [
                      {
                        [`${"End"}Time`]: "",
                        [`${"End"}Hour`]: "",
                        [`${"End"}Minute`]: "",
                        [`${"Start"}Time`]: "",
                        [`${"Start"}Hour`]: "",
                        [`${"Start"}Minute`]: "",
                      },
                    ]
                  : [
                      {
                        [`${"End"}Time`]: "23:59",
                        [`${"End"}Hour`]: "23",
                        [`${"End"}Minute`]: "59",
                        [`${"Start"}Time`]: "00:00",
                        [`${"Start"}Hour`]: "0",
                        [`${"Start"}Minute`]: "00",
                      },
                    ],
              },
            },
          },
        },
      },
    });
  };

  const table = (data, index, from) => {
    setDayTimings({
      ...DayTimings,
      ...{
        [data]: {
          ...DayTimings[data],
          ...{
            [from]: {
              ...DayTimings[data][from],
              ...(from == "DeliveryTimeCharge"
                ? index
                  ? {
                      AfterPreparation: [
                        ...DayTimings[data][from]?.AfterPreparation?.slice(
                          0,
                          index
                        ),
                        ...DayTimings[data][from]?.AfterPreparation?.slice(
                          index,
                          DayTimings[data][from]?.AfterPreparation.length - 1
                        ),
                      ],
                    }
                  : {
                      AfterPreparation: [
                        ...DayTimings[data][from]?.AfterPreparation,
                        {
                          AfterPreparation: "",
                          ChargeAmount: "",
                          Currency: "",
                        },
                      ],
                    }
                : index
                ? {
                    timing: [
                      ...DayTimings[data][from]?.timing?.slice(0, index),
                      ...DayTimings[data][from]?.timing?.slice(
                        index,
                        DayTimings[data][from]?.timing.length - 1
                      ),
                    ],
                  }
                : {
                    timing: [
                      ...DayTimings[data][from]?.timing,
                      {
                        [`${"Start"}Time`]: "",
                        [`${"Start"}Hour`]: "",
                        [`${"Start"}Minute`]: "",
                        [`${"End"}Time`]: "",
                        [`${"End"}Hour`]: "",
                        [`${"End"}Minute`]: "",
                      },
                    ],
                  }),
            },
          },
        },
      },
    });
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const getshop = async () => {
    var resp = await axiosFile.getshop({
      SellerId: payload?._id ?? DecryptData(localStorage?._id_s),
      // from: "admin",
      _id: id,
    });
    if (resp.data?.length > 0) {
      delete resp?.data?.[0]?.["SellerCategory"];
      set_shop(resp?.data[0]);
      console.log("dsgahjkl;m,", resp.data[0]);
      setAcceptingOrders(resp?.data[0]?.AcceptingOrders);
      if (resp?.data[0]?.AcceptingOrders) {
        setSubActiveTab("Timing");
      }
      if (resp?.data?.[0]?.DayTimings) {
        let instobj = {
          Timing: [],
          Pickup: [],
          Delivery: [],
        };
        let respdaytiming = resp?.data?.[0]?.DayTimings;
        let days = Object.keys(respdaytiming);
        Object.values(respdaytiming).map((val, ind) => {
          console.log("lkfjfldgksf", Object.keys(val));
          Object.keys(val).map((type) => {
            if (
              (type == "Delivery" || type == "Timing" || type == "Pickup") &&
              val?.[type]?.Status
            ) {
              if (
                instobj?.[type]?.find(
                  (findcheck) =>
                    findcheck?.EndTime == val?.[type]?.timing?.[0]?.EndTime &&
                    findcheck?.StartTime == val?.[type]?.timing?.[0]?.StartTime
                )
              ) {
                let findindex = instobj[type].findIndex(
                  (findcheck) =>
                    findcheck?.EndTime == val?.[type]?.timing?.[0]?.EndTime &&
                    findcheck?.StartTime ==
                      val?.[type]?.timing?.[0]?.StartTime &&
                    !isEmpty(findcheck?.EndTime) &&
                    !isEmpty(findcheck?.StartTime)
                );
                instobj[type] = [
                  ...instobj[type].slice(0, findindex),
                  ...[
                    {
                      ...instobj[type]?.[findindex],
                      ...{
                        [days[ind]]: true,
                      },
                    },
                  ],
                  ...instobj[type].slice(findindex + 1, instobj[type].length),
                ];
              } else {
                instobj[type].push({
                  StartTime: val?.[type]?.timing?.[0]?.StartTime,
                  StartTimeshow: HourMinutetoTime(
                    val?.[type]?.timing?.[0]?.StartHour,
                    val?.[type]?.timing?.[0]?.StartMinute
                  ),
                  EndTime: val?.[type]?.timing?.[0]?.EndTime,
                  EndTimeshow: HourMinutetoTime(
                    val?.[type]?.timing?.[0]?.EndHour,
                    val?.[type]?.timing?.[0]?.EndMinute
                  ),
                  [days[ind]]: true,
                });
              }
            }
          });
        });
        console.log(
          "kjklfdslfsd",
          resp?.data?.[0]?.DayTimings,
          instobj,
          respdaytiming
        );
        setDayTimings(respdaytiming);
        SetSelectedTime({ ...instobj });
      }
    }
  };

  /**lp */

  let initShop = {
    SellerId: payload?._id ?? DecryptData(localStorage?._id_s),
    Title: "",
    BrandName: "",
    SubCategory: [],
    Category: "",
    EmailId: "",
    Mobile: "",
    ShopProfile: "",
    BrandName: "",
    BrandImage: "",
    BrandDescription: "",
    Discount: "",
    Address: {
      StreetNumber: "",
      StreetName: "",
      City: "",
      State: "",
      Country: "",
      Zipcode: "",
    },
    BankDetails: {
      BankName: "",
      AccountType: "",
      AccountHolderName: "",
      AccountNumber: "",
      RoutingNumber: "",
      Zipcode: "",
    },
    StoreImages: [],
    GSTNumber: "",
    TaxPercentage: "",

    AcceptingOrders: false,
    DeliveryTimeCharge: 0,
    CurrencyName: "",
    CurrencySymbol: "",
  };

  const [Currencies, SetCurrencies] = useState([
    { label: "USD", value: "$" },
    { label: "EURO", value: "€" },
  ]);

  const [_shop, set_shop] = useState(initShop);
  const [Categorys, setsubcat] = useState("");
  const [SubCategorys, setSubCategory] = useState([]);
  const [ChooseTimeArray, SetChooseTimeArray] = useState([1]);
  const [SelectedTime, SetSelectedTime] = useState({
    Timing: [],
    Pickup: [],
    Delivery: [],
  });

  const Onchange = (e, ids) => {
    SetError({});
    let id = ids ? ids : e?.target?.id,
      value = typeof e == "string" ? e : e?.value ? e?.value : e?.target?.value,
      name = e?.target?.name,
      files = e?.target?.files;
    // console.log("Object.values(...files).map(it=>it)",Object.values(files))
    if (name == "Address" || name == "BankDetails") {
      set_shop({
        ..._shop,
        ...{ [name]: { ..._shop[name], ...{ [id]: value } } },
      });
    } else {
      if (
        id == "StoreImages" &&
        files?.length > 0 &&
        Object.values(files).some((it) => !it.type.includes("image"))
      ) {
        toast("Except Image other formats are eliminated automatically", {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
      if (
        id == "BrandImage" &&
        files?.length > 0 &&
        Object.values(files).some((it) => !it.type.includes("image"))
      ) {
        toast("Image Only Allowed", {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
      set_shop({
        ..._shop,
        ...{
          [id]:
            files?.length > 0
              ? id == "StoreImages"
                ? Object.values(files).filter((it) => it.type.includes("image"))
                : Object.values(files).filter((it) =>
                    it.type.includes("image")
                  )[0]
              : value,
        },
      });
    }
    if (ids == "Category") {
      set_shop({
        ..._shop,
        ...{
          ["Category"]: value,
          ["SubCategory"]: "",
        },
      });
      setSubCategory(e.SubCategory);
    }
    if (ids == "Currency") {
      set_shop({
        ..._shop,
        ...{
          ["CurrencyName"]: e.label,
          ["CurrencySymbol"]: value,
        },
      });
    }
  };
  console.log("Dsadaskdhasdas", _shop);
  useEffect(() => {
    console.log("++++++++++++++", pathname, payload?._id);
    if (id && payload?._id) {
      set_shop({
        ..._shop,
        ...{ SellerId: payload?._id },
      });
      getshop();
      getcat();
    }
  }, [payload?._id, id]);

  useEffect(() => {
    getcat();
  }, [_shop?.Category]);

  useEffect(() => {
    if (state?.from) {
      setActiveTab("operationtime");
      setAcceptingOrders(true);
      setSubActiveTab("Timing");
    }
  }, [state]);

  const getcat = async () => {
    var resp = await axiosFile.getcatList({ from: "seller" });
    console.log("bdhsbdh", resp);
    setsubcat(resp?.data);
    setSubCategory(
      resp?.data?.filter((it) => it?.value == _shop?.Category).length > 0
        ? resp?.data?.filter((it) => it?.value == _shop?.Category)?.[0]
            ?.SubCategory
        : []
    );
  };
  // useEffect(())
  const CopyTimings = (fromtype, totype) => {
    let currdaytiming = DayTimings;
    let currselectedtime = SelectedTime;
    let display = "Copied to ";
    if (Array.isArray(totype)) {
      if (
        totype.every(
          (check) =>
            check == "Timing" || check == "Pickup" || check == "Delivery"
        ) &&
        (fromtype == "Timing" || fromtype == "Pickup" || fromtype == "Delivery")
      ) {
        totype.map((type, ind) => {
          Object.keys(currdaytiming).map((days) => {
            currdaytiming[days][type] = currdaytiming[days][fromtype];
          });
          currselectedtime[type] = currselectedtime[fromtype];
          display =
            display +
            (ind == totype.length - 1 ? type + " Successfully." : type + " ,");
        });
      } else {
        console.log("fromtype,totype invalid");
        return false;
      }
    } else {
      if (
        (fromtype == "Timing" ||
          fromtype == "Pickup" ||
          fromtype == "Delivery") &&
        (totype == "Timing" || totype == "Pickup" || totype == "Delivery")
      ) {
        Object.keys(currdaytiming).map((days) => {
          currdaytiming[days][totype] = currdaytiming[days][fromtype];
        });
        currselectedtime[totype] = currselectedtime[fromtype];
        display = display + totype + " Successfully.";
      } else {
        console.log("fromtype,totype invalid");
        return false;
      }
    }
    toast.success(display, 1000);
    setDayTimings({ ...currdaytiming });
    SetSelectedTime({ ...currselectedtime });
  };
  const Validation = () => {
    let errors = {};
    // if (activeTab == "profile") {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (
      (!id && isEmpty(_shop?.BrandImage?.type)) ||
      (id && isEmpty(_shop?.BrandImage))
    ) {
      errors.BrandImage = "Brand Image Required";
    }
    if (isEmpty(_shop.BrandName)) {
      errors.BrandName = "Brand Name Required";
    }
    if (isEmpty(_shop.StoreImages)) {
      errors.StoreImages = "Store Images are Required";
    }
    if (isEmpty(_shop.Mobile)) {
      errors.Mobile = "Mobile Number is  Required";
    }
    if (_shop.Mobile && !isValidPhoneNumber(_shop.Mobile)) {
      errors.Mobile = "Enter Valid Mobile number";
    }
    if (isEmpty(_shop.EmailId)) {
      errors.EmailId = "EmailId is  Required";
    }
    if (!isEmpty(_shop?.EmailId) && !emailRegex.test(_shop.EmailId)) {
      errors.EmailId = "Enter Valid Email";
    }
    if (isEmpty(_shop.Category)) {
      errors.Category = "Category is  Required";
    }
    // if (isEmpty(_shop.SubCategory)) {
    //   errors.SubCategory = "SubCategory is  Required";
    // }

    if (isEmpty(_shop.Address?.StreetName)) {
      errors.StreetName = "StreetName is  Required";
    }
    if (isEmpty(_shop.Address?.City)) {
      errors.City = "City is  Required";
    }
    if (isEmpty(_shop.Address?.State)) {
      errors.State = "State is  Required";
    }
    if (isEmpty(_shop.Address?.Country)) {
      errors.Country = "Country is  Required";
    }
    if (isEmpty(_shop.Address?.Zipcode)) {
      errors.Zipcode = "Zipcode is  Required";
    }

    if (isEmpty(_shop?.BankDetails?.BankName)) {
      errors.BankName = "BankName is  Required";
    }
    if (isEmpty(_shop?.BankDetails?.AccountType)) {
      errors.AccountType = "AccountType is  Required";
    }

    if (isEmpty(_shop?.BankDetails?.AccountHolderName)) {
      errors.AccountHolderName = "AccountHolderName is  Required";
    }
    if (isEmpty(_shop?.BankDetails?.BankAccountNumber)) {
      errors.BankAccountNumber = "BankAccountNumber is  Required";
    }
    if (isEmpty(_shop.BankDetails?.RoutingNumber)) {
      errors.RoutingNumber = "RoutingNumber is  Required";
    }
    if (isEmpty(_shop.CurrencyName)) {
      errors.Currency = "Currency is  Required";
    }
    if (isEmpty(errors)) {
      errors.Profile = false;
    } else {
      errors.Profile = true;
    }
    errors.Timing = false;
    Object.values(DayTimings).map((instval) => {
      if (instval?.Timing?.timing?.length > 0) {
        errors.Timing = true;
      }
    });
    if (errors?.Timing) {
      let curdaytiming = DayTimings;
      let currTiming = SelectedTime?.Timing;
      Object.keys(SelectedTime).map((timetype) => {
        if (SelectedTime[timetype]?.length > 0) {
          SelectedTime[timetype]?.map((val, ind) => {
            if (isEmpty(val?.StartTime)) {
              errors[timetype + "StartTime" + ind] = "Enter Start-Time";
            }
            if (isEmpty(val?.EndTime)) {
              errors[timetype + "EndTime" + ind] = "Enter Start-Time";
            }
            if (timetype != "Timing") {
              // let error = true;
              Object.keys(val).map((keys) => {
                if (
                  keys != "EndTime" &&
                  keys != "EndTimeshow" &&
                  keys != "StartTime" &&
                  keys != "StartTimeshow" &&
                  val[keys]
                ) {
                  curdaytiming?.[keys]?.Timing?.timing?.map((checktime) => {
                    // if( error && (HourMinutetoTime(checktime?.EndHour,checktime?.EndMinute)>=HourMinutetoTime(val?.EndTime.split(':')[0],val?.EndTime.split(':')[1])) && (HourMinutetoTime(val?.StartHour,val?.StartMinute)>=HourMinutetoTime(checktime?.StartHour,curdaytiming?.StartMinute))){
                    //   error = false;
                    // }
                  });
                  // console.log('lkjskjfsfs',val,timetype,SelectedTime[timetype],Object.keys(val),curdaytiming?.[keys]?.[timetype])
                }
                // else if(Object.keys(val)?.every((testkeys)=>(testkeys!='EndTime' || testkeys!='EndTimeshow' || testkeys!='StartTime' || testkeys!='StartTimeshow'))){
                //   error = false;
                // }
              });
              // console.log('kjshjkhsfksfs',timetype,val,error,errors,Object.keys(val)?.every((testkeys)=>(testkeys!='EndTime' || testkeys!='EndTimeshow' || testkeys!='StartTime' || testkeys!='StartTimeshow')),)
              // if(error){
              //     errors[timetype+'EndTime'+ind] = "Choose Time between Shop-Timing";
              //     errors[timetype+'StartTime'+ind] = "Choose Time between Shop-Timing";
              // }
            }
          });
        }
      });
      if (isEmpty(errors) || errors.Timing) {
        // errors[type] = false;
        errors = { Delivery: false, Timing: false, Pickup: false };
        Object.values(curdaytiming).map((it, ind) => {
          console.log("dkjjsfsklflkjklfl", it);
          Object.keys(it).map((type) => {
            console.log("sljfksjflsfs", type);
            if (type == "Delivery" || type == "Timing" || type == "Pickup") {
              if (it?.[type]?.timing?.length > 1) {
                it?.[type]?.timing?.map((checkdata, checkdataind) => {
                  it?.[type]?.timing?.map((checkdata2, checkdata2ind) => {
                    if (checkdata2ind != checkdataind) {
                      if (
                        HourMinutetoTime(
                          checkdata?.StartHour,
                          checkdata?.StartMinute
                        ) <
                          HourMinutetoTime(
                            checkdata2?.StartHour,
                            checkdata2?.StartMinute
                          ) &&
                        HourMinutetoTime(
                          checkdata?.EndHour,
                          checkdata?.EndMinute
                        ) <
                          HourMinutetoTime(
                            checkdata2?.EndHour,
                            checkdata2?.EndMinute
                          ) &&
                        HourMinutetoTime(
                          checkdata2?.StartHour,
                          checkdata2?.StartMinute
                        ) <
                          HourMinutetoTime(
                            checkdata?.EndHour,
                            checkdata?.EndMinute
                          )
                      ) {
                        let newstarttime =
                          HourMinutetoTime(
                            checkdata?.StartHour,
                            checkdata?.StartMinute
                          ) <
                          HourMinutetoTime(
                            checkdata2?.StartHour,
                            checkdata2?.StartMinute
                          )
                            ? checkdata?.StartTime
                            : checkdata2?.StartTime;
                        let newendtime =
                          HourMinutetoTime(
                            checkdata?.EndHour,
                            checkdata?.EndMinute
                          ) <
                          HourMinutetoTime(
                            checkdata2?.EndHour,
                            checkdata2?.EndMinute
                          )
                            ? checkdata2?.EndTime
                            : checkdata2?.EndTime;
                        curdaytiming = {
                          ...curdaytiming,
                          ...{
                            [it.day]: {
                              ...curdaytiming[it.day],
                              ...{
                                [type]: {
                                  ...curdaytiming[it.day][type],
                                  ...{
                                    timing: [
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        0,
                                        checkdataind < checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind
                                      ),
                                      ...[
                                        {
                                          ...curdaytiming[it.day][type]
                                            ?.timing?.[
                                            checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ],
                                          ...{
                                            StartHour:
                                              newstarttime.split(":")[0],
                                            StartMinute:
                                              newstarttime.split(":")[1],
                                            StartTime: newstarttime,
                                            EndHour: newendtime.split(":")[0],
                                            EndMinute: newendtime.split(":")[1],
                                            EndTime: newendtime,
                                          },
                                        },
                                      ],
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        (checkdataind < checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind) + 1,
                                        checkdataind > checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind
                                      ),
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        (checkdataind > checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind) + 1,
                                        curdaytiming[it.day][type]?.timing
                                          ?.length
                                      ),
                                    ],
                                  },
                                },
                              },
                            },
                          },
                        };
                      } else if (
                        HourMinutetoTime(
                          checkdata2?.StartHour,
                          checkdata2?.StartMinute
                        ) <
                          HourMinutetoTime(
                            checkdata?.StartHour,
                            checkdata?.StartMinute
                          ) &&
                        HourMinutetoTime(
                          checkdata?.StartHour,
                          checkdata?.StartMinute
                        ) <
                          HourMinutetoTime(
                            checkdata2?.EndHour,
                            checkdata2?.EndMinute
                          )
                      ) {
                        let newstarttime =
                          HourMinutetoTime(
                            checkdata?.StartHour,
                            checkdata?.StartMinute
                          ) <
                          HourMinutetoTime(
                            checkdata2?.StartHour,
                            checkdata2?.StartMinute
                          )
                            ? checkdata?.StartTime
                            : checkdata2?.StartTime;
                        let newendtime =
                          HourMinutetoTime(
                            checkdata?.EndHour,
                            checkdata?.EndMinute
                          ) <
                          HourMinutetoTime(
                            checkdata2?.EndHour,
                            checkdata2?.EndMinute
                          )
                            ? checkdata2?.EndTime
                            : checkdata2?.EndTime;
                        curdaytiming = {
                          ...curdaytiming,
                          ...{
                            [it.day]: {
                              ...curdaytiming[it.day],
                              ...{
                                [type]: {
                                  ...curdaytiming[it.day][type],
                                  ...{
                                    timing: [
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        0,
                                        checkdataind < checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind
                                      ),
                                      ...[
                                        {
                                          ...curdaytiming[it.day][type]
                                            ?.timing?.[
                                            checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ],
                                          ...{
                                            StartHour:
                                              newstarttime.split(":")[0],
                                            StartMinute:
                                              newstarttime.split(":")[1],
                                            StartTime: newstarttime,
                                            EndHour: newendtime.split(":")[0],
                                            EndMinute: newendtime.split(":")[1],
                                            EndTime: newendtime,
                                          },
                                        },
                                      ],
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        (checkdataind < checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind) + 1,
                                        checkdataind > checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind
                                      ),
                                      ...curdaytiming[it.day][
                                        type
                                      ]?.timing?.slice(
                                        (checkdataind > checkdata2ind
                                          ? checkdataind
                                          : checkdata2ind) + 1,
                                        curdaytiming[it.day][type]?.timing
                                          ?.length
                                      ),
                                    ],
                                  },
                                },
                              },
                            },
                          },
                        };
                      }
                      // else if()
                    }
                  });
                });
              }
              if (curdaytiming[it.day][type]?.timing?.length == 0) {
                curdaytiming = {
                  ...curdaytiming,
                  ...{
                    [it.day]: {
                      ...curdaytiming[it.day],
                      ...{
                        [type]: {
                          ...curdaytiming[it.day][type],
                          ...{
                            Status: false,
                          },
                        },
                      },
                    },
                  },
                };
              }
              if (curdaytiming[it.day][type]?.timing?.length > 0) {
                errors[type] = true;
              }
            }
          });
        });

        console.log("hsskhkfds2222222", curdaytiming);
        return { ...{ newdaytiming: curdaytiming }, ...errors };
      } else {
        return errors;
      }
    }
    return errors;
  };

  console.log("hi>>>>>>>>>>>>", _shop);
  const onsubmit = async () => {

    let error = Validation();
    if(activeTab == "profile" && error?.Timing && !error.Profile){
      return setActiveTab("operationtime")
    }
    let Id = LoadToast("Validating");
    

    if (error?.Timing && !error.Profile) {
      console.log("khjjkhjkhjhjhk", error);

      if (_shop.Address?.City) {
        var address = `${_shop.Address.StreetName},${_shop.Address?.City},${_shop.Address?.Country},${_shop.Address?.State},${_shop.Address?.Zipcode}`;
        var latlng = await GetLatLngBasedOnAddress(address);
      }

      // senddata.sss = 'sdkjsds'
      let senddata = {
        action: "profile_add",
        ..._shop,
        ...{
          latlng,
          Delivery: error?.Delivery ? true : false,
          Pickup: error?.Pickup ? true : false,
        },
        ...{
          DayTimings: error.newdaytiming,
          AcceptingOrders,
          SubCategory: JSON.stringify(Array.isArray(_shop?.SubCategory)?(_shop?.SubCategory?.every((val)=>isEmpty(val))?[]:_shop?.SubCategory):[]),
        },
      };
      SetDisabledstatus({Submit:true})
      let Resp = await axiosFile.addsellershop(senddata);
      console.log("resppppp", Resp, senddata);
      if (Resp.success == "success") {
        updateToast(Id, "Updated Successfully", "success");
        console.log("hasvdgh");
        navigate("/shoplist");
      } else if (Resp.success == "error" && isEmpty(Resp.error)) {
        updateToast(Id, "Updated Successfully", "success");
        navigate("/shoplist");
        // SetError(Resp?.error)
      } else {
        updateToast(Id, Resp?.msg ?? "Try Again", "error");
        SetError(Resp?.error);
      }
      SetDisabledstatus({Submit:false})
      // }
    } 
    else {
      console.log("error?.Pickup", error?.Pickup?.length, error);
      if (!error?.Timing) {
        if (activeTab == "profile") {
          if (!error.Profile) {
            setActiveTab("operationtime");
            setAcceptingOrders(true);
            setSubActiveTab("Timing");
            return updateToast(Id, "Choose Time", "info");
          }
        } else {
          updateToast(Id, "Choose Time", "info");
        }
      }
      if (error?.Delivery?.length > 0) {
        updateToast(Id, "Select Delivery Time", "error");
      } else if (error?.Pickup?.length > 0) {
        // console.log("error?.Pickup2",error?.Pickup.length)

        updateToast(Id, "Select Pickup Time", "error");
      } else if (error?.DeliveryTimeCharge?.length > 0) {
        updateToast(Id, "Select DeliveryTimeCharge Time", "error");
      } else {
        // console.log("error?.Pickup 3",error,error?.Pickup.length)

        updateToast(Id, "fix", "error");
      }

      SetError(error);
    }
  };

  console.log("_shop?.Address", _shop?.Address);
  /**lp */

  const TimeChoose = (day, hour, minute, type) => {
    // console.log('kjdjfhkjfsjkfs1111',)
    // let newselectedtime = {};
    // let newdaytiming = {};
    // let editdaytimeindex = -1;
    // let editselecttimeindex = -1;
    // let newtime = ''
    // if(DayTimings?.[day]?.[type]?.timing?.length>0){
    //   if(DayTimings?.[day]?.[type]?.timing?.length>1){
    //     DayTimings?.[day]?.[type]?.timing?.map((currtiming)=>{
    //       // if()
    //       console.log('kjskdhdsjkhfsjd',day,hour,minute,type,HourMinutetoTime(hour,minute),HourMinutetoTime(currtiming?.StartHour,currtiming?.StartMinute),HourMinutetoTime(currtiming?.EndHour,currtiming?.EndMinute))
    //     })
    //   }
    //   else{
    //     if( DayTimings?.[day]?.[type]?.StartHour || DayTimings?.[day]?.[type]?.EndHour ){
    //       console.log('kjdjfhkjfsjkfsiffiff1')
    //       if(isEmpty(DayTimings?.[day]?.[type]?.EndHour)){
    //         checkhour = Number(DayTimings?.[day]?.[type]?.StartHour)+((24 - Number(DayTimings?.[day]?.[type]?.StartHour))/2);
    //       }
    //       else if(isEmpty(DayTimings?.[day]?.[type]?.StartHour)){
    //         checkhour = Number(DayTimings?.[day]?.[type]?.EndHour)/2;
    //       }
    //       else if( DayTimings?.[day]?.[type]?.StartHour && DayTimings?.[day]?.[type]?.EndHour ){
    //         checkhour = Number(DayTimings?.[day]?.[type]?.StartHour)+((Number(DayTimings?.[day]?.[type]?.EndHour) - Number(DayTimings?.[day]?.[type]?.StartHour))/2);
    //       }
    //     }
    //   }
    //   let starthour = ( type=='Timing' ? '00' : ( DayTimings?.[day]?.Timing?.timing?.[0]?.StartHour ? DayTimings?.[day]?.Timing?.timing?.[0]?.StartHour : '00' ) );
    //   let startminute = ( type=='Timing' ? '00' : ( DayTimings?.[day]?.Timing?.timing?.[0]?.StartMinute ? DayTimings?.[day]?.Timing?.timing?.[0]?.StartMinute : '00' ) )
    //   let endhour = ( type=='Timing' ? '24' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour : '24' ) );
    //   let endminute = ( type=='Timing' ? '00' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute : '00' ) )
    //   console.log('kjdjfhkjfsjkfsifff11',)
    //   let checkhour = 12;
    //   console.log('kjdjfhkjfsjkfs',day,hour,minute,type,DayTimings,checkhour)
    //   if(hour>checkhour){
    //     console.log('kjdjfhkjfsjkfsifif2');
    //     newtime = HourMinutetoTime(hour,minute) < HourMinutetoTime(DayTimings?.[day]?.[type]?.EndHour,DayTimings?.[day]?.[type]?.EndMinute) ? DayTimings?.[day]?.[type]?.EndTime : MinuteHourFormat(hour)+':'+MinuteHourFormat(minute)
    //     if(SelectedTime?.[type]?.find((val)=>( val.EndTime == MinuteHourFormat(hour)+":"+MinuteHourFormat(minute) ))){
    //       editselecttimeindex = SelectedTime?.[type]?.findIndex((val)=>( val.EndTime == MinuteHourFormat(hour)+":"+MinuteHourFormat(minute) ))
    //       editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((findval)=>findval.EndTime==MinuteHourFormat(hour)+":"+MinuteHourFormat(minute))
    //       newselectedtime = {
    //         ...SelectedTime,
    //         ...{
    //           [type]:[
    //             ...SelectedTime?.[type]?.slice(0,editselecttimeindex),
    //             ...[{
    //               ...SelectedTime?.[type]?.[editselecttimeindex],
    //               ...{
    //                 [day]: true,
    //               }
    //             }],
    //             ...SelectedTime?.[type]?.slice(editselecttimeindex+1,SelectedTime?.[type]?.length)
    //           ]
    //         }
    //       }
    //     }
    //     else {
    //       let foundindex = undefined;
    //       SelectedTime?.[type]?.map((val, ind) => {
    //         if (val?.[day] == true) {
    //           if (Object.keys(val).every((inval) => (inval == 'EndTime' || inval == 'EndTimeshow' || inval == 'StartTime' || inval == 'StartTimeshow' || inval == day))) {
    //             foundindex = ind
    //           }
    //         }
    //       })
    //       if(foundindex != undefined){
    //         editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==SelectedTime?.[type]?.[foundindex]?.EndTime && findval.StartTime==SelectedTime?.[type]?.[foundindex]?.StartTime))
    //         newselectedtime = {
    //           ...SelectedTime,
    //           ...{
    //             [type]:[
    //               ...SelectedTime?.[type]?.slice(0,foundindex),
    //               ...[{
    //                 ...SelectedTime?.[type]?.[foundindex],
    //                 ...{
    //                   // StartTime: DayTimings?.[day]?.[type].timing[0]?.StartTime,
    //                   // StartTimeshow: new Date(new Date().setHours(DayTimings?.[day]?.[type].timing[0]?.StartHour,DayTimings?.[day]?.[type].timing[0]?.StartMinute)),
    //                   EndTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute) ,
    //                   EndTimeshow:new Date(new Date().setHours(hour,minute)),
    //                 }
    //               }],
    //               ...SelectedTime?.[type]?.slice(foundindex+1,SelectedTime?.[type]?.length)
    //             ]
    //           }
    //         };
    //         editselecttimeindex = foundindex
    //       }
    //       else{
    //         newselectedtime = {
    //           ...SelectedTime,
    //           ...{
    //             [type]:[
    //               ...SelectedTime?.[type],
    //               ...[{
    //                 StartTime: starthour+':'+startminute,
    //                 StartTimeshow: HourMinutetoTime(starthour,startminute),
    //                 EndTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute) ,
    //                 EndTimeshow:HourMinutetoTime(hour,minute),
    //               }],
    //             ]
    //           }
    //         };
    //         editselecttimeindex = newselectedtime?.[type]?.length - 1;
    //         editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==newselectedtime?.[type]?.[editselecttimeindex]?.EndTime && findval.StartTime==newselectedtime?.[type]?.[editselecttimeindex]?.StartTime))
    //       }
    //     }
    //     if(editdaytimeindex!=-1){
    //       newdaytiming = {
    //         ...DayTimings,
    //         ...{
    //           [day]:{
    //             ...DayTimings?.[day],
    //             ...{
    //               [type]:{
    //                 ...DayTimings?.[day]?.[type],
    //                 ...{
    //                   EndHour: newtime.split(':')[0],
    //                   EndMinute: newtime.split(':')[1],
    //                   EndTime: newtime,
    //                   timing:[
    //                       ...DayTimings?.[day]?.[type]?.timing?.slice(0,editdaytimeindex),
    //                       ...[{
    //                           ...DayTimings?.[day]?.[type]?.timing?.[editdaytimeindex],
    //                           ...{
    //                             EndHour: MinuteHourFormat(hour),
    //                             EndMinute: MinuteHourFormat(minute),
    //                             EndTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute) ,
    //                           }
    //                       }],
    //                       ...DayTimings?.[day]?.[type]?.timing?.slice(editdaytimeindex+1,DayTimings?.[day]?.[type]?.timing?.length)
    //                   ]
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       };
    //     }
    //     else{
    //       newdaytiming = {
    //         ...DayTimings,
    //         ...{
    //           [day]:{
    //             ...DayTimings?.[day],
    //             ...{
    //               [type]:{
    //                 ...DayTimings?.[day]?.[type],
    //                 ...{
    //                   EndHour: newtime.split(':')[0],
    //                   EndMinute: newtime.split(':')[1],
    //                   EndTime: newtime,
    //                   timing:[
    //                       ...DayTimings?.[day]?.[type]?.timing,
    //                       ...[{
    //                           StartHour:  MinuteHourFormat(SelectedTime?.[type]?.[editselecttimeindex]?.StartTime?.split(':')[0]),
    //                           StartMinute: MinuteHourFormat(SelectedTime?.[type]?.[editselecttimeindex]?.StartTime?.split(':')[1]),
    //                           StartTime: SelectedTime?.[type]?.[editselecttimeindex]?.StartTime,
    //                           EndHour: MinuteHourFormat(hour),
    //                           EndMinute: MinuteHourFormat(minute),
    //                           EndTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute) ,
    //                       }],
    //                   ]
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       };
    //       editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.length - 1;
    //     }
    //   }
    //   else{
    //     newtime = HourMinutetoTime(hour,minute) < HourMinutetoTime(DayTimings?.[day]?.[type]?.StartHour,DayTimings?.[day]?.[type]?.StartMinute) ? DayTimings?.[day]?.[type]?.StartTime : MinuteHourFormat(hour)+':'+MinuteHourFormat(minute)
    //     console.log('dsjfskljfskljfselsee',DayTimings?.[day]?.[type].timing[0],SelectedTime?.[type]?.find((val)=>(val?.StartTime==DayTimings?.[day]?.[type].timing[0]?.StartTime && val.EndTime == DayTimings?.[day]?.[type].timing[0]?.EndTime)))
    //     if(SelectedTime?.[type]?.find((val)=>(val?.StartTime == MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute)))){
    //       // Sele
    //       editselecttimeindex = SelectedTime?.[type]?.findIndex((val)=>(val?.StartTime==MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute)))
    //       editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((checktiming)=> MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute) == checktiming?.EndTime)
    //       newselectedtime = {
    //         ...SelectedTime,
    //         ...{
    //           [type]:[
    //             ...SelectedTime?.[type]?.slice(0,editselecttimeindex),
    //             ...[{
    //               ...SelectedTime?.[type][editselecttimeindex],
    //               ...{
    //                 [day]: true
    //               }
    //             }],
    //             ...SelectedTime?.[type]?.slice(editselecttimeindex+1,SelectedTime?.[type]?.length)
    //           ]
    //         }
    //       }
    //     }
    //     else{
    //       let foundindex = undefined;
    //       SelectedTime?.[type]?.map((val,ind)=>{
    //         console.log('dmfkdsjfkdsjfk111111',val)
    //         if(val?.[day]==true){
    //           if(Object.keys(val).every((inval)=>(inval=='EndTime'||inval=='EndTimeshow'||inval=='StartTime'||inval=='StartTimeshow'||inval==[day]))){
    //             foundindex = ind
    //           }
    //         }
    //       })
    //       console.log('dmfkdsjfkdsjfk111111outtt',foundindex,SelectedTime,hour,checkhour)
    //       if(foundindex!=undefined){
    //         editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==SelectedTime?.[type]?.[foundindex]?.EndTime && findval.StartTime==SelectedTime?.[type]?.[foundindex]?.StartTime))
    //         newselectedtime = {
    //           ...SelectedTime,
    //           ...{
    //             [type]:[
    //               ...SelectedTime?.[type]?.slice(0,foundindex),
    //               ...[{
    //                 ...SelectedTime?.[type]?.[foundindex],
    //                 ...{
    //                   StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //                   StartTimeshow: new Date(new Date().setHours(hour,minute)),
    //                 }
    //               }],
    //               ...SelectedTime?.[type]?.slice(foundindex+1,SelectedTime?.[type]?.length)
    //             ]
    //           }
    //         };
    //         editselecttimeindex = foundindex
    //         // let editdaytimeindex = DayTimings?.[day]?.[type]?.findIndex((checktiming)=> (SelectedTime?.[type]?.[foundindex]?.EndTime == checktiming?.EndTime && SelectedTime?.[type]?.[foundindex]?.StartTime == checktiming?.StartTime))
    //         // if(editdaytimeindex!=-1){
    //         //   newselectedtime = {
    //         //     ...SelectedTime,
    //         //     ...{
    //         //       [type]:[
    //         //         ...SelectedTime?.[type]?.slice(0,foundindex),
    //         //         ...[{
    //         //           ...SelectedTime?.[type][foundindex],
    //         //           ...{
    //         //             StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //             StartTimeshow: new Date(new Date().setHours(hour,minute)),
    //         //             EndTime: DayTimings?.[day]?.[type].timing[editdaytimeindex]?.EndTime,
    //         //             EndTimeshow:new Date(new Date().setHours(DayTimings?.[day]?.[type].timing[editdaytimeindex]?.EndHour,DayTimings?.[day]?.[type].timing[editdaytimeindex]?.EndMinute)),
    //         //           }
    //         //         }],
    //         //         ...SelectedTime?.[type]?.slice(foundindex+1,SelectedTime?.[type]?.length)
    //         //       ]
    //         //     }
    //         //   }
    //         //   newdaytiming = {
    //         //     ...DayTimings,
    //         //     ...{
    //         //       [day]:{
    //         //         ...DayTimings?.[day],
    //         //         ...{
    //         //           [type]:{
    //         //             ...DayTimings?.[day]?.[type],
    //         //             ...{
    //         //               StartHour: newtime.split(':')[0],
    //         //               StartMinute: newtime.split(':')[1],
    //         //               StartTime: newtime,
    //         //               timing:[
    //         //                 ...DayTimings?.[day]?.[type]?.timing?.slice(0,editdaytimeindex),
    //         //                 ...[{
    //         //                   ...DayTimings?.[day]?.[type].timing[editdaytimeindex],
    //         //                   ...{
    //         //                     StartHour: MinuteHourFormat(hour),
    //         //                     StartMinute: MinuteHourFormat(minute),
    //         //                     StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //                   }
    //         //                 }],
    //         //                 ...DayTimings?.[day]?.[type]?.timing?.slice(editdaytimeindex+1,DayTimings?.[day]?.[type]?.timing?.length)
    //         //               ]
    //         //             }
    //         //           }
    //         //         }
    //         //       }
    //         //     }
    //         //   }
    //         // }
    //         // else{
    //         //   let endhour = ( type=='Timing' ? '24' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour : '24' ) );
    //         //   let endminute = ( type=='Timing' ? '00' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute : '00' ) )
    //         //   newselectedtime = {
    //         //     ...SelectedTime,
    //         //     ...{
    //         //       [type]:[
    //         //         ...SelectedTime?.[type],
    //         //         ...[{
    //         //           StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //           StartTimeshow: new Date(new Date().setHours(hour,minute)),
    //         //           EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //         //           EndTimeshow:new Date(new Date().setHours(endhour,endminute)),
    //         //         }],
    //         //       ]
    //         //     }
    //         //   };
    //         //   newdaytiming = {
    //         //     ...DayTimings,
    //         //     ...{
    //         //       [day]:{
    //         //         ...DayTimings?.[day],
    //         //         ...{
    //         //           [type]:{
    //         //             ...DayTimings?.[day]?.[type],
    //         //             ...{
    //         //               StartHour: newtime.split(':')[0],
    //         //               StartMinute: newtime.split(':')[1],
    //         //               StartTime: newtime,
    //         //               timing:[
    //         //                 ...DayTimings?.[day]?.[type]?.timing?.slice(0,editdaytimeindex),
    //         //                 ...[{
    //         //                     StartHour: MinuteHourFormat(hour),
    //         //                     StartMinute: MinuteHourFormat(minute),
    //         //                     StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //                     EndHour: MinuteHourFormat(endhour),
    //         //                     EndMinute: MinuteHourFormat(endminute),
    //         //                     EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //         //                   }],
    //         //                 ...DayTimings?.[day]?.[type]?.timing?.slice(editdaytimeindex+1,DayTimings?.[day]?.[type]?.timing?.length)
    //         //               ]
    //         //             }
    //         //           }
    //         //         }
    //         //       }
    //         //     }
    //         //   }
    //         //   SetChooseTimeArray([...ChooseTimeArray,...[ChooseTimeArray.length+1]])
    //         // }
    //       }
    //       else{
    //         newselectedtime = {
    //           ...SelectedTime,
    //           ...{
    //             [type]:[
    //               ...SelectedTime?.[type],
    //               ...[{
    //                 StartTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute),
    //                 StartTimeshow: HourMinutetoTime(hour,minute),
    //                 EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute) ,
    //                 EndTimeshow:HourMinutetoTime(endhour,endminute),
    //               }],
    //             ]
    //           }
    //         };
    //         editselecttimeindex = newselectedtime?.[type]?.length - 1;
    //         // newselectedtime = {
    //         //   ...SelectedTime,
    //         //   ...{
    //         //     [type]:[
    //         //       ...SelectedTime?.[type],
    //         //       ...[{
    //         //         StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //         StartTimeshow: HourMinutetoTime(hour,minute),
    //         //         EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //         //         EndTimeshow: HourMinutetoTime(endhour,endminute),
    //         //       }],
    //         //     ]
    //         //   }
    //         // }
    //         // newdaytiming = {
    //         //   ...DayTimings,
    //         //   ...{
    //         //     [day]:{
    //         //       ...DayTimings?.[day],
    //         //       ...{
    //         //         [type]:{
    //         //           ...DayTimings?.[day]?.[type],
    //         //           ...{
    //         //             StartHour: newtime.split(':')[0],
    //         //             StartMinute: newtime.split(':')[1],
    //         //             StartTime: newtime,
    //         //             timing:[
    //         //               ...DayTimings?.[day]?.[type]?.timing,
    //         //               ...[{
    //         //                   StartHour: MinuteHourFormat(hour),
    //         //                   StartMinute: MinuteHourFormat(minute),
    //         //                   StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //         //                   EndHour: MinuteHourFormat(endhour),
    //         //                   EndMinute: MinuteHourFormat(endminute),
    //         //                   EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //         //                 }],
    //         //             ]
    //         //           }
    //         //         }
    //         //       }
    //         //     }
    //         //   }
    //         // }
    //         editdaytimeindex = DayTimings?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==newselectedtime?.[type]?.[editselecttimeindex]?.EndTime && findval.StartTime==newselectedtime?.[type]?.[editselecttimeindex]?.StartTime))
    //         SetChooseTimeArray([...ChooseTimeArray,...[ChooseTimeArray.length+1]])
    //       }
    //     }
    //     if(editdaytimeindex!=-1){
    //       newdaytiming = {
    //         ...DayTimings,
    //         ...{
    //           [day]:{
    //             ...DayTimings?.[day],
    //             ...{
    //               [type]:{
    //                 ...DayTimings?.[day]?.[type],
    //                 ...{
    //                   EndHour: newtime.split(':')[0],
    //                   EndMinute: newtime.split(':')[1],
    //                   EndTime: newtime,
    //                   timing:[
    //                       ...DayTimings?.[day]?.[type]?.timing?.slice(0,editdaytimeindex),
    //                       ...[{
    //                           ...DayTimings?.[day]?.[type]?.timing?.[editdaytimeindex],
    //                           ...{
    //                             StartHour: MinuteHourFormat(hour),
    //                             StartMinute: MinuteHourFormat(minute),
    //                             StartTime: MinuteHourFormat(hour)+':'+MinuteHourFormat(minute) ,
    //                           }
    //                       }],
    //                       ...DayTimings?.[day]?.[type]?.timing?.slice(editdaytimeindex+1,DayTimings?.[day]?.[type]?.timing?.length)
    //                   ]
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       };
    //     }
    //     else{
    //       newdaytiming = {
    //         ...DayTimings,
    //         ...{
    //           [day]:{
    //             ...DayTimings?.[day],
    //             ...{
    //               [type]:{
    //                 ...DayTimings?.[day]?.[type],
    //                 ...{
    //                   EndHour: newtime.split(':')[0],
    //                   EndMinute: newtime.split(':')[1],
    //                   EndTime: newtime,
    //                   timing:[
    //                     ...DayTimings?.[day]?.[type]?.timing,
    //                     ...[{
    //                       StartHour: MinuteHourFormat(hour),
    //                       StartMinute: MinuteHourFormat(minute),
    //                       StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //                       EndHour: MinuteHourFormat(SelectedTime?.[type]?.[editselecttimeindex]?.EndTime?.split(':')[0]),
    //                       EndMinute: MinuteHourFormat(SelectedTime?.[type]?.[editselecttimeindex]?.EndTime?.split(':')[1]),
    //                       EndTime: SelectedTime?.[type]?.[editselecttimeindex]?.EndTime,
    //                     }],
    //                   ]
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       };
    //       editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.length - 1;
    //     }
    //   }
    // }
    // else if(DayTimings?.[day]?.[type]?.timing?.length==0){
    //   console.log('kjdjfhkjfsjkfselseiff11')
    //   newtime = HourMinutetoTime(hour,minute) < HourMinutetoTime(DayTimings?.[day]?.[type]?.StartHour,DayTimings?.[day]?.[type]?.StartMinute) ? DayTimings?.[day]?.[type]?.StartTime : MinuteHourFormat(hour)+':'+MinuteHourFormat(minute)
    //   let endhour = ( type=='Timing' ? '24' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndHour : '24' ) );
    //   let endminute = ( type=='Timing' ? '00' : ( DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute ? DayTimings?.[day]?.Timing?.timing?.[DayTimings?.[day]?.Timing?.timing?.length-1]?.EndMinute : '00' ) )
    //   newdaytiming = {
    //     ...DayTimings,
    //     ...{
    //       [day]:{
    //         ...DayTimings?.[day],
    //         ...{
    //           [type]:{
    //             ...DayTimings?.[day]?.[type],
    //             ...{
    //               StartHour: MinuteHourFormat(hour),
    //               StartMinute: MinuteHourFormat(minute),
    //               StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //               timing:[
    //                 ...DayTimings?.[day]?.[type]?.timing,
    //                 ...[{
    //                     StartHour: MinuteHourFormat(hour),
    //                     StartMinute: MinuteHourFormat(minute),
    //                     StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //                     EndHour: MinuteHourFormat(endhour),
    //                     EndMinute: MinuteHourFormat(endminute),
    //                     EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //                   }],
    //               ]
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   console.log('dsjfskljfskljfselseiff',newdaytiming?.[day]?.[type].timing[0],SelectedTime?.[type]?.find((val)=>(val?.StartTime==newdaytiming?.[day]?.[type].timing[0]?.StartTime && val.EndTime == newdaytiming?.[day]?.[type].timing[0]?.EndTime)))
    //   if (SelectedTime?.[type]?.find((val) => (val?.StartTime == MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute) && val.EndTime == (type == 'Timing' ? '24:00' : (newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime ? newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime : '24:00'))))) {
    //     editselecttimeindex = SelectedTime?.[type]?.findIndex((val) => (val?.StartTime == MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute) && val.EndTime == (type == 'Timing' ? '24:00' : (newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime ? newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime : '24:00'))))
    //     editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.findIndex((findval) => (findval?.StartTime == MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute) && findval.EndTime == (type == 'Timing' ? '24:00' : (newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime ? newdaytiming?.[day]?.Timing?.timing?.[newdaytiming?.[day]?.Timing?.timing?.length - 1]?.EndTime : '24:00'))))
    //     newselectedtime = {
    //       ...SelectedTime,
    //       ...{
    //         [type]: [
    //           ...SelectedTime?.[type]?.slice(0, editselecttimeindex),
    //           ...[{
    //             ...SelectedTime?.[type]?.[editselecttimeindex],
    //             ...{
    //               [day]: true
    //             }
    //           }],
    //           ...SelectedTime?.[type].slice(editselecttimeindex + 1, SelectedTime?.[type]?.length),
    //         ]
    //       }
    //     }
    //   }
    //   else{
    //     let foundindex = undefined;
    //       SelectedTime?.[type]?.map((val,ind)=>{
    //         console.log('dmfkdsjfkdsjfk111111',val)
    //         if(val?.[day]==true){
    //           if(Object.keys(val).every((inval)=>(inval=='EndTime'||inval=='EndTimeshow'||inval=='StartTime'||inval=='StartTimeshow'||inval==[day]))){
    //             foundindex = ind
    //           }
    //         }
    //       })
    //       if(foundindex!=undefined){
    //         editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==SelectedTime?.[type]?.[foundindex]?.EndTime && findval.StartTime==SelectedTime?.[type]?.[foundindex]?.StartTime))
    //         newselectedtime = {
    //             ...SelectedTime,
    //             ...{
    //               [type]:[
    //                 ...SelectedTime?.[type]?.slice(0,foundindex),
    //                 ...[{
    //                   ...SelectedTime?.[type]?.[foundindex],
    //                   ...{
    //                     StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //                     StartTimeshow: HourMinutetoTime(hour,minute),
    //                     EndTime: MinuteHourFormat(endhour)+':'+MinuteHourFormat(endminute),
    //                     EndTimeshow: HourMinutetoTime(endhour,endminute),
    //                   }
    //                 }],
    //                 ...SelectedTime?.[type].slice(foundindex+1,SelectedTime?.[type]?.length),
    //               ]
    //             }
    //           }
    //           editselecttimeindex = foundindex
    //       }
    //       else{
    //         newselectedtime = {
    //             ...SelectedTime,
    //             ...{
    //               [type]:[
    //                 ...SelectedTime?.[type],
    //                 ...[
    //                   {
    //                     StartTime: MinuteHourFormat(hour) + ':' + MinuteHourFormat(minute),
    //                     StartTimeshow: HourMinutetoTime(hour,minute),
    //                     EndTime: MinuteHourFormat(endhour)+":"+MinuteHourFormat(endminute),
    //                     EndTimeshow: HourMinutetoTime(endhour,endminute),
    //                   }
    //                 ],
    //               ]
    //             }
    //           }
    //           editselecttimeindex = newselectedtime?.[type]?.length - 1;
    //           editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==newselectedtime?.[type]?.[editselecttimeindex]?.EndTime && findval.StartTime==newselectedtime?.[type]?.[editselecttimeindex]?.StartTime))
    //         SetChooseTimeArray([...ChooseTimeArray,...[ChooseTimeArray.length+1]])
    //       }
    //   }
    // }
    // let editdaytime = {
    //   StartTime: newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.StartTime,
    //   EndTime: newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.EndTime,
    // }
    // let editselectedtime = {
    //   StartTime: newselectedtime?.[type]?.[editselecttimeindex]?.StartTime,
    //   EndTime: newselectedtime?.[type]?.[editselecttimeindex]?.EndTime,
    // }
    // newdaytiming?.[day]?.[type]?.timing.map((checkdaytime,ind) => {
    //   if((HourMinutetoTime(checkdaytime?.EndHour,checkdaytime?.EndMinute) <= HourMinutetoTime(newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.EndHour,newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.EndMinute)) && (HourMinutetoTime(checkdaytime?.StartHour,checkdaytime?.StartMinute) >= HourMinutetoTime(newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.StartHour,newdaytiming?.[day]?.[type]?.[editdaytimeindex]?.StartMinute)) && ind != editdaytimeindex){
    //     newdaytiming = {
    //       ...DayTimings,
    //       ...{
    //         [day]:{
    //           ...DayTimings?.[day],
    //           ...{
    //             [type]:{
    //               ...DayTimings?.[day]?.[type],
    //               ...{
    //                 EndHour: newtime.split(':')[0],
    //                 EndMinute: newtime.split(':')[1],
    //                 EndTime: newtime,
    //                 timing:[
    //                     ...DayTimings?.[day]?.[type]?.timing.slice(0,ind),
    //                     ...DayTimings?.[day]?.[type]?.timing.slice(ind+1,DayTimings?.[day]?.[type]?.timing?.length),
    //                 ]
    //               }
    //             }
    //           }
    //         }
    //       }
    //     };
    //     editdaytimeindex = newdaytiming?.[day]?.[type]?.timing?.findIndex((findval)=>(findval.EndTime==editdaytime.EndTime && findval?.StartTime == editdaytime.EndTime));
    //     newselectedtime?.[type]?.map((checkselectime,selecttimeind)=>{
    //       if( checkselectime[day] && checkselectime?.StartTimeshow >= newselectedtime?.[type]?.[editselecttimeindex]?.StartTimeshow && checkselectime?.EndTimeshow <= newselectedtime?.[type]?.[editselecttimeindex]?.EndTimeshow && selecttimeind == editselecttimeindex ){
    //         if(Object.keys(newselectedtime?.[type]?.[selecttimeind])?.every((inval)=>(inval=='EndTime'||inval=='EndTimeshow'||inval=='StartTime'||inval=='StartTimeshow'||inval==day))){
    //           newselectedtime = {
    //             ...newselectedtime,
    //             ...{
    //               [type]:[
    //                 ...newselectedtime?.[type]?.slice(0,selecttimeind),
    //                 ...newselectedtime?.[type]?.slice(selecttimeind+1,newselectedtime?.[type]?.length)
    //               ]
    //             }
    //           }
    //         }
    //         else{
    //           newselectedtime = {
    //             ...newselectedtime,
    //             ...{
    //               [type]: [
    //                 ...newselectedtime?.[type]?.slice(0, selecttimeind),
    //                 ...[{
    //                   ...newselectedtime?.[type][selecttimeind],
    //                   ...{
    //                     [day]: false,
    //                   }
    //                 }],
    //                 ...newselectedtime?.[type]?.slice(selecttimeind + 1, newselectedtime?.[type]?.length)
    //               ]
    //             }
    //           }
    //         }
    //         editselecttimeindex = newselectedtime?.[type]?.findIndex((findval)=>(findval.EndTime==editselectedtime.EndTime && findval?.StartTime == editselectedtime.StartTime));
    //       }
    //     })
    //   }
    // });
    // SetSelectedTime(newselectedtime)
    // setDayTimings(newdaytiming)
  };
  // Don't Remove

  const HourMinutetoTime = (hour, minute, from) => {
    if (isEmpty(hour) && isEmpty(minute) && hour !== 0 && minute !== 0) {
      if (from == "end") {
        return new Date(new Date().setHours(0, 0));
      }
      else if(from == 'start'){
        return new Date(new Date().setHours(0, 0, 0));  
      }
      return new Date(new Date().setHours(23, 59, 59));
    }
    if (0 == Number(hour) && 0 == Number(minute)) {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }
    return new Date(new Date().setHours((hour>=24)?23:hour, (hour>=24)?59:minute, (minute == 59 || hour>=24) ? 59 : 0));
  };

  const OnChangeTime = (e, index, day, type, timingtype) => {
    try {
      let setvalue = {};
      if (type == "checkbox") {
        setvalue = {
          ...SelectedTime,
          ...{
            [timingtype]: [
              ...SelectedTime?.[timingtype]?.slice(0, index),
              ...[
                {
                  ...SelectedTime?.[timingtype][index],
                  ...{
                    [day]: e?.target?.checked,
                  },
                },
              ],
              ...SelectedTime?.[timingtype]?.slice(
                index + 1,
                SelectedTime?.[timingtype]?.length
              ),
            ],
          },
        };
        console.log("jdshgfsgsfjfsaaaa", timingtype, setvalue);
        // SetSelectedTime(setvalue)
      } else if (type == "time") {
        e._d = new Date(e._d.setSeconds(0, 0));
        console.log(
          "lsjklksjkfjsks",
          SelectedTime?.[timingtype]?.[index]?.EndTimeshow <= e._d,
          SelectedTime?.[timingtype]?.[index]?.EndTimeshow,
          SelectedTime?.[timingtype]?.[index]?.StartTimeshow,
          e._d,
          day
        );
        if (day == "EndTime") {
          if (SelectedTime?.[timingtype]?.[index]?.StartTimeshow >= e._d) {
            toast.error("Time Choosen Must be Greater than Start Time");
            SetSelectedTime({ ...SelectedTime });
            setDayTimings({ ...DayTimings });
            return false;
          }
        } else if (day == "StartTime") {
          if (SelectedTime?.[timingtype]?.[index]?.EndTimeshow <= e._d) {
            toast.error("Time Choosen Must be Less than End Time");
            SetSelectedTime({ ...SelectedTime });
            setDayTimings({ ...DayTimings });
            return false;
          }
        }
        setvalue = {
          ...SelectedTime,
          ...{
            [timingtype]: [
              ...SelectedTime?.[timingtype]?.slice(0, index),
              ...[
                {
                  ...SelectedTime?.[timingtype]?.[index],
                  ...{
                    [day]:
                      MinuteHourFormat(e._d.getHours()) +
                      ":" +
                      MinuteHourFormat(e._d.getMinutes()),
                    [day + "show"]: e._d,
                  },
                },
              ],
              ...SelectedTime?.[timingtype]?.slice(
                index + 1,
                SelectedTime?.[timingtype]?.length
              ),
            ],
          },
        };
      }
      let instdaytiming = DayTimings;
      let Time = {
        StartTime: HourMinutetoTime(
          setvalue?.[timingtype]?.[index]?.StartTime.split(":")[0],
          setvalue?.[timingtype]?.[index]?.StartTime.split(":")[1]
        ),
        EndTime: HourMinutetoTime(
          setvalue?.[timingtype]?.[index]?.EndTime.split(":")[0],
          setvalue?.[timingtype]?.[index]?.EndTime.split(":")[1]
        ),
      };

      if ((type == "checkbox" && e?.target?.checked) || type == "time") {
        Object.keys(setvalue?.[timingtype]?.[index])?.map((val) => {
          if (
            val != "StartTime" &&
            val != "StartTimeshow" &&
            val != "EndTime" &&
            val != "EndTimeshow"
          ) {
            if (DayTimings?.[val] && setvalue?.[timingtype]?.[index]?.[val]) {
              let curdaytimeind = DayTimings?.[val]?.[
                timingtype
              ]?.timing?.findIndex(
                (findindexval) =>
                  findindexval?.StartTime ==
                    SelectedTime?.[timingtype]?.[index]?.StartTime &&
                  findindexval?.EndTime ==
                    SelectedTime?.[timingtype]?.[index]?.EndTime
              );
              if (curdaytimeind != -1) {
                console.log(
                  "ldkfkjdlfsfmapppppifff",
                  instdaytiming[val],
                  val,
                  timingtype,
                  curdaytimeind,
                  instdaytiming?.[val]?.[timingtype]?.timing?.slice(
                    0,
                    curdaytimeind
                  )
                );
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [val]: {
                      ...instdaytiming?.[val],
                      ...{
                        [timingtype]: {
                          ...instdaytiming?.[val]?.[timingtype],
                          ...{
                            Status: true,
                            timing: [
                              ...instdaytiming?.[val]?.[
                                timingtype
                              ]?.timing?.slice(0, curdaytimeind),
                              ...[
                                {
                                  ...instdaytiming[val]?.[timingtype]?.timing?.[
                                    curdaytimeind
                                  ],
                                  ...{
                                    StartTime:
                                      setvalue?.[timingtype]?.[index]
                                        ?.StartTime,
                                    StartHour:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.StartTime?.split(":")[0],
                                    StartMinute:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.StartTime?.split(":")[1],
                                    EndTime:
                                      setvalue?.[timingtype]?.[index]?.EndTime,
                                    EndHour:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.EndTime?.split(":")[0],
                                    EndMinute:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.EndTime?.split(":")[1],
                                  },
                                },
                              ],
                              ...instdaytiming[val]?.[
                                timingtype
                              ]?.timing?.slice(
                                curdaytimeind + 1,
                                instdaytiming[val]?.[timingtype]?.timing?.length
                              ),
                            ],
                          },
                        },
                      },
                    },
                  },
                };
                Object.keys(instdaytiming?.[val])?.map((insttimingtype) => {
                  if (insttimingtype != "Timing" && insttimingtype != "day") {
                    if (
                      instdaytiming?.[val]?.[insttimingtype]?.Status == false ||
                      instdaytiming?.[val]?.[instdaytiming]?.timing?.length == 0
                    ) {
                      if (insttimingtype != "DeliveryTimeCharge") {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [val]: {
                              ...instdaytiming[val],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[val][insttimingtype],
                                  ...{
                                    Status: true,
                                    AfterPreparation: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      } else {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [val]: {
                              ...instdaytiming[val],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[val][insttimingtype],
                                  ...{
                                    StartHour: "",
                                    StartMinute: "",
                                    StartTime: "",
                                    EndHour: "",
                                    EndMinute: "",
                                    EndTime: "",
                                    Status: true,
                                    timing: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      }
                    }
                  }
                });
              } else {
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [val]: {
                      ...instdaytiming[val],
                      ...{
                        [timingtype]: {
                          ...instdaytiming[val][timingtype],
                          ...{
                            Status: true,
                            timing: [
                              ...instdaytiming[val]?.[timingtype]?.timing,
                              ...[
                                {
                                  StartTime:
                                    setvalue?.[timingtype]?.[index]?.StartTime,
                                  StartHour:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.StartTime?.split(":")[0],
                                  StartMinute:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.StartTime?.split(":")[1],
                                  EndTime:
                                    setvalue?.[timingtype]?.[index]?.EndTime,
                                  EndHour:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.EndTime?.split(":")[0],
                                  EndMinute:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.EndTime?.split(":")[1],
                                },
                              ],
                            ],
                          },
                        },
                      },
                    },
                  },
                };
              }
            }
          }
        });
      } else if (type == "checkbox" && !e?.target?.checked) {
        let timingindex = instdaytiming?.[day]?.[timingtype]?.timing?.findIndex(
          (daytimeindex) =>
            daytimeindex?.StartTime ==
              SelectedTime?.[timingtype]?.[index]?.StartTime &&
            daytimeindex?.EndTime ==
              SelectedTime?.[timingtype]?.[index]?.EndTime
        );
        if (timingindex != -1) {
          let newtiming = {
            StartHour: "",
            StartMinute: "",
            StartTime: "",
            StartTimeshow: "",
            EndHour: "",
            EndMinute: "",
            EndTime: "",
            EndTimeshow: "",
          };
          instdaytiming = {
            ...instdaytiming,
            ...{
              [day]: {
                ...instdaytiming[day],
                ...{
                  [timingtype]: {
                    ...instdaytiming[day][timingtype],
                    ...{
                      timing: [
                        ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                          0,
                          timingindex
                        ),
                        ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                          timingindex + 1,
                          instdaytiming[day]?.[timingtype]?.timing?.length
                        ),
                      ],
                    },
                  },
                },
              },
            },
          };

          if (timingtype == "Timing") {
            Object.keys(instdaytiming).map((days) => {
              console.log("kjfkdfkljsfs", instdaytiming[days]);
              if (
                instdaytiming?.[days]?.Timing?.Status &&
                instdaytiming?.[days]?.Timing?.timing?.length == 0
              ) {
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [days]: {
                      ...instdaytiming[days],
                      ...{
                        [timingtype]: {
                          ...instdaytiming[days][timingtype],
                          ...{
                            StartHour: "",
                            StartMinute: "",
                            StartTime: "",
                            EndHour: "",
                            EndMinute: "",
                            EndTime: "",
                            Status: false,
                          },
                        },
                      },
                    },
                  },
                };
                Object.keys(instdaytiming?.[days])?.map((insttimingtype) => {
                  if (insttimingtype != "Timing" && insttimingtype != "day") {
                    if (
                      instdaytiming?.[days]?.[insttimingtype]?.Status ||
                      instdaytiming?.[days]?.[instdaytiming]?.timing?.length > 0
                    ) {
                      if (insttimingtype != "DeliveryTimeCharge") {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [days]: {
                              ...instdaytiming[days],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[days][insttimingtype],
                                  ...{
                                    Status: false,
                                    AfterPreparation: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      } else {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [days]: {
                              ...instdaytiming[days],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[days][insttimingtype],
                                  ...{
                                    StartHour: "",
                                    StartMinute: "",
                                    StartTime: "",
                                    EndHour: "",
                                    EndMinute: "",
                                    EndTime: "",
                                    Status: false,
                                    timing: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      }
                    }
                  }
                });
              }
            });
          }
        }
      }
      Object.keys(instdaytiming).map((days) => {
        if (instdaytiming[days][timingtype]?.Status) {
          let maxtime = GetMaxendMinstart(instdaytiming, days, timingtype);
          instdaytiming = {
            ...instdaytiming,
            ...{
              [days]: {
                ...instdaytiming[days],
                ...{
                  [timingtype]: {
                    ...instdaytiming[days][timingtype],
                    ...maxtime,
                  },
                },
              },
            },
          };
        }
      });

      SetSelectedTime({ ...setvalue });
      setDayTimings({ ...instdaytiming });
    } catch (err) {
      console.log("OnChangeTime Error", err);
    }
  };
  const GetMaxendMinstart = (instdaytiming, days, timingtype) => {
    let minstart = "";
    let maxend = "";
    instdaytiming?.[days]?.[timingtype]?.timing?.map((timings) => {
      if (
        minstart == "" ||
        HourMinutetoTime(timings?.StartHour, timings?.StartMinute) <
          HourMinutetoTime(minstart.split(":")[0], minstart.split(":")[1])
      ) {
        minstart = timings?.StartTime;
      }
      if (
        maxend == "" ||
        HourMinutetoTime(timings?.EndHour, timings?.EndMinute) <
          HourMinutetoTime(maxend.split(":")[0], maxend.split(":")[1])
      ) {
        maxend = timings?.EndTime;
      }
    });
    return {
      StartHour: minstart ? minstart.split(":")[0] : "",
      StartMinute: minstart ? minstart.split(":")[1] : "",
      StartTime: minstart,
      EndHour: maxend ? maxend.split(":")[0] : "",
      EndMinute: maxend ? maxend.split(":")[1] : "",
      EndTime: maxend,
    };
  };

  const RemoveTime = (index, type) => {
    let insttimings = DayTimings;
    Object.keys(SelectedTime?.[type]?.[index]).map((val) => {
      if (
        val != "EndTime" &&
        val != "EndTimeshow" &&
        val != "StartTime" &&
        val != "StartTimeshow"
      ) {
        let maxtime = {
          StartTime: "",
          StartTimeshow: "",
          StartHour: "",
          StartMinute: "",
          EndTime: "",
          EndTimeshow: "",
          EndHour: "",
          EndMinute: "",
        };
        console.log("dishfjkhsfs", maxtime);
        let findtimingindex = insttimings?.[val]?.[type]?.timing?.findIndex(
          (findinst) =>
            findinst?.StartTime == SelectedTime?.[type]?.[index]?.StartTime &&
            findinst?.EndTime == SelectedTime?.[type]?.[index]?.EndTime
        );
        if (findtimingindex != -1) {
          insttimings = {
            ...insttimings,
            ...{
              [val]: {
                ...insttimings?.[val],
                ...{
                  [type]: {
                    ...insttimings?.[val]?.[type],
                    ...{
                      timing: [
                        ...insttimings?.[val]?.[type]?.timing?.slice(
                          0,
                          findtimingindex
                        ),
                        ...insttimings?.[val]?.[type]?.timing?.slice(
                          findtimingindex + 1,
                          insttimings?.[val]?.[type]?.timing?.length
                        ),
                      ],
                    },
                  },
                },
              },
            },
          };
        }
      }
    });
    console.log("dishfjkhsfsouttt", insttimings);
    setDayTimings({ ...insttimings });
    SetChooseTimeArray([
      ...ChooseTimeArray.slice(0, index),
      ...ChooseTimeArray.slice(index + 1, ChooseTimeArray?.length),
    ]);
    SetSelectedTime({
      ...SelectedTime,
      ...{
        [type]: [
          ...SelectedTime?.[type]?.slice(0, index),
          ...SelectedTime?.[type]?.slice(
            index + 1,
            SelectedTime?.[type]?.length
          ),
        ],
      },
    });
  };

  const AddNewTiming = (type) => {
    SetChooseTimeArray([...ChooseTimeArray, ...[ChooseTimeArray.length + 1]]);
    SetSelectedTime({
      ...SelectedTime,
      ...{
        [type]: [
          ...SelectedTime?.[type],
          ...[
            {
              StartTime: "00:00",
              StartTimeshow: HourMinutetoTime(0, 0),
              EndTime: "24:00",
              EndTimeshow: HourMinutetoTime(23, 59),
            },
          ],
        ],
      },
    });
  };

  const Add = async (newadd, LatLng) => {
    if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
      var Latlngs = await GetLatLngBasedOnAddress(Address?.Address ?? newadd);
      var Latlng = Latlngs?.coordinates;
    } else Latlng = Address?.Latlng ?? LatLng;
    if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
      var Addresses = await GetAddressBasedOnLatLng(
        Address?.Latlng?.[0] ?? LatLng?.[0],
        Address?.Latlng?.[1] ?? LatLng?.[1]
      );
    } else Addresses = Address?.Address ? Address?.Address : newadd;
    console.log("AddressAddress", Addresses, Address, LatLng);
  };

  return (
    // (!isEmpty(Categorys)) &&
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            <div className="container custom_container">
              <div className="row">
                <div className="innerpages_wid">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset"
                        : "content-sec-inner"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="sec_border acnt_secborder">
                        <div className="account_bannerimg">
                          <div className="account_profile_whole">
                            <div className="acnt_profileedit_align">
                              <div className="acnt_profile_edit">
                                <img
                                  src={require("../assets/images/icons/camera.png")}
                                />
                                <input
                                  className="acnf_profile_editinp"
                                  type="file"
                                  id="BrandImage"
                                  onChange={(e) => Onchange(e)}
                                />
                              </div>
                            </div>
                            <img
                              className="acnt_profile_img"
                              src={
                                _shop?.BrandImage
                                  ? typeof _shop?.BrandImage == "object"
                                    ? window.URL.createObjectURL(
                                        _shop?.BrandImage
                                      )
                                    : `${config.IMG_URL}/shop/${_shop?.BrandImage}`
                                  : require("../assets/images/profilelogo.png")
                              }
                            />
                          </div>
                        </div>
                        <span className="error-msg mt-4">
                          {Error?.BrandImage}
                        </span>

                        <div className="mdv_drivertab acnt_drivertab">
                          <p
                            className={
                              activeTab == "profile"
                                ? "mdv_tabtext active"
                                : "mdv_tabtext"
                            }
                            onClick={() => setActiveTab("profile")}
                          >
                            Profile
                          </p>

                          <p
                            className={
                              activeTab == "operationtime"
                                ? "mdv_tabtext active"
                                : "mdv_tabtext"
                            }
                            onClick={() => setActiveTab("operationtime")}
                          >
                            Operation Time
                          </p>
                        </div>
                        <Col
                          xxl={3}
                          xl={3}
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          className="mb-3"
                        >
                          <p className="mdv_inplabel">
                            {id ? "Edit Shop" : "Add Shop"}
                          </p>
                        </Col>
                      </div>

                      {activeTab == "profile" ? (
                        <>
                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl"></p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">BrandName</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="BrandName"
                                  placeholder="Cafe"
                                  id="BrandName"
                                  value={_shop?.BrandName}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.BrandName && (
                                  <span className="error-msg">
                                    {Error.BrandName}
                                  </span>
                                )}
                              </Col>

                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">BrandDescription</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="BrandDescription"
                                  placeholder="ourshopdescribe..."
                                  id="BrandDescription"
                                  value={_shop?.BrandDescription}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.BrandDescription && (
                                  <span className="error-msg">
                                    {Error.BrandDescription}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">EmailId</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="email"
                                  placeholder="EmailId"
                                  id="EmailId"
                                  value={_shop?.EmailId}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.EmailId && (
                                  <span className="error-msg">
                                    {Error.EmailId}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Mobile</p>
                                {/* <input className='mdv_cmninput' autoComplete="off" type="number" placeholder="Mobile" id='Mobile' value={_shop?.Mobile} onChange={(e) => Onchange(e)} /> */}
                                <PhoneInput
                                  className="new_phoneinp"
                                  placeholder="Enter phone number"
                                  id="Mobile"
                                  value={_shop?.Mobile}
                                  onChange={(val) => Onchange(val, "Mobile")}
                                  defaultCountry="US"
                                  country="US"
                                  countries={["US","IN"]}
                                  // onCountryChange={(val)=>Onchange(val,"Mobile","country")}
                                />
                                {Error?.Mobile && (
                                  <span className="error-msg">
                                    {Error.Mobile}
                                  </span>
                                )}
                              </Col>

                              {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                              <p className='mdv_inplabel'>Discount</p>
                              <input className='mdv_cmninput' autoComplete="off" type="number" placeholder="Discount" id='Discount' value={ShopDetail?.Discount} onChange={(e) => Onchange(e)} />
                              { Error?.Discount && <span className='error-msg'>{Error.Discount}</span>}
                            </Col> */}
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel signin_labels">
                                  Category
                                </p>
                                {console.log("ldflkdsfdsf", _shop)}
                                {/* <input className='mdv_cmninput' autoComplete="off" type="email" placeholder="john@jamkse.com"/> */}
                                {/* <label className='signin_labels' for="mobile"></label> */}
                                {!isEmpty(Categorys) &&
                                  (id ? (
                                    <Select
                                      options={Categorys}
                                      value={{
                                        label:
                                          (Categorys || [])?.filter(
                                            (it) => it?.value == _shop?.Category
                                          )?.length > 0
                                            ? Categorys.filter(
                                                (it) =>
                                                  it?.id == _shop?.Category
                                              )[0]?.label
                                            : _shop?.Category,
                                        value: _shop?.Category,
                                      }}
                                      styles={stylesgraybg}
                                      onChange={(e) => Onchange(e, "Category")}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Category"
                                    />
                                  ) : (
                                    <Select
                                      options={Categorys}
                                      styles={stylesgraybg}
                                      onChange={(e) => Onchange(e, "Category")}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Category"
                                    />
                                  ))}

                                {Error?.Category && (
                                  <span className="error-msg">
                                    {Error.Category}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Sub Category</p>
                                {/* <input className='mdv_cmninput' autoComplete="off" type="email" placeholder="john@jamkse.com"/> */}
                                {!isEmpty(_shop?.Category) &&
                                  (id ? (
                                    <Select
                                      isMulti
                                      closeMenuOnSelect={false}
                                      options={SubCategorys}
                                      value={
                                        SubCategorys?.length > 0 ?
                                        (_shop?.SubCategory || [])?.map(
                                        (it) => {
                                          return {
                                            label:
                                              (SubCategorys || [])?.filter(
                                                (its) => it == its?.value
                                              )?.length > 0
                                                ? SubCategorys?.filter(
                                                    (its) => it == its?.value
                                                  )?.[0]?.label
                                                : it,
                                            value: it,
                                          };
                                        })
                                        :
                                        {
                                          label: "All",
                                          value: "All",
                                        }
                                      }
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        Onchange(
                                          { value: e.map((e) => e.value) },
                                          "SubCategory"
                                        )
                                      }
                                      className="border_blue_select react_multi_slct"
                                      classNamePrefix="react-select"
                                      placeholder="SubCategory"
                                      isDisabled={SubCategorys?.length>0?false:true}
                                    />
                                  ) : (
                                    <Select
                                      isMulti
                                      options={SubCategorys}
                                      value={
                                        SubCategorys?.length > 0 ?
                                        (_shop.SubCategory || [])?.map(
                                        (it) => {
                                          return {
                                            label: (SubCategorys || [])?.filter(
                                              (its) => it == its?.value
                                            )?.[0]?.label,
                                            value: it,
                                          };
                                        })
                                        :
                                        {
                                          label:"All",
                                          value:"All",
                                        }
                                      }
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        Onchange(
                                          { value: e.map((e) => e.value) },
                                          "SubCategory"
                                        )
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="SubCategory"
                                      isDisabled={SubCategorys?.length>0?false:true}
                                    />
                                  ))}

                                {Error?.SubCategory && (
                                  <span className="error-msg">
                                    {Error.SubCategory}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Address</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Street Name</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="6th avenue"
                                  id="StreetName"
                                  name="Address"
                                  value={_shop?.Address?.StreetName}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.StreetName && (
                                  <span className="error-msg">
                                    {Error.StreetName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">City</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="e.x.Manhattan"
                                  id="City"
                                  name="Address"
                                  value={_shop?.Address?.City}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.City && (
                                  <span className="error-msg">
                                    {Error.City}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">State</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="New York"
                                  id="State"
                                  name="Address"
                                  value={_shop?.Address?.State}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.State && (
                                  <span className="error-msg">
                                    {Error.State}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Country</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="America"
                                  id="Country"
                                  name="Address"
                                  value={_shop?.Address?.Country}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.Country && (
                                  <span className="error-msg">
                                    {Error.Country}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Zip Code</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="10101"
                                  id="Zipcode"
                                  name="Address"
                                  value={_shop?.Address?.Zipcode}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.Zipcode && (
                                  <span className="error-msg">
                                    {Error.Zipcode}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Click To Choose Address
                                </p>
                                <div
                                  className="header_locateme_btn locate_flex_align"
                                  onClick={() => setShow_map(true)}
                                >
                                  <img
                                    src={require("../assets/images/icons/Icon.png")}
                                  />
                                  <p>Locate me</p>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Bank Details</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Bank Name</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Bank Of America"
                                  name="BankDetails"
                                  id="BankName"
                                  value={_shop?.BankDetails?.BankName}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.BankName && (
                                  <span className="error-msg">
                                    {Error.BankName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Account Type</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Business"
                                  name="BankDetails"
                                  id="AccountType"
                                  value={_shop?.BankDetails?.AccountType}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.AccountType && (
                                  <span className="error-msg">
                                    {Error.AccountType}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Account Holder Name
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Sweet green"
                                  name="BankDetails"
                                  id="AccountHolderName"
                                  value={_shop?.BankDetails?.AccountHolderName}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.AccountHolderName && (
                                  <span className="error-msg">
                                    {Error.AccountHolderName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Bank Account Number
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="number"
                                  placeholder="5894 58967 6984"
                                  name="BankDetails"
                                  id="BankAccountNumber"
                                  value={_shop?.BankDetails?.BankAccountNumber}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.BankAccountNumber && (
                                  <span className="error-msg">
                                    {Error.BankAccountNumber}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Routing Number</p>
                                <input
                                  className="mdv_cmninput"
                                  autoComplete="off"
                                  type="number"
                                  placeholder="1562 87952"
                                  name="BankDetails"
                                  id="RoutingNumber"
                                  value={_shop?.BankDetails?.RoutingNumber}
                                  onChange={(e) => Onchange(e)}
                                />
                                {Error?.RoutingNumber && (
                                  <span className="error-msg">
                                    {Error.RoutingNumber}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Currency</p>
                                <Select
                                  options={Currencies}
                                  styles={stylesgraybg}
                                  onChange={(e) => Onchange(e, "Currency")}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Choose Currency"
                                  value={
                                    _shop?.CurrencyName
                                      ? {
                                          label: _shop?.CurrencyName,
                                          value: _shop?.CurrencySymbol,
                                        }
                                      : {}
                                  }
                                  isSearchable={false}
                                />
                                {Error?.Currency && (
                                  <span className="error-msg">
                                    {Error.Currency}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Store Images</p>
                            </div>

                            <div className="mvv_griddtls">
                              <>
                                {_shop?.StoreImages &&
                                  _shop?.StoreImages?.map((item) => {
                                    return (
                                      <div className="mvv_gridsingle">
                                        <img
                                          className="mvv_shopimg"
                                          src={
                                            item
                                              ? item?._id
                                                ? item?.Name
                                                  ? `${config?.IMG_URL}/shop/${item?.Name}`
                                                  : require("../assets/images/store.png")
                                                : window.URL.createObjectURL(
                                                    item
                                                  )
                                              : require("../assets/images/store.png")
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                              </>

                              <div className="mvv_gridsingle">
                                <div className="mvv_uploadfile_dtl">
                                  <img
                                    className="img-fluid"
                                    src={require("../assets/images/icons/cloud.png")}
                                  />
                                  <p className="mvv_cloudtext">Upload File</p>
                                  <input
                                    type="file"
                                    className="mvv_fileupload"
                                    id="StoreImages"
                                    onChange={(e) => Onchange(e, "StoreImages")}
                                    multiple
                                  />
                                  <span className="error-msg">
                                    {Error?.StoreImages}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mdv_update_dualbtn mt-3">
                            <button
                              className="blue_btn mdv_update"
                              onClick={() => {
                                onsubmit();
                              }}
                              disabled={disabledstatus?.Submit}
                            >
                              Next
                            </button>
                            {pathname.includes("add") && (
                              <button className="seconday_btn">Clear</button>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* end of profile details */}

                      {/* operation time details */}

                      {activeTab == "operationtime" && (
                        <div className="whole_operationtime_border">
                          <div className="mvv_catelog_timerow krmvv_catelog_timerow">
                            <div className="mvv_operationtime_tabs krmvv_operationtime_tabs">
                              <p
                                className={
                                  subactiveTab == "Timing"
                                    ? "mvv_subtabs active"
                                    : "mvv_subtabs"
                                }
                                onClick={() => setSubActiveTab("Timing")}
                              >
                                StoreTiming
                              </p>
                              <p
                                className={
                                  subactiveTab == "operationtime"
                                    ? "mvv_subtabs active"
                                    : "mvv_subtabs"
                                }
                                onClick={() => setSubActiveTab("operationtime")}
                              >
                                Delivery / Pickup
                              </p>

                              {/* <p
                              className={
                                subactiveTab == "DeliveryTimeCharge"
                                  ? "mvv_subtabs active"
                                  : "mvv_subtabs"
                              }
                              onClick={() =>
                                setSubActiveTab("DeliveryTimeCharge")
                              }
                            >
                              Delivery-TimeCharge
                            </p> */}
                            </div>

                            <div className="rightsec secondary_tog">
                              <p className="mvv_acceptorder">
                                Accepting Orders
                              </p>
                              <div className="togswit ">
                                <input
                                  type="checkbox"
                                  id="toggle-btn1"
                                  checked={AcceptingOrders}
                                  onChange={(e) => {
                                    setAcceptingOrders(e.target.checked);
                                    setSubActiveTab(
                                      !e.target.checked ? "" : "Timing"
                                    );
                                  }}
                                />
                                <label for="toggle-btn1"></label>
                              </div>
                            </div>
                          </div>

                          {subactiveTab == "Timing" && (
                            <div className=" py-3 pb-3">
                              {/* <div className="subact_tabled_whole">
                              <div className="subact_delivery_whole">
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Day</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Status</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">24 Hours</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Open Time</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">
                                    Close Time
                                  </p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl"> Action</p>
                                </div>
                              </div>
                              {console.log('kdsjdkjsfs',DayTimings)}
                              {Object.values(DayTimings)?.map((localvar) => {
                                console.log('kdsjdkjsfsinn',(DayTimings[localvar?.Delivery?.day]?.Timing?.timing.length>0 &&  DayTimings[localvar?.Delivery?.day]?.Timing?.timing?.some(is=>is.StartTime != "" && is.EndTime != "" )))
                                return (
                                (DayTimings[localvar?.Delivery?.day]?.Timing?.timing.length>0 &&  DayTimings[localvar?.Delivery?.day]?.Timing?.timing?.some(is=>is.StartTime != "" && is.EndTime != "" )) ?
                                  <div className="subact_delivery_whole">
                                    <div className="subact_deliver_singles">
                                      <p>{localvar?.day}</p>
                                    </div>
                                    <div className="subact_deliver_singles">
                                      <div className="rightsec secondary_togtab">
                                        <div className="togswit togswit_redgreen me-2">
                                          <input
                                            type="checkbox"
                                            checked={localvar?.Delivery?.Status}
                                            id={localvar?.Delivery?.day}
                                            onChange={(e) =>
                                              click(e, null, "Delivery")
                                            }
                                          />
                                          <label for={localvar?.day}></label>
                                        </div>
                                        <p className="mvv_acceptorder">
                                          {localvar?.Delivery?.Status
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </div>
                                    {localvar?.Delivery?.Status&&
                                    <div className="subact_deliver_singles">
                                      <div className="rightsec secondary_togtab">
                                        <div className="togswit togswit_redgreen me-2">
                                          <input
                                            type="checkbox"
                                            checked={localvar?.Delivery?.hours}
                                            id={`${localvar?.Delivery?.day}1`}
                                            onChange={(e) =>
                                              _24hours(e, "Delivery")
                                            }
                                          />
                                          <label
                                            for={`${localvar?.Delivery?.day}1`}
                                          ></label>
                                        </div>
                                        <p className="mvv_acceptorder">
                                          {localvar?.Delivery?.hours
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </div>}
                                    <div className="subact_deliver_singles">
                                      {localvar?.Delivery?.Status &&
                                        localvar?.Delivery?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <div className="storetiming_flexing mt-2">
                                                {
                                                  <>
                                                    <input
                                                      className="sigin_inputs timing_inputs"
                                                      type="time"  step="3600"
                                                      disabled={
                                                        localvar?.Delivery
                                                          ?.hours
                                                      }
                                                      value={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.StartTime || ""
                                                      }
                                                      name={indexs}
                                                      placeholder="10:00 AM"
                                                      id={
                                                        localvar?.Delivery?.day
                                                      }
                                                      max={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.EndTime
                                                      }
                                                      // min={"09:00"}
                                                      // max={"10:00"}

                                                      onChange={(e) =>
                                                        click(
                                                          e,
                                                          "Start",
                                                          "Delivery"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="subact_deliver_singles">
                                      {localvar?.Delivery?.Status &&
                                        localvar?.Delivery?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <div className="storetiming_flexing mt-2">
                                                {
                                                  <>
                                                    <input
                                                      className="sigin_inputs timing_inputs"
                                                      type="time"  step="3600"
                                                      disabled={
                                                        localvar?.Delivery
                                                          ?.hours
                                                      }
                                                      value={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.EndTime || ""
                                                      }
                                                      name={indexs}
                                                      placeholder="10:00 AM"
                                                      id={
                                                        localvar?.Delivery?.day
                                                      }
                                                      min={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.StartTime
                                                      }
                                                      // min={"09:00"}
                                                      // max={"10:00"}

                                                      onChange={(e) =>
                                                        click(
                                                          e,
                                                          "End",
                                                          "Delivery"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="subact_deliver_singles">
                                      {localvar?.["Delivery"]?.Status &&
                                        !localvar?.["Delivery"]?.hours &&
                                        localvar?.["Delivery"]?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <>
                                                <button
                                                  className="mmv_tabgn_plusbtn"
                                                  onClick={() =>
                                                    table(
                                                      localvar?.["Delivery"]?.day,
                                                      undefined,
                                                      "Delivery"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={require("../assets/images/icons/plusroundgreen.png")}
                                                  />
                                                </button>

                                                {indexs >= 1 && (
                                                  <button
                                                    className="mmv_tabgn_plusbtn"
                                                    onClick={() =>
                                                      table(
                                                        localvar?.["Delivery"]
                                                          ?.day,
                                                        indexs,
                                                        "Delivery"
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/icons/minusround.png")}
                                                    />
                                                  </button>
                                                )}
                                                <br />
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>:<></>
                                
                                );
                              })}
                            </div> */}
                              <Container>
                                <Row>
                                  <Col xl={12} lg={12} md={12} xs={12}>
                                    <div className="mangra_overflow_align mangra_overflow_align_new">
                                      <p className="mangra_whole_ttl">
                                        Business Hours Graph
                                      </p>
                                      <div className="manual_graph_whle">
                                        <div className="manualgra_xaxis_txt">
                                          <p>Day</p>
                                          <p>Midnight</p>
                                          <p>4AM</p>
                                          <p>8AM</p>
                                          <p>Lunch</p>
                                          <p>4PM</p>
                                          <p>8PM</p>
                                        </div>

                                        {console.log(
                                          "kjkfsjlkfskjfhks",
                                          DayTimings,
                                          SelectedTime
                                        )}

                                        {Object.keys(DayTimings)?.map((val) => {
                                          return (
                                            // DayTimings?.[val]?.Timing?.Status &&
                                            <div className="manual_graph_single">
                                              <p className="manualgra_yaxis_txt">
                                                {val.substring(0, 3)}
                                              </p>
                                              {HourMinuteDisplay?.Hours?.map(
                                                (hour) => {
                                                  return (
                                                    <div className="single_square">
                                                      {HourMinuteDisplay?.Minutes?.map(
                                                        (minute) => {
                                                          return (
                                                            <div
                                                              className={
                                                                DayTimings?.[
                                                                  val
                                                                ]?.Timing
                                                                  ?.timing
                                                                  ?.length > 0
                                                                  ? DayTimings?.[
                                                                      val
                                                                    ]?.Timing?.timing?.some(
                                                                      (val) =>
                                                                        HourMinutetoTime(
                                                                          hour,
                                                                          minute *
                                                                            15
                                                                        ) >=
                                                                          HourMinutetoTime(
                                                                            val?.StartHour,
                                                                            val?.StartMinute,
                                                                            "start"
                                                                          ) &&
                                                                        HourMinutetoTime(
                                                                          hour,
                                                                          minute *
                                                                            15
                                                                        ) <=
                                                                          HourMinutetoTime(
                                                                            val?.EndHour,
                                                                            val?.EndMinute,
                                                                            "end"
                                                                          ) &&
                                                                        (minute *
                                                                          15 !==
                                                                          60 ||
                                                                          (minute *
                                                                            15 ==
                                                                            60 &&
                                                                            hour !=
                                                                              Number(
                                                                                val?.StartHour
                                                                              ) -
                                                                                1))
                                                                    )
                                                                    ? "timing_div"
                                                                    : "test111"
                                                                  : "test111"
                                                              }
                                                            ></div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <Row className="mt-3">
                                        <Col xs={12}>
                                          <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title checkbox_title_new">
                                                Select day(s):
                                              </p>
                                            </div>
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title tet_lefvc checkbox_title_new ps-3">
                                                Select times:
                                              </p>
                                            </div>
                                          </div>
                                          <div className="whole_check_datepick mt-3">
                                            <div className="daysslct_ttl day_width_45">
                                              {/* <div className="">

                                    <div className="daysslct_ttl"> */}
                                              {Object.keys(DayTimings)?.map(
                                                (val) => {
                                                  return (
                                                    // DayTimings?.[val]?.Timing?.Status &&
                                                    <p className="show_days_div">
                                                      {val?.substring(0, 1)}
                                                    </p>
                                                  );
                                                }
                                              )}
                                              {/*  <p>M</p>
                                       <p>T</p>
                                       <p>W</p>
                                      <p>T</p>
                                      <p>F</p>
                                       <p>S</p> */}
                                              {/* </div>
                                   
                                    </div> */}
                                            </div>

                                            <div className="dateselect_dtls">
                                              <div className="daysslct_ttl days_new_widh">
                                                <p>From</p>
                                                <p>To</p>
                                                <p className="hider_txt">
                                                  Delete
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {console.log(
                                            "sjhfjkhsfksfsfs",
                                            SelectedTime
                                          )}
                                          {SelectedTime?.Timing?.length > 0 &&
                                            SelectedTime?.Timing?.map(
                                              (arrind, ind) => {
                                                return (
                                                  <>
                                                    <div className="whole_check_datepick">
                                                      <div className="day_width_45">
                                                        {Object.keys(
                                                          DayTimings
                                                        )?.map((val) => {
                                                          return (
                                                            // DayTimings?.[val]?.Timing?.Status &&
                                                            <div className="linecheck_aligned linecheck_aligned_new">
                                                              <div className="modal_checkoptions odp_check">
                                                                <Form.Check type="checkbox">
                                                                  <Form.Check.Input
                                                                    type="checkbox"
                                                                    defaultChecked={
                                                                      SelectedTime
                                                                        ?.Timing?.[
                                                                        ind
                                                                      ]?.[val]
                                                                    }
                                                                    isValid
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      OnChangeTime(
                                                                        e,
                                                                        ind,
                                                                        val,
                                                                        "checkbox",
                                                                        "Timing"
                                                                      );
                                                                    }}
                                                                  />
                                                                </Form.Check>
                                                              </div>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>

                                                      <div className="dateselect_dtls">
                                                        <Datetime
                                                          viewMode={"time"}
                                                          value={
                                                            SelectedTime
                                                              ?.Timing?.[ind]
                                                              ?.StartTimeshow
                                                          }
                                                          // onViewModeChange={'time'}
                                                          dateFormat={false}
                                                          onChange={(e) => {
                                                            console.log(
                                                              "kdfjsfkdsfsd",
                                                              e._d
                                                            );
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "StartTime",
                                                              "time",
                                                              "Timing"
                                                            );
                                                          }}
                                                        />

                                                        <Datetime
                                                          viewMode={"time"}
                                                          dateFormat={false}
                                                          value={
                                                            SelectedTime
                                                              ?.Timing?.[ind]
                                                              ?.EndTimeshow
                                                          }
                                                          onChange={(e) =>
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "EndTime",
                                                              "time",
                                                              "Timing"
                                                            )
                                                          }
                                                        />
                                                        {/* <img
                                                          className="order_trashed_ic "
                                                          src={require("../assets/images/icons/trash.png")}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Timing"
                                                            )
                                                          }
                                                        /> */}
                                                        <img
                                                          className="order_trashed_ic "
                                                          src={delIcn}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Timing"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>

                                                    <div></div>
                                                  </>
                                                );
                                              }
                                            )}

                                          <div className="triple_copybtns">
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                AddNewTiming("Timing")
                                              }
                                            >
                                              Add Time
                                            </button>
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings("Timing", "Pickup")
                                              }
                                            >
                                              Copy This to Pickup
                                            </button>
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings(
                                                  "Timing",
                                                  "Delivery"
                                                )
                                              }
                                            >
                                              Copy This to Delivery
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                          {subactiveTab == "pickup" ? (
                            <div className="ffd_table px-3 pb-3">
                              <div className="subact_tabled_whole">
                                <div className="subact_delivery_whole">
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">Day</p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">Status</p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">
                                      24 Hours
                                    </p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">
                                      Open Time
                                    </p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">
                                      Close Time
                                    </p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl"> Action</p>
                                  </div>
                                </div>
                                {Object.values(DayTimings)?.map((localvar) => {
                                  return (
                                    DayTimings[localvar?.Delivery?.day]?.Timing
                                      ?.timing.length > 0 &&
                                    DayTimings[
                                      localvar?.Delivery?.day
                                    ]?.Timing?.timing?.some(
                                      (is) =>
                                        is.StartTime != "" && is.EndTime != ""
                                    ) && (
                                      <div className="subact_delivery_whole">
                                        <div className="subact_deliver_singles">
                                          <p>{localvar?.day}</p>
                                        </div>
                                        <div className="subact_deliver_singles">
                                          <div className="rightsec secondary_togtab">
                                            <div className="togswit togswit_redgreen me-2">
                                              <input
                                                autoComplete="off"
                                                type="checkbox"
                                                checked={
                                                  localvar?.Pickup?.Status
                                                }
                                                id={localvar?.Pickup?.day}
                                                onChange={(e) =>
                                                  click(e, null, "Pickup")
                                                }
                                              />
                                              <label
                                                for={localvar?.day}
                                              ></label>
                                            </div>
                                            <p className="mvv_acceptorder">
                                              {localvar?.Pickup?.Status
                                                ? "On"
                                                : "Off"}
                                            </p>
                                          </div>
                                        </div>
                                        {localvar?.Pickup?.Status && (
                                          <div className="subact_deliver_singles">
                                            <div className="rightsec secondary_togtab">
                                              <div className="togswit togswit_redgreen me-2">
                                                <input
                                                  autoComplete="off"
                                                  type="checkbox"
                                                  checked={
                                                    localvar?.Pickup?.hours
                                                  }
                                                  id={`${localvar?.Pickup?.day}1`}
                                                  onChange={(e) =>
                                                    _24hours(e, "Pickup")
                                                  }
                                                />
                                                <label
                                                  for={`${localvar?.Pickup?.day}1`}
                                                ></label>
                                              </div>
                                              <p className="mvv_acceptorder">
                                                {localvar?.Pickup?.hours
                                                  ? "On"
                                                  : "Off"}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                        <div className="subact_deliver_singles">
                                          {localvar?.Pickup?.Status &&
                                            localvar?.Pickup?.timing.map(
                                              (its, indexs) => {
                                                return (
                                                  <div className="storetiming_flexing mt-2">
                                                    {
                                                      <>
                                                        <input
                                                          autoComplete="off"
                                                          className="sigin_inputs timing_inputs"
                                                          type="time"
                                                          step="3600"
                                                          disabled={
                                                            localvar?.Pickup
                                                              ?.hours
                                                          }
                                                          value={
                                                            localvar?.Pickup
                                                              ?.timing[indexs]
                                                              ?.StartTime || ""
                                                          }
                                                          name={indexs}
                                                          placeholder="10:00 AM"
                                                          id={
                                                            localvar?.Pickup
                                                              ?.day
                                                          }
                                                          max={
                                                            localvar?.Pickup
                                                              ?.timing[indexs]
                                                              ?.EndTime
                                                          }
                                                          // min={"09:00"}
                                                          // max={"10:00"}

                                                          onChange={(e) =>
                                                            click(
                                                              e,
                                                              "Start",
                                                              "Pickup"
                                                            )
                                                          }
                                                        />
                                                      </>
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                        <div className="subact_deliver_singles">
                                          {localvar?.Pickup?.Status &&
                                            localvar?.Pickup?.timing.map(
                                              (its, indexs) => {
                                                return (
                                                  <div className="storetiming_flexing mt-2">
                                                    {
                                                      <>
                                                        <input
                                                          autoComplete="off"
                                                          className="sigin_inputs timing_inputs"
                                                          type="time"
                                                          // step="3600"
                                                          disabled={
                                                            localvar?.Pickup
                                                              ?.hours
                                                          }
                                                          value={
                                                            localvar?.Pickup
                                                              ?.timing[indexs]
                                                              ?.EndTime || ""
                                                          }
                                                          name={indexs}
                                                          placeholder="10:00 AM"
                                                          id={
                                                            localvar?.Pickup
                                                              ?.day
                                                          }
                                                          min={
                                                            localvar?.Pickup
                                                              ?.timing[indexs]
                                                              ?.StartTime
                                                          }
                                                          // min={"09:00"}
                                                          // max={"10:00"}

                                                          onChange={(e) =>
                                                            click(
                                                              e,
                                                              "End",
                                                              "Pickup"
                                                            )
                                                          }
                                                        />
                                                      </>
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                        <div className="subact_deliver_singles">
                                          {localvar["Pickup"]?.Status &&
                                            !localvar["Pickup"]?.hours &&
                                            localvar["Pickup"]?.timing.map(
                                              (its, indexs) => {
                                                return (
                                                  <>
                                                    <button
                                                      className="mmv_tabgn_plusbtn"
                                                      onClick={() =>
                                                        table(
                                                          localvar?.["Pickup"]
                                                            ?.day,
                                                          undefined,
                                                          "Pickup"
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={require("../assets/images/icons/plusroundgreen.png")}
                                                      />
                                                    </button>

                                                    {indexs >= 1 && (
                                                      <button
                                                        className="mmv_tabgn_plusbtn"
                                                        onClick={() =>
                                                          table(
                                                            localvar?.["Pickup"]
                                                              ?.day,
                                                            indexs,
                                                            "Pickup"
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/icons/minusround.png")}
                                                        />
                                                      </button>
                                                    )}
                                                    <br />
                                                  </>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                              {/* <button
                              className="blue_btn mdv_update"
                              onClick={() => onsubmit()}
                            >
                              Update Time
                            </button> */}
                            </div>
                          ) : (
                            <></>
                          )}

                          {subactiveTab == "operationtime" ? (
                            // <div className="ffd_table px-3 pb-3">
                            //   <div className="subact_tabled_whole">
                            //     <div className="subact_delivery_whole">
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl">Day</p>
                            //       </div>
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl">Status</p>
                            //       </div>
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl">24 Hours</p>
                            //       </div>
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl">Open Time</p>
                            //       </div>
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl">
                            //           Close Time
                            //         </p>
                            //       </div>
                            //       <div className="subact_deliver_singles">
                            //         <p className="subact_tabled_ttl"> Action</p>
                            //       </div>
                            //     </div>
                            //     {/* {console.log('kfdsjfklsd',Object.values(DayTimings))} */}
                            //     {Object.values(DayTimings)?.map((localvar) => {
                            //       return (

                            //         <div className="subact_delivery_whole">
                            //           <div className="subact_deliver_singles">
                            //             <p>{localvar.day}</p>
                            //           </div>
                            //           <div className="subact_deliver_singles">
                            //             <div className="rightsec secondary_togtab">
                            //               <div className="togswit togswit_redgreen me-2">
                            //                 <input
                            //                   type="checkbox"
                            //                   checked={localvar?.Timing?.Status}
                            //                   id={localvar?.Timing?.day}
                            //                   onChange={(e) =>
                            //                     click(e, null, "Timing")
                            //                   }
                            //                 />
                            //                 <label for={localvar?.day}></label>
                            //               </div>
                            //               <p className="mvv_acceptorder">
                            //                 {localvar?.Timing?.Status
                            //                   ? "On"
                            //                   : "Off"}
                            //               </p>
                            //             </div>
                            //           </div>
                            //           {localvar?.Timing?.Status&&
                            //           <div className="subact_deliver_singles">
                            //             <div className="rightsec secondary_togtab">
                            //               <div className="togswit togswit_redgreen me-2">
                            //                 <input
                            //                   type="checkbox"
                            //                   checked={localvar?.Timing?.hours}
                            //                   id={`${localvar?.Timing?.day}1`}
                            //                   onChange={(e) =>
                            //                     _24hours(e, "Timing")
                            //                   }
                            //                 />
                            //                 <label
                            //                   for={`${localvar?.Timing?.day}1`}
                            //                 ></label>
                            //               </div>
                            //               <p className="mvv_acceptorder">
                            //                 {localvar?.Timing?.hours
                            //                   ? "On"
                            //                   : "Off"}
                            //               </p>
                            //             </div>
                            //           </div>}
                            //           <div className="subact_deliver_singles">
                            //             {localvar?.Timing?.Status &&
                            //               localvar?.Timing?.timing.map(
                            //                 (its, indexs) => {
                            //                   return (
                            //                     <div className="storetiming_flexing mt-2">
                            //                       {
                            //                         <>
                            //                           <input
                            //                             className="sigin_inputs timing_inputs"
                            //                             type="time"  step="3600"
                            //                             disabled={
                            //                               localvar?.Timing?.hours
                            //                             }
                            //                             value={
                            //                               localvar?.Timing
                            //                                 ?.timing[indexs]
                            //                                 ?.StartTime || ""
                            //                             }
                            //                             name={indexs}
                            //                             placeholder="10:00 AM"
                            //                             id={localvar?.Timing?.day}
                            //                             max={
                            //                               localvar?.Timing
                            //                                 ?.timing[indexs]
                            //                                 ?.EndTime
                            //                             }
                            //                             // min={"09:00"}
                            //                             // max={"10:00"}

                            //                             onChange={(e) =>
                            //                               click(
                            //                                 e,
                            //                                 "Start",
                            //                                 "Timing"
                            //                               )
                            //                             }
                            //                           />
                            //                         </>
                            //                       }
                            //                     </div>
                            //                   );
                            //                 }
                            //               )}
                            //           </div>
                            //           <div className="subact_deliver_singles">
                            //             {localvar?.Timing?.Status &&
                            //               localvar?.Timing?.timing.map(
                            //                 (its, indexs) => {
                            //                   return (
                            //                     <div className="storetiming_flexing mt-2">
                            //                       {
                            //                         <>
                            //                           <input
                            //                             className="sigin_inputs timing_inputs"
                            //                             type="time"  step="3600"
                            //                             disabled={
                            //                               localvar?.Timing?.hours
                            //                             }
                            //                             value={
                            //                               localvar?.Timing
                            //                                 ?.timing[indexs]
                            //                                 ?.EndTime || ""
                            //                             }
                            //                             name={indexs}
                            //                             placeholder="10:00 AM"
                            //                             id={localvar?.Timing?.day}
                            //                             min={
                            //                               localvar?.Timing
                            //                                 ?.timing[indexs]
                            //                                 ?.StartTime
                            //                             }
                            //                             // min={"09:00"}
                            //                             // max={"10:00"}

                            //                             onChange={(e) =>
                            //                               click(
                            //                                 e,
                            //                                 "End",
                            //                                 "Timing"
                            //                               )
                            //                             }
                            //                           />
                            //                         </>
                            //                       }
                            //                     </div>
                            //                   );
                            //                 }
                            //               )}
                            //           </div>
                            //           <div className="subact_deliver_singles">
                            //             {localvar?.["Timing"]?.Status &&
                            //               !localvar?.["Timing"]?.hours &&
                            //               localvar?.["Timing"]?.timing.map(
                            //                 (its, indexs) => {
                            //                   return (
                            //                     <>
                            //                       <button
                            //                         className="mmv_tabgn_plusbtn"
                            //                         onClick={() =>
                            //                           table(
                            //                             localvar?.["Timing"]?.day,
                            //                             undefined,
                            //                             "Timing"
                            //                           )
                            //                         }
                            //                       >
                            //                         <img
                            //                           src={require("../assets/images/icons/plusroundgreen.png")}
                            //                         />
                            //                       </button>

                            //                       {indexs >= 1 && (
                            //                         <button
                            //                           className="mmv_tabgn_plusbtn"
                            //                           onClick={() =>
                            //                             table(
                            //                               localvar?.["Timing"]?.day,
                            //                               indexs,
                            //                               "Timing"
                            //                             )
                            //                           }
                            //                         >
                            //                           <img
                            //                             src={require("../assets/images/icons/minusround.png")}
                            //                           />
                            //                         </button>
                            //                       )}
                            //                       <br />
                            //                     </>
                            //                   );
                            //                 }
                            //               )}
                            //           </div>
                            //         </div>
                            //       );
                            //     })}
                            //   </div>
                            //   {/* <button
                            //     className="blue_btn mdv_update"
                            //     onClick={() => onsubmit()}
                            //   >
                            //     Update Time
                            //   </button> */}
                            // </div>
                            <div className=" py-3 pb-3">
                              {/* <div className="subact_tabled_whole">
                              <div className="subact_delivery_whole">
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Day</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Status</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">24 Hours</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Open Time</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">
                                    Close Time
                                  </p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl"> Action</p>
                                </div>
                              </div>
                              {console.log('kdsjdkjsfs',DayTimings)}
                              {Object.values(DayTimings)?.map((localvar) => {
                                console.log('kdsjdkjsfsinn',(DayTimings[localvar?.Delivery?.day]?.Timing?.timing.length>0 &&  DayTimings[localvar?.Delivery?.day]?.Timing?.timing?.some(is=>is.StartTime != "" && is.EndTime != "" )))
                                return (
                                (DayTimings[localvar?.Delivery?.day]?.Timing?.timing.length>0 &&  DayTimings[localvar?.Delivery?.day]?.Timing?.timing?.some(is=>is.StartTime != "" && is.EndTime != "" )) ?
                                  <div className="subact_delivery_whole">
                                    <div className="subact_deliver_singles">
                                      <p>{localvar?.day}</p>
                                    </div>
                                    <div className="subact_deliver_singles">
                                      <div className="rightsec secondary_togtab">
                                        <div className="togswit togswit_redgreen me-2">
                                          <input
                                            autoComplete="off"
                                            type="checkbox"
                                            checked={localvar?.Delivery?.Status}
                                            id={localvar?.Delivery?.day}
                                            onChange={(e) =>
                                              click(e, null, "Delivery")
                                            }
                                          />
                                          <label for={localvar?.day}></label>
                                        </div>
                                        <p className="mvv_acceptorder">
                                          {localvar?.Delivery?.Status
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </div>
                                    {localvar?.Timing?.Status&&
                                    <div className="subact_deliver_singles">
                                      <div className="rightsec secondary_togtab">
                                        <div className="togswit togswit_redgreen me-2">
                                          <input
                                            type="checkbox"
                                            checked={localvar?.Timing?.hours}
                                            id={`${localvar?.Timing?.day}1`}
                                            onChange={(e) =>
                                              _24hours(e, "Timing")
                                            }
                                          />
                                          <label
                                            for={`${localvar?.Timing?.day}1`}
                                          ></label>
                                        </div>
                                        <p className="mvv_acceptorder">
                                          {localvar?.Timing?.hours
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </div>}
                                    <div className="subact_deliver_singles">
                                      {localvar?.Delivery?.Status &&
                                        localvar?.Delivery?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <div className="storetiming_flexing mt-2">
                                                {
                                                  <>
                                                    <input
                                                      autoComplete="off"
                                                      className="sigin_inputs timing_inputs"
                                                      type="time"
                                                      step="3600"
                                                      disabled={
                                                        localvar?.Delivery
                                                          ?.hours
                                                      }
                                                      value={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.StartTime || ""
                                                      }
                                                      name={indexs}
                                                      placeholder="10:00 AM"
                                                      id={
                                                        localvar?.Delivery?.day
                                                      }
                                                      max={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.EndTime
                                                      }
                                                      // min={"09:00"}
                                                      // max={"10:00"}

                                                      onChange={(e) =>
                                                        click(
                                                          e,
                                                          "Start",
                                                          "Delivery"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="subact_deliver_singles">
                                      {localvar?.Delivery?.Status &&
                                        localvar?.Delivery?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <div className="storetiming_flexing mt-2">
                                                {
                                                  <>
                                                    <input
                                                      autoComplete="off"
                                                      className="sigin_inputs timing_inputs"
                                                      type="time"
                                                      step="3600"
                                                      disabled={
                                                        localvar?.Delivery
                                                          ?.hours
                                                      }
                                                      value={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.EndTime || ""
                                                      }
                                                      name={indexs}
                                                      placeholder="10:00 AM"
                                                      id={
                                                        localvar?.Delivery?.day
                                                      }
                                                      min={
                                                        localvar?.Delivery
                                                          ?.timing[indexs]
                                                          ?.StartTime
                                                      }
                                                      // min={"09:00"}
                                                      // max={"10:00"}

                                                      onChange={(e) =>
                                                        click(
                                                          e,
                                                          "End",
                                                          "Delivery"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    <div className="subact_deliver_singles">
                                      {localvar?.["Delivery"]?.Status &&
                                        !localvar?.["Delivery"]?.hours &&
                                        localvar?.["Delivery"]?.timing.map(
                                          (its, indexs) => {
                                            return (
                                              <>
                                                <button
                                                  className="mmv_tabgn_plusbtn"
                                                  onClick={() =>
                                                    table(
                                                      localvar?.["Delivery"]?.day,
                                                      undefined,
                                                      "Delivery"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={require("../assets/images/icons/plusroundgreen.png")}
                                                  />
                                                </button>

                                                {indexs >= 1 && (
                                                  <button
                                                    className="mmv_tabgn_plusbtn"
                                                    onClick={() =>
                                                      table(
                                                        localvar?.["Delivery"]
                                                          ?.day,
                                                        indexs,
                                                        "Delivery"
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/icons/minusround.png")}
                                                    />
                                                  </button >
                                                )}
                                                <br />
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>:<></>
                                
                                );
                              })}
                            </div> */}
                              <Container>
                                <Row>
                                  <Col xl={12} lg={12} md={12} xs={12}>
                                    <div className="mangra_overflow_align mangra_overflow_align_new">
                                      <p className="mangra_whole_ttl">
                                        Delivery Hours Graph
                                      </p>
                                      <div className="manual_graph_whle">
                                        <div className="manualgra_xaxis_txt">
                                          <p>Day</p>
                                          <p>Midnight</p>
                                          <p>4AM</p>
                                          <p>8AM</p>
                                          <p>Lunch</p>
                                          <p>4PM</p>
                                          <p>8PM</p>
                                        </div>

                                        {console.log(
                                          "kjkfsjlkfskjfhksxjcsdjkjd",
                                          DayTimings,
                                          SelectedTime
                                        )}

                                        {Object.keys(DayTimings)?.map((val) => {
                                          return (
                                            DayTimings?.[val]?.Timing
                                              ?.Status && (
                                              <div className="manual_graph_single">
                                                <p className="manualgra_yaxis_txt">
                                                  {val.substring(0, 3)}
                                                </p>
                                                {HourMinuteDisplay?.Hours?.map(
                                                  (hour) => {
                                                    return (
                                                      <div className="single_square">
                                                        {HourMinuteDisplay?.Minutes?.map(
                                                          (minute) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  DayTimings?.[
                                                                    val
                                                                  ]?.Delivery
                                                                    ?.timing
                                                                    ?.length > 0
                                                                    ? DayTimings?.[
                                                                        val
                                                                      ]?.Delivery?.timing?.some(
                                                                        (val) =>
                                                                          HourMinutetoTime(
                                                                            hour,
                                                                            minute *
                                                                              15
                                                                          ) >=
                                                                            HourMinutetoTime(
                                                                              val?.StartHour,
                                                                              val?.StartMinute,
                                                                              "start"
                                                                            ) &&
                                                                          HourMinutetoTime(
                                                                            hour,
                                                                            minute *
                                                                              15
                                                                          ) <=
                                                                            HourMinutetoTime(
                                                                              val?.EndHour,
                                                                              val?.EndMinute,
                                                                              "end"
                                                                            ) &&
                                                                          (minute *
                                                                            15 !==
                                                                            60 ||
                                                                            (minute *
                                                                              15 ==
                                                                              60 &&
                                                                              hour !=
                                                                                Number(
                                                                                  val?.StartHour
                                                                                ) -
                                                                                  1))
                                                                      )
                                                                      ? "timing_div"
                                                                      : "test111"
                                                                    : "test111"
                                                                }
                                                                // onClick={() => {
                                                                //   TimeChoose(
                                                                //     val,
                                                                //     minute * 15 ==
                                                                //       60
                                                                //       ? hour + 1
                                                                //       : hour,
                                                                //     minute * 15 ==
                                                                //       60
                                                                //       ? 0
                                                                //       : minute *
                                                                //           15,
                                                                //     "Delivery"
                                                                //   );
                                                                // }}
                                                              ></div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )
                                          );
                                        })}
                                      </div>
                                      <Row className="mt-3">
                                        <Col xs={12}>
                                          <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title checkbox_title_new">
                                                Select day(s):
                                              </p>
                                            </div>
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title checkbox_title_new ps-3">
                                                Select times:
                                              </p>
                                            </div>
                                          </div>
                                          <div className="whole_check_datepick mt-3">
                                            <div className="daysslct_ttl day_width_45">
                                              {/* <div className="daysslct_ttl"> */}
                                              {Object.keys(DayTimings)?.map(
                                                (val) => {
                                                  return (
                                                    DayTimings?.[val]?.Timing
                                                      ?.Status && (
                                                      <p className="show_days_div">
                                                        {val?.substring(0, 1)}
                                                      </p>
                                                    )
                                                  );
                                                }
                                              )}

                                              {/*  <p>M</p>
                                       <p>T</p>
                                       <p>W</p>
                                      <p>T</p>
                                      <p>F</p>
                                       <p>S</p> */}
                                              {/* </div> */}
                                            </div>
                                            <div className="title_percent_ttl">
                                              <div className="daysslct_ttl days_new_widh">
                                                <p>From</p>
                                                <p>To</p>
                                                <p className="hider_txt">
                                                  Delete
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {console.log(
                                            "sjhfjkhsfksfsfs",
                                            SelectedTime
                                          )}
                                          {SelectedTime?.Delivery?.length > 0 &&
                                            SelectedTime?.Delivery?.map(
                                              (arrind, ind) => {
                                                return (
                                                  <>
                                                    <div className="whole_check_datepick">
                                                      <div className="day_width_45">
                                                        {Object.keys(
                                                          DayTimings
                                                        )?.map((val) => {
                                                          return (
                                                            DayTimings?.[val]
                                                              ?.Timing
                                                              ?.Status && (
                                                              <div className="linecheck_aligned linecheck_aligned_new">
                                                                <div className="modal_checkoptions odp_check">
                                                                  <Form.Check type="checkbox">
                                                                    <Form.Check.Input
                                                                      type="checkbox"
                                                                      defaultChecked={
                                                                        SelectedTime
                                                                          ?.Delivery?.[
                                                                          ind
                                                                        ]?.[val]
                                                                      }
                                                                      isValid
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        OnChangeTime(
                                                                          e,
                                                                          ind,
                                                                          val,
                                                                          "checkbox",
                                                                          "Delivery"
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Form.Check>
                                                                </div>
                                                              </div>
                                                            )
                                                          );
                                                        })}
                                                      </div>
                                                      <div className="dateselect_dtls">
                                                        <Datetime
                                                          viewMode={"time"}
                                                          value={
                                                            SelectedTime
                                                              ?.Delivery?.[ind]
                                                              ?.StartTimeshow
                                                          }
                                                          // onViewModeChange={'time'}
                                                          dateFormat={false}
                                                          onChange={(e) => {
                                                            console.log(
                                                              "kdfjsfkdsfsd",
                                                              e._d
                                                            );
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "StartTime",
                                                              "time",
                                                              "Delivery"
                                                            );
                                                          }}
                                                        />

                                                        <Datetime
                                                          viewMode={"time"}
                                                          dateFormat={false}
                                                          value={
                                                            SelectedTime
                                                              ?.Delivery?.[ind]
                                                              ?.EndTimeshow
                                                          }
                                                          onChange={(e) =>
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "EndTime",
                                                              "time",
                                                              "Delivery"
                                                            )
                                                          }
                                                        />
                                                        {/* <img
                                                          className="order_trashed_ic"
                                                          src={require("../assets/images/icons/trash.png")}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Delivery"
                                                            )
                                                          }
                                                        /> */}
                                                        <img
                                                          className="order_trashed_ic"
                                                          src={delIcn}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Delivery"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <span className="error-msg">
                                                        {
                                                          Error?.[
                                                            "SelectedTimeDelivery" +
                                                              ind
                                                          ]
                                                        }
                                                      </span>
                                                    </div>

                                                    <div></div>
                                                  </>
                                                );
                                              }
                                            )}

                                          <div className="triple_copybtns">
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                AddNewTiming("Delivery")
                                              }
                                            >
                                              Add Time
                                            </button>

                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings(
                                                  "Delivery",
                                                  "Pickup"
                                                )
                                              }
                                            >
                                              Copy This to Pickup
                                            </button>
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings(
                                                  "Delivery",
                                                  "Timing"
                                                )
                                              }
                                            >
                                              Copy This to Timing
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col xl={12} lg={12} md={12} xs={12}>
                                    <div className="mangra_overflow_align mangra_overflow_align_new">
                                      <p className="mangra_whole_ttl">
                                        PickUp Hours Graph
                                      </p>
                                      <div className="manual_graph_whle">
                                        <div className="manualgra_xaxis_txt">
                                          <p>Day</p>
                                          <p>Midnight</p>
                                          <p>4AM</p>
                                          <p>8AM</p>
                                          <p>Lunch</p>
                                          <p>4PM</p>
                                          <p>8PM</p>
                                        </div>

                                        {Object.keys(DayTimings)?.map((val) => {
                                          return (
                                            DayTimings?.[val]?.Timing
                                              ?.Status && (
                                              <div className="manual_graph_single">
                                                <p className="manualgra_yaxis_txt">
                                                  {val.substring(0, 3)}
                                                </p>
                                                {HourMinuteDisplay?.Hours?.map(
                                                  (hour) => {
                                                    return (
                                                      <div className="single_square">
                                                        {HourMinuteDisplay?.Minutes?.map(
                                                          (minute) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  DayTimings?.[
                                                                    val
                                                                  ]?.Pickup
                                                                    ?.timing
                                                                    ?.length > 0
                                                                    ? DayTimings?.[
                                                                        val
                                                                      ]?.Pickup?.timing?.some(
                                                                        (val) =>
                                                                          HourMinutetoTime(
                                                                            hour,
                                                                            minute *
                                                                              15
                                                                          ) >=
                                                                            HourMinutetoTime(
                                                                              val?.StartHour,
                                                                              val?.StartMinute,
                                                                              "start"
                                                                            ) &&
                                                                          HourMinutetoTime(
                                                                            hour,
                                                                            minute *
                                                                              15
                                                                          ) <=
                                                                            HourMinutetoTime(
                                                                              val?.EndHour,
                                                                              val?.EndMinute,
                                                                              "end"
                                                                            ) &&
                                                                          (minute *
                                                                            15 !==
                                                                            60 ||
                                                                            (minute *
                                                                              15 ==
                                                                              60 &&
                                                                              hour !=
                                                                                Number(
                                                                                  val?.StartHour
                                                                                ) -
                                                                                  1))
                                                                      )
                                                                      ? "timing_div"
                                                                      : "test111"
                                                                    : "test111"
                                                                }
                                                                onClick={() => {
                                                                  TimeChoose(
                                                                    val,
                                                                    minute *
                                                                      15 ==
                                                                      60
                                                                      ? hour + 1
                                                                      : hour,
                                                                    minute *
                                                                      15 ==
                                                                      60
                                                                      ? 0
                                                                      : minute *
                                                                          15,
                                                                    "Pickup"
                                                                  );
                                                                }}
                                                              ></div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )
                                          );
                                        })}
                                      </div>
                                      <Row className="mt-3">
                                        <Col xs={12}>
                                          <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title checkbox_title_new">
                                                Select day(s):
                                              </p>
                                            </div>
                                            <div className="title_percent_ttl title_percent_ttl_new">
                                              <p className="checkbox_title checkbox_title_new ps-3">
                                                Select times:
                                              </p>
                                            </div>
                                          </div>
                                          <div className="whole_check_datepick mt-3">
                                            {/* <div className="title_percent_ttl"> */}

                                            <div className="daysslct_ttl day_width_45">
                                              {Object.keys(DayTimings)?.map(
                                                (val) => {
                                                  return (
                                                    DayTimings?.[val]?.Timing
                                                      ?.Status && (
                                                      <p className="show_days_div">
                                                        {val?.substring(0, 1)}
                                                      </p>
                                                    )
                                                  );
                                                }
                                              )}
                                            </div>

                                            {/* </div> */}
                                            <div className="title_percent_ttl">
                                              <div className="daysslct_ttl">
                                                <p>From</p>
                                                <p className="calendar_totxt">
                                                  To
                                                </p>
                                                <p className="hider_txt">
                                                  Delete
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {SelectedTime?.Pickup?.length > 0 &&
                                            SelectedTime?.Pickup?.map(
                                              (arrind, ind) => {
                                                return (
                                                  <>
                                                    <div className="whole_check_datepick">
                                                      <div className="day_width_45">
                                                        {Object.keys(
                                                          DayTimings
                                                        )?.map((val) => {
                                                          return (
                                                            DayTimings?.[val]
                                                              ?.Timing
                                                              ?.Status && (
                                                              <div className="linecheck_aligned linecheck_aligned_new">
                                                                <div className="modal_checkoptions odp_check">
                                                                  <Form.Check type="checkbox">
                                                                    <Form.Check.Input
                                                                      type="checkbox"
                                                                      defaultChecked={
                                                                        SelectedTime
                                                                          ?.Pickup?.[
                                                                          ind
                                                                        ]?.[val]
                                                                      }
                                                                      isValid
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        OnChangeTime(
                                                                          e,
                                                                          ind,
                                                                          val,
                                                                          "checkbox",
                                                                          "Pickup"
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Form.Check>
                                                                </div>
                                                              </div>
                                                            )
                                                          );
                                                        })}
                                                      </div>

                                                      <div className="dateselect_dtls">
                                                        <Datetime
                                                          viewMode={"time"}
                                                          value={
                                                            SelectedTime
                                                              ?.Pickup?.[ind]
                                                              ?.StartTimeshow
                                                          }
                                                          dateFormat={false}
                                                          // onViewModeChange={'time'}
                                                          onChange={(e) => {
                                                            console.log(
                                                              "kdfjsfkdsfsd",
                                                              e._d
                                                            );
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "StartTime",
                                                              "time",
                                                              "Pickup"
                                                            );
                                                          }}
                                                        />

                                                        <Datetime
                                                          viewMode={"time"}
                                                          dateFormat={false}
                                                          value={
                                                            SelectedTime
                                                              ?.Pickup?.[ind]
                                                              ?.EndTimeshow
                                                          }
                                                          onChange={(e) =>
                                                            OnChangeTime(
                                                              e,
                                                              ind,
                                                              "EndTime",
                                                              "time",
                                                              "Pickup"
                                                            )
                                                          }
                                                        />
                                                        {/* <img
                                                          className="order_trashed_ic"
                                                          src={require("../assets/images/icons/trash.png")}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Pickup"
                                                            )
                                                          }
                                                        /> */}
                                                        <img
                                                          className="order_trashed_ic"
                                                          src={delIcn}
                                                          onClick={() =>
                                                            RemoveTime(
                                                              ind,
                                                              "Pickup"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <span className="error-msg">
                                                        {
                                                          Error?.[
                                                            "SelectedTimePickup" +
                                                              ind
                                                          ]
                                                        }
                                                      </span>
                                                    </div>

                                                    <div></div>
                                                  </>
                                                );
                                              }
                                            )}
                                          <div className="triple_copybtns">
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                AddNewTiming("Pickup")
                                              }
                                            >
                                              Add Time
                                            </button>

                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings(
                                                  "Pickup",
                                                  "Delivery"
                                                )
                                              }
                                            >
                                              Copy This to Delivery
                                            </button>
                                            <button
                                              className="blue_btn mangra_addtime mangra_addtime_new"
                                              type="button"
                                              onClick={() =>
                                                CopyTimings("Pickup", "Timing")
                                              }
                                            >
                                              Copy This to Timing
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          ) : (
                            <></>
                          )}
                          {subactiveTab == "DeliveryTimeCharge" ? (
                            <div className="ffd_table px-3 pb-3">
                              <div className="subact_tabled_whole">
                                <div className="subact_delivery_whole">
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">Day</p>
                                  </div>
                                  {/* <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Status</p>
                                </div> */}

                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">
                                      Time in hours
                                    </p>
                                  </div>
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl">Charge</p>
                                  </div>
                                  {/* <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Currency</p>
                                </div> */}
                                  <div className="subact_deliver_singles">
                                    <p className="subact_tabled_ttl"> Action</p>
                                  </div>
                                </div>
                                {Object.values(DayTimings)?.map((localvar) => {
                                  return (
                                    DayTimings[localvar?.Delivery?.day]?.Timing
                                      ?.timing.length > 0 &&
                                    DayTimings[
                                      localvar?.Delivery?.day
                                    ]?.Timing?.timing?.some(
                                      (is) =>
                                        is.StartTime != "" && is.EndTime != ""
                                    ) && (
                                      <div className="subact_delivery_whole">
                                        <div className="subact_deliver_singles">
                                          <p>{localvar?.day}</p>
                                        </div>
                                        {/* <div className="subact_deliver_singles">
                                      <div className="rightsec secondary_togtab">
                                        <div className="togswit togswit_redgreen me-2">
                                          <input
                                            type="checkbox"
                                            checked={
                                              localvar?.DeliveryTimeCharge
                                                ?.Status
                                            }
                                            id={
                                              localvar?.DeliveryTimeCharge?.day
                                            }
                                            onChange={(e) =>
                                              click(
                                                e,
                                                null,
                                                "DeliveryTimeCharge"
                                              )
                                            }
                                          />
                                          <label
                                            for={
                                              localvar?.DeliveryTimeCharge?.day
                                            }
                                          ></label>
                                        </div>
                                        <p className="mvv_acceptorder">
                                          {localvar?.DeliveryTimeCharge?.Status
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </div> */}

                                        <div className="subact_deliver_singles">
                                          {localvar?.DeliveryTimeCharge
                                            ?.Status &&
                                            localvar?.DeliveryTimeCharge?.AfterPreparation.map(
                                              (its, indexs) => {
                                                return (
                                                  <div className="storetiming_flexing mt-2">
                                                    {
                                                      <>
                                                        <input
                                                          className="sigin_inputs timing_inputs"
                                                          type="number"
                                                          max={24}
                                                          value={
                                                            localvar
                                                              ?.DeliveryTimeCharge
                                                              ?.AfterPreparation[
                                                              indexs
                                                            ]
                                                              ?.AfterPreparation ||
                                                            ""
                                                          }
                                                          name={indexs}
                                                          placeholder="10"
                                                          id={
                                                            localvar
                                                              ?.DeliveryTimeCharge
                                                              ?.day
                                                          }
                                                          onChange={(e) =>
                                                            // console.log("dasdasd",e.target?.value)
                                                            click(
                                                              e,
                                                              "AfterPreparation",
                                                              "DeliveryTimeCharge"
                                                            )
                                                          }
                                                        />
                                                      </>
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>

                                        <div className="subact_deliver_singles">
                                          {localvar?.DeliveryTimeCharge
                                            ?.Status &&
                                            localvar?.DeliveryTimeCharge?.AfterPreparation.map(
                                              (its, indexs) => {
                                                console.log("hgfhg", indexs);
                                                return (
                                                  <div className="storetiming_flexing mt-2">
                                                    {
                                                      <>
                                                        <input
                                                          className="sigin_inputs timing_inputs"
                                                          type="number"
                                                          max={24}
                                                          value={
                                                            localvar
                                                              ?.DeliveryTimeCharge
                                                              ?.AfterPreparation[
                                                              indexs
                                                            ]?.ChargeAmount ||
                                                            ""
                                                          }
                                                          name={indexs}
                                                          placeholder="1,2..."
                                                          id={
                                                            localvar
                                                              ?.DeliveryTimeCharge
                                                              ?.day
                                                          }
                                                          onChange={(e) =>
                                                            click(
                                                              e,
                                                              "ChargeAmount",
                                                              "DeliveryTimeCharge"
                                                            )
                                                          }
                                                        />
                                                      </>
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>

                                        {/* <div className="subact_deliver_singles">
                                      {localvar?.DeliveryTimeCharge?.Status &&
                                        localvar?.DeliveryTimeCharge?.AfterPreparation.map(
                                          (its, indexs) => {
                                            return (
                                              <div className="storetiming_flexing mt-2">
                                                {
                                                  <>
                                                  
                                                    <input
                                                      className="sigin_inputs timing_inputs"
                                                      type="text"
                                                      max={24}
                                                      value={
                                                        localvar
                                                          ?.DeliveryTimeCharge
                                                          ?.AfterPreparation[
                                                          indexs
                                                        ]?.Currency || ""
                                                      }
                                                      name={indexs}
                                                      placeholder="inr"
                                                      id={
                                                        localvar
                                                          ?.DeliveryTimeCharge
                                                          ?.day
                                                      }
                                                      onChange={(e) =>
                                                        click(
                                                          e,
                                                          "Currency",
                                                          "DeliveryTimeCharge"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                    </div> */}

                                        <div className="subact_deliver_singles">
                                          {localvar["DeliveryTimeCharge"]
                                            ?.Status &&
                                            localvar[
                                              "DeliveryTimeCharge"
                                            ]?.AfterPreparation.map(
                                              (its, indexs) => {
                                                return (
                                                  <>
                                                    <button
                                                      className="mmv_tabgn_plusbtn"
                                                      onClick={() =>
                                                        table(
                                                          localvar?.[
                                                            "DeliveryTimeCharge"
                                                          ]?.day,
                                                          undefined,
                                                          "DeliveryTimeCharge"
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={require("../assets/images/icons/plusroundgreen.png")}
                                                      />
                                                    </button>

                                                    {indexs >= 1 && (
                                                      <button
                                                        className="mmv_tabgn_plusbtn"
                                                        onClick={() =>
                                                          table(
                                                            localvar?.[
                                                              "DeliveryTimeCharge"
                                                            ]?.day,
                                                            indexs,
                                                            "DeliveryTimeCharge"
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/icons/minusround.png")}
                                                        />
                                                      </button>
                                                    )}
                                                    <br />
                                                  </>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                              {/* <button
                              className="blue_btn mdv_update"
                              onClick={() => onsubmit()}
                            >
                              Update Time
                            </button> */}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                      {activeTab == "operationtime" && (
                        <button
                          className="blue_btn mdv_update"
                          onClick={() => onsubmit()}
                          disabled={disabledstatus?.Submit}
                        >
                          Update Time
                        </button>
                      )}
                      {/* end of operation time details */}

                      {/* notification settings details */}
                      {activeTab == "notification" && (
                        <>
                          <div className="acnt_notify_activity">
                            <p className="acnt_notify_ttl">Activity</p>
                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <hr className="acnt_notify_hr" />
                          </div>

                          <div className="acnt_notify_activity">
                            <p className="acnt_notify_ttl">System</p>
                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            <div className="acnt_nofity_singledtls">
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn5" />
                                <label for="toggle-btn5"></label>
                              </div>
                              <p className="acnt_notify_hints">
                                Lorem Ipsum has been the industry's standard
                                dummy text ever since the 1500s.
                              </p>
                            </div>

                            {/* <button className="blue_btn mdv_update">
                            Save Changes
                          </button> */}
                          </div>
                        </>
                      )}

                      {/* end of notification settings details */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={(e) => {
            set_shop({
              ..._shop,
              ...{
                Address: {
                  ..._shop?.Address,
                  ...(e?.Address ? e?.Address : {}),
                },
              },
            });
          }}
          Address={Address}
        />
      )}
    </>
  );
}

export default Account;
