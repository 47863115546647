import React, { useState, useEffect } from 'react'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import useAxiosFile from '../actions/useaxios';
import { DateTimeForm, isEmpty } from '../actions/common';
import config from '../actions/config'
// import orders_img from "../assets/images/accountbanner.png"


function createData(no, orderid, name, type, date, price) {
  return { no, orderid, name, type, date, price };
}



const latestorders = [
  createData(1, "#1593195485", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(2, "#1593195486", "Joh Doe", "Delivery", "1/17/2022", "$23.67"),
  createData(3, "#1593195487", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(4, "#1593195488", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(5, "#1593195489", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),


];

function Dashboard() {

  const { payload, toggle } = useSelector((state)=>state.seller_panel.User)
  

  const [chartdata, setChartData] = useState({});
  const [newdata, setnew] = useState();
  const [sellershop, setSellershop] = useState({})


  const axiosfile = useAxiosFile()


  const navigate = useNavigate();

  useEffect(() => {

    sellerDetails()
  }, [payload?._id])

  const sellerDetails = async () => {
    if(payload?._id){
    var Resp = await axiosfile.sellerDetails({
      SellerId:payload?._id
    })
    setSellershop(Resp)
    console.log('RespRespResp',Resp)
    if (!isEmpty(Resp?.data)) {
      let datas = Resp?.data;
      let ordergraph = [
        datas?.TotalDelivery,
        datas?.TotalPickup,
        datas?.TotalCancel,
      ];
      let userordergraph = [
        { name: "Orders", data: datas.OrderOnDays },
        { name: "Views", data: datas.ViewOnDays },
      ];

      let overviewgraphtime = [];
      let overviewgraphorders = { name: "Orders", data: [] };
      for (let i = 0; i <= 6; i++) {
        let sum = 0;
        if (i > 0) {
          for (let j = i == 1 ? 1 : i * 4 - 4 + 1; j <= i * 4; j++) {
            sum =
              sum +
              (Object.keys(datas?.Ordertoday)?.some((val) => {
                return val == j;
              })
                ? datas.Ordertoday[j].orders
                : 0);
          }
        }
        overviewgraphorders.data.push(sum / 4);
        overviewgraphtime.push(String(i * 4));
      }
      // overviewgraphorders.data.slice(1,7)

      let currdata = data;
      currdata.options.xaxis.categories = overviewgraphtime;
      console.log('kshfksfsjsfs',datas,ordergraph)
      setChartData({
        Ordergraph: ordergraph,
        TotalOrders: datas.TotalOrders,
        UserOrders: userordergraph,
        OverviewGraphtime: overviewgraphtime,
        OverViewGraphOrder: [overviewgraphorders],
        TopProducts: datas.TopProducts,
        LatestOrders: datas.LatestOrders,
      });
      SetData({ ...data, ...currdata });
      setnew(Resp.data);
    }
  }
  }
  console.log("edfsdfdsrgte", sellershop,chartdata,data,newdata);

  const [sellerdata, setSellerdata] = useState([
    {
      seller_img: require('../assets/images/tablefood.png'),
      seller_name: "Toasted Chocolate-Hazelnut Sandwiches",
      seller_desc: "$15",
      rating: "4.9",
      ratingcount: "1692 Sales"

    },
    {
      seller_img: require('../assets/images/tablefood.png'),
      seller_name: "Classic Cheese Pizza",
      seller_desc: "$15",
      rating: "3.7",
      ratingcount: "1692 Sales"

    },
    {
      seller_img: require('../assets/images/tablefood.png'),
      seller_name: "Spicy Szechuan Noodles with Garlic Chili Oil",
      seller_desc: "$15",
      rating: "4.5",
      ratingcount: "1692 Sales"

    },

    {
      seller_img: require('../assets/images/tablefood.png'),
      seller_name: "Korean Crispy Fried Chicken",
      seller_desc: "$15",
      rating: "4.3",
      ratingcount: "1692 Sales"

    },

  ])
  var [data, SetData] = useState({
    
    series: [{
      name: "orders",
      data: [10, 41, 25, 41, 29, 42, 39]
    }],
    options: {
      chart: {
        height: 350,

        type: 'line',
        zoom: {
          enabled: false
        },

      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        curve: 'smooth',
        width: 2,
      },
      // title: {
      //   text: 'Product Trends by Month',
      //   align: 'left'
      // },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['11', '12', '13', '14', '15', '16', '17'],
      },
      yaxis: {
        type: 'Lists',
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep"]
      },

    },
  });

  const datas = {

    series: [70, 20, 10],

    options: {
      labels: ['Delivery', 'Pickup', 'Cancel'],
      colors: ['#06038D', '#EE9D26', '#EB5757'],
      chart: {
        type: 'donut',
        height: 150,
        width: '100%',

      },
      legend: {
        // show: false,
        position: 'bottom'

      },
      fill: {
        colors: ['#06038D', '#EE9D26', '#EB5757']
      },


      //  dataLabels: {
      //         enabled: false,},


      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },

          legend: {
            position: 'bottom'
          }
        }
      }]
    },

  };
  const datas1 = {
    series: [{
      name: 'Orders',
      data: [44, 55, 57, 56, 61, 58, 63],

    }, {
      name: 'Views',
      data: [76, 85, 101, 98, 87, 105, 91]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: "100%"
      },
      colors: ['#06038D', '#EE9D26', '#EB5757'],
      legend: {
        position: 'top'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded',
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      },
      yaxis: {

      },
      fill: {
        opacity: 1
      },
      fill: {
        colors: ['#06038D', '#EE9D26', '#EB5757']
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },


  };
  return (
    <div>
      <div className='innerpages'>
        <div className='dashboard_pages'>
          <Header />
          <div className='container custom_container'>
            <div className='row'>
              <div className='innerpages_wid'>
                <Sidebar />
                <div className={toggle ? 'content-sec-inner right_widthset' : 'content-sec-inner'}>
                  <section id="content-wrapper">
                    <div class="row">
                      <div className='top_title_sec'>
                        <div>
                          <p className='page_title'>Dashboard</p>
                        </div>
                        {/* <div className='rightsec'>
        <p>Delivery Services 
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='overall_card_sec'>
                        <div className='card overall_card'>
                          {/* <img src={orders_img} className='img-fluid' width="40" height="40" alt='order_img'/> */}
                          <p className='count'>{sellershop?.data?.TotalOrders ? sellershop?.data?.TotalOrders : 0}</p>
                          <p className='count_name'>Orders</p>
                        </div>
                        <div className='card overall_card'>
                          {/* <img src={orders_img} className='img-fluid' width="40" height="40" alt='order_img'/> */}
                          <p className='count'>{newdata?.Earnings?.TotalEarnings?(newdata?.Earnings?.TotalEarnings).toFixed(2):0}</p>
                          <p className='count_name'>Orders Value</p>
                        </div>
                        {/* <div className='card overall_card'>
                          <p className='count'>{sellershop?.data?.Shipping[0]?.TotalShipping?sellershop?.data?.Shipping[0]?.TotalShipping:0}</p>
                          <p className='count_name'>Shipping</p>
                        </div>
                        <div className='card overall_card'>
                          <p className='count'>{sellershop?.data?.TotalVendors?sellershop?.data?.TotalVendors:0}</p>
                          <p className='count_name'>Vendors</p>
                        </div>
                        <div className='card overall_card'>
                          <p className='count'>{sellershop?.data?.TotalDrivers ? sellershop?.data?.TotalDrivers : 0}</p>
                          <p className='count_name'>Drivers</p>
                        </div> */}
                      </div>
                    </div>
                    <div className='mid_sec_graph mt-4'>
                      <div className='row'>
                        {console.log('ksjhdsfjs',sellershop,chartdata,data)}
                        <div className='col-lg-3 mb-4'>
                          <div className='graph_card '>
                            <div className='top_title'>
                              <p className='orders_name'>Order's Overview</p>
                              {/* <p className='orders_count'>25290</p> */}
                            </div>
                            <div id="chart">
                            {chartdata?.OverViewGraphOrder && (
                              <ReactApexChart
                              options={data.options}
                              series={
                                chartdata?.OverViewGraphOrder
                                  ? chartdata?.OverViewGraphOrder
                                  : data.series
                              }
                              type="line"
                              height={280}
                            />)}
                            </div>

                          </div>
                        </div>
                        {
                          (chartdata?.Ordergraph?.length > 0 &&
                          chartdata?.Ordergraph?.some((test)=>!isEmpty(test)))&&
                        <div className='col-lg-3 mb-4'>
                          <div className='graph_card'>
                            <div className='top_title'>
                              <p className='orders_name'>Orders</p>
                              <p className='orders_count'>{sellershop?.data?.TotalOrders}</p>
                            </div>
                            
                            <div id="chart1">
                              {console.log('jsdhjsfgsfs',chartdata?.Ordergraph)}
                              
                                <ReactApexChart
                                options={datas.options}
                                series={
                                  chartdata?.Ordergraph
                                    ? chartdata.Ordergraph
                                    : datas.series
                                }
                                type="donut"
                                height={450}
                              />
                            
                            </div>

                          </div>
                        </div>}
                        <div className={(chartdata?.Ordergraph?.length > 0&&chartdata?.Ordergraph?.some((test)=>!isEmpty(test)))?'col-lg-6 last mb-4':'col-lg-9 last mb-4'}>
                          <div className='graph_card'>
                            <div className='top_title'>
                              <p className='orders_name'>User/orders</p>

                            </div>
                            <div id="chart2">
                            <ReactApexChart
                                options={datas1.options}
                                series={
                                  chartdata?.UserOrders
                                    ? chartdata?.UserOrders
                                    : datas1.series
                                }
                                type="bar"
                                height={295}
                              />
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>





                    <div className='bottom_sec mt-4'>
                      <div className='row'>
                        <div className='col-lg-5 mb-3 mb-lg-0'>
                          <div className='dash_table h-100'>
                            <div className='top_section'>
                              <div>
                                <p className='title'>Top Selling Products</p>
                              </div>
                              <div>
                                <Link className="table_link" to="/menumanager">See All</Link>
                              </div>
                            </div>
                            <ul className='seller_list'>
                              {console.log('kjshjfskfs',chartdata)}
                            {chartdata?.TopProducts?.length > 0 ?
                                chartdata?.TopProducts?.map((e) => (
                                  <li>
                                    <div className="leftsec_seller">
                                      <div>
                                        <img
                                          src={
                                            e?.ProductDetail?.ProductImage
                                              ? `${config.IMG_URL}/Product/${e?.ProductDetail?.ProductImage}`
                                              : require('../assets/images/tablefood.png')
                                          }
                                          className="seller_img"
                                          alt="seller_img"
                                        />
                                      </div>
                                      <div>
                                        <p className="name">
                                          {e?.ProductDetail?.ProductName}
                                        </p>
                                        <p className="desc">
                                          {e?.ProductDetail?.ProductDescription ? (e?.ProductDetail?.ProductDescription?.length > 10 ? e?.ProductDetail?.ProductDescription?.substring(0,10)+'...' : e?.ProductDetail?.ProductDescription ) :""}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="right_sec_seller">
                                      <p className="rating">
                                        <img src={require('../assets/images/Star.png')} alt="star" />
                                        {e?.Rating}{" "}
                                      </p>
                                      <p className="rat_count">{e?.Rating}</p>
                                    </div>
                                  </li>
                                ))
                              :
                              <p className='text-center'>No data</p>
                              }
                            </ul>
                          </div>
                        </div>
                        <div className='col-lg-7'>
                          <div className='dash_table h-100'>
                            <div className='top_section'>
                              <div>
                                <p className='title'>Latest Orders</p>
                              </div>
                              <div>
                                <Link className="table_link" to="/orders">See All</Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>No.</TableCell>
                                      <TableCell align="center">Order ID</TableCell>
                                      <TableCell align="center">Name</TableCell>
                                      <TableCell align="center">Type</TableCell>
                                      <TableCell align="center">Date</TableCell>
                                      <TableCell align="center">Price</TableCell>
                                      <TableCell align="center"></TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {chartdata?.LatestOrders?.length > 0 ?
                                      chartdata?.LatestOrders?.map(
                                        (latestorders, ind) => (
                                          <TableRow
                                            key={latestorders.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {ind + 1}
                                            </TableCell>
                                            <TableCell align="center">
                                              {latestorders.OrderNumber}
                                            </TableCell>
                                            <TableCell align="center">
                                              {latestorders?.UserId?.FirstName}
                                            </TableCell>
                                            <TableCell align="center">
                                              {latestorders.OrderType}
                                            </TableCell>
                                            <TableCell align="center">
                                              {DateTimeForm(latestorders.createdAt,true)}
                                            </TableCell>
                                            <TableCell
                                              className="price_clr"
                                              align="center"
                                            >
                                              {latestorders?.ShopId?.CurrencySymbol} {latestorders?.Price?.PayAmount}
                                              
                                             
                                            </TableCell>
                                            <TableCell align="center">
                                            <i
                                                class="fa fa-angle-right cursor_pointer_hand"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  navigate("/orders")
                                                }
                                              ></i>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )
                                    :  <TableCell align="center" colSpan={7}>
                                      <p>No Data</p></TableCell>
                                    }
                                    {/* {latestorders.map((latestorders) => (
                                      <TableRow
                                        key={latestorders.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {latestorders.no}
                                        </TableCell>
                                        <TableCell align="center">{latestorders.orderid}</TableCell>
                                        <TableCell align="center">{latestorders.name}</TableCell>
                                        <TableCell align="center">{latestorders.type}</TableCell>
                                        <TableCell align="center">{latestorders.date}</TableCell>
                                        <TableCell className='price_clr' align="center">{latestorders.price} <i class="fa fa-angle-right" aria-hidden="true"></i></TableCell>

                                      </TableRow>
                                    ))} */}
                                  </TableBody>
                                </Table>
                              </TableContainer>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='w-100'>
                      <Datetime />
                    </div> */}
                  </section>
                </div>
              </div>

            </div>
          </div>




        </div>
      </div>

    </div>
  )
}

export default Dashboard