import React, { useEffect, useMemo, useRef, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import useAxiosFile from "../../actions/useaxios";
import config from "../../actions/config";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import { useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiRadioCircle } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import {
  MdCheckBoxOutlineBlank,
  MdOutlineArrowDropDownCircle,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import ass from "../../assets/images/sa.png";

const initialstate = {
  ProductName: "",
  Quantity: 0,
  Price: "",
  Ingredients: "",
  AdditionalInfo: [],
  ProductImage: "",
  ProductDescription: "No Data Available",
  variants: "",
  SellerCategory: "",
  ShopId: "",
  ProductImageMultiple: null,
};
function AddProduct() {
  // google form states

  const alladdvariant = () => {
    setForm({
      ...form,
      ...{
        ["AdditionalInfo"]: [
          ...form?.AdditionalInfo,
          { type: "", values: "", question: "" },
        ],
      },
    });
  };

  const addvariant = (index, value, targevalue, data, innerindex) => {
    setForm({
      ...form,
      ...{
        ["AdditionalInfo"]: [
          ...form?.AdditionalInfo.slice(0, index),
          data == "question"
            ? {
                ...form["AdditionalInfo"][index],
                ...{
                  type: form["AdditionalInfo"][index]["type"],
                  question: targevalue,
                  values: form["AdditionalInfo"][index]["values"],
                },
              }
            : data == "values"
            ? {
                ...form["AdditionalInfo"][index],
                ...{
                  type: form["AdditionalInfo"][index]["type"],
                  question: form["AdditionalInfo"][index]["question"],
                  values: targevalue,
                },
              }
            : data == "value" || data == "price"
            ? {
                ...form["AdditionalInfo"][index],
                ...{
                  type: form["AdditionalInfo"][index]["type"],
                  question: form["AdditionalInfo"][index]["question"],
                  values: [
                    ...form["AdditionalInfo"][index]["values"].slice(
                      0,
                      innerindex
                    ),
                    {
                      ...form["AdditionalInfo"][index]["values"][innerindex],
                      ...{
                        value:
                          data == "value"
                            ? targevalue
                            : form["AdditionalInfo"][index]["values"][
                                innerindex
                              ]["value"],
                        price:
                          data == "price"
                            ? targevalue
                            : form["AdditionalInfo"][index]["values"][
                                innerindex
                              ]["price"],
                      },
                    },
                    ...form["AdditionalInfo"][index]["values"].slice(
                      innerindex + 1,
                      form["AdditionalInfo"][index]["values"]
                    ),
                  ],
                },
              }
            : {
                ...form["AdditionalInfo"][index],
                ...{
                  type: form["AdditionalInfo"][index]["type"],
                  question: form["AdditionalInfo"][index]["question"],
                  values:
                    value == "Shortanswer" || value == "Paragraph"
                      ? {
                          ...form["AdditionalInfo"][index]["values"],
                          ...{ value: "", price: "" },
                        }
                      : [
                          ...form["AdditionalInfo"][index]["values"],
                          { value: "", price: "" },
                        ],
                },
              },
          ...form?.AdditionalInfo.slice(index + 1, form?.AdditionalInfo.length),
        ],
      },
    });
  };

  const removevariant = (type, innerindex, index) => {
    console.log(
      "dfghjkl;kjhgfdsrfjdmas",
      form["AdditionalInfo"][index]["values"].length,
      innerindex,
      index,
      ...form["AdditionalInfo"][index]["values"].slice(0, innerindex),
      ...form["AdditionalInfo"][index]["values"].slice(
        innerindex + 1,
        form["AdditionalInfo"][index]["values"].length
      )
    );
    setForm({
      ...form,
      ...{
        ["AdditionalInfo"]: innerindex
          ? [
              ...form?.AdditionalInfo.slice(0, index),
              {
                ...form["AdditionalInfo"][index],
                ...{
                  type: form["AdditionalInfo"][index]["type"],
                  question: form["AdditionalInfo"][index]["question"],
                  values: [
                    // ...form['AdditionalInfo'][index]['values'],
                    ...form["AdditionalInfo"][index]["values"].slice(
                      0,
                      innerindex
                    ),
                    ...form["AdditionalInfo"][index]["values"].slice(
                      innerindex + 1,
                      form["AdditionalInfo"][index]["values"].length
                    ),
                  ],
                },
              },
              ...form?.AdditionalInfo.slice(
                index + 1,
                form?.AdditionalInfo.length
              ),
            ]
          : [
              ...form?.AdditionalInfo.slice(0, index),
              ...form?.AdditionalInfo.slice(
                index + 1,
                form?.AdditionalInfo.length
              ),
            ],
      },
    });
  };

  const choosingfn = (e, index) => {
    setForm({
      ...form,
      ...{
        ["AdditionalInfo"]: [
          ...form?.AdditionalInfo.slice(0, index),
          {
            type: e.target.value,
            values: ["Shortanswer", "Paragraph"].includes(e.target.value)
              ? ""
              : [],
            question: "",
          },
          ...form?.AdditionalInfo.slice(index + 1, form?.AdditionalInfo.length),
        ],
      },
    });
  };

  const axiosFile = useAxiosFile();
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  const navigate = useNavigate();
  const location = useLocation();
  const ckeditor = useRef(null);
  const [form, setForm] = useState({});
  const [Categorys, setsubcat] = useState("");
  const { toggle } = useSelector((state) => state.seller_panel.User);

  const [error, SetError] = useState({});
  const [Id, Setid] = useState("");
  const [shop, setshop] = useState();

  useEffect(() => {
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (payload?._id) {
      if (Id) {
        geteditproduct();
      } else {
        Getshop();
        setForm(initialstate);
      }
    }
  }, [Id, payload?._id]);

  const geteditproduct = async () => {
    var sendata = {
      id: Id,
    };
    var resp = await axiosFile.getproductedit(sendata);
    setForm(resp?.data);
    Getshop(resp?.data?.ShopId);
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const OnChange = (e, ids) => {
    SetError({});
    if (e?.target?.files) {
      if (e?.target?.id == "ProductImage") {
        if (e?.target?.files[0]?.type?.includes("image")) {
          let formData = {
            ...form,
            ...{ [e?.target?.id]: e?.target?.files[0] },
          };
          setForm(formData);
        } else {
          updateToast(null, "Upload Images Only", "error");
        }
      } else if (e?.target?.id == "ProductImageMultiple") {
        if (
          e?.target?.files.length > 0 &&
          e?.target?.files.length <= 4 &&
          [...e?.target?.files].some((it) => it.type.includes("image"))
        ) {
          let formData = {
            ...form,
            ...{
              [e?.target?.id]: [...e?.target?.files].filter((it) =>
                it.type.includes("image")
              ),
            },
          };
          setForm(formData);
        }
        if (
          e?.target?.files?.length > 0 &&
          e?.target?.files.length >= 4 &&
          [...e?.target?.files].some((it) => it.type.includes("image"))
        ) {
          updateToast(null, "Add Only 4 Images", "error");
        }
        if (
          e?.target?.files?.length > 0 &&
          e?.target?.files.length <= 4 &&
          [...e?.target?.files].some((it) => !it.type.includes("image"))
        ) {
          updateToast(
            null,
            "Exept Image Other types of files will be terminated",
            "error"
          );
        }
      }
    } else {
      setForm({
        ...form,
        ...{ [e?.target?.id ?? ids]: e?.target?.value ?? e.value },
      });
    }
  };

  const Validate = () => {
    let Error = {};

    if (isEmpty(form?.ProductName)) {
      Error.ProductName = "ProductName Required";
    }
    if (isEmpty(form?.Price)) {
      Error.Price = "Price Required";
    } else if (isNaN(form.Price)) {
      Error.Price = "Price Must be a Number";
    }
    if (isEmpty(form?.SellerCategory)) {
      Error.SellerCategory = "SellerCategory Required";
    }

    if (typeof form?.ProductImage == "string" && isEmpty(form?.ProductImage)) {
      Error.ProductImage = "ProductImage Required";
    }

    if (typeof form?.ProductImage == "object" && !form?.ProductImage?.type) {
      Error.ProductImage = "ProductImage Required";
    }

    if (isEmpty(form?.ProductDescription)) {
      Error.ProductDescription = "Description Required";
    }
    if (isEmpty(form?.Quantity)) {
      Error.Quantity = "Quantity Required";
    }

    return Error;
  };

  const AddProduct = async () => {
    var load = LoadToast("Validating Form");
    let errorvalid = Validate(form);

    if (isEmpty(errorvalid)) {
      if (Id) {
        let senddata = {
          ...form,
          ...{
            ["AdditionalInfo"]: JSON.stringify(form.AdditionalInfo),
            UserId: payload?._id,
            ProductId: form._id,
            from: "edit",
          },
        };
        let Resp = await axiosFile.AddProduct(senddata);
        console.log("Resp", Resp);
        updateToast(load, Resp?.msg, Resp?.success);
        if (Resp?.data) {
          navigate("/ProductList");
          // setForm(initialstate);
        }
      } else {
        let senddata = {
          ...form,
          ...{
            ["AdditionalInfo"]: JSON.stringify(form.AdditionalInfo),
            UserId: payload?._id,
            // ProductId: form._id,
            from: "Add",
          },
        };
        let Resp = await axiosFile.AddProduct(senddata);
        if (Resp?.success == "success") {
          updateToast(load, "Product Add Succesfully ", "success");

          navigate("/ProductList");
        } else {
          updateToast(load, "Product Can't Added", "error");
        }
      }
    } else {
      updateToast(load, "fix", "error");
      SetError(errorvalid);
    }
  };

  const Getshop = async (data) => {
    var resp = await axiosFile.getshop({
      SellerId: payload?._id,
      from: "sell_add",
    });
    if (resp?.data?.length > 0) {
      setshop(resp?.data);
      setsubcat(
        data
          ? resp.data?.find((it) => it._id == data).SellerCategory
          : resp?.data[0]?.SellerCategory
      );
    }
  };

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Header />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">
                            {Id ? "Edit Product" : "Add Product"}
                          </p>
                        </div>
                        <div className="rightside"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-8">
                        <div className="sec_border">
                          <p className="cat_newcreate_ttls addproduct_ttl">
                            Product Name *
                          </p>
                          <input
                            className="sigin_inputs cat_newinput"
                            type="text"
                            placeholder="Enter Product name"
                            id="ProductName"
                            value={form?.ProductName}
                            onChange={(e) => OnChange(e)}
                          />
                          {error?.ProductName && (
                            <span className="error-msg">
                              {error?.ProductName}
                            </span>
                          )}

                          <div className="row">
                            <div className="col-lg-6 mt-3">
                              <p className="cat_newcreate_ttls addproduct_ttl">
                                Price *
                              </p>

                              <input
                                className="sigin_inputs cat_newinput"
                                type="number"
                                placeholder="Ex. $12.50"
                                id="Price"
                                value={form?.Price}
                                onChange={(e) => OnChange(e)}
                              />
                              {error?.Price && (
                                <span className="error-msg">
                                  {error?.Price}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 mt-3">
                              <p className="cat_newcreate_ttls addproduct_ttl">
                                Shop *
                              </p>
                              <Select
                                options={shop}
                                value={{
                                  label: shop?.find(
                                    (it) => it._id == form?.ShopId
                                  )?.BrandName,
                                  value: form?.ShopId ?? form?.ShopId,
                                }}
                                styles={stylesgraybg}
                                // value={form?.Category}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    ShopId: e.value,
                                    SellerCategory: "",
                                  });
                                  setsubcat(e.SellerCategory);
                                }}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="category"
                              />
                              {error?.Shop && (
                                <span className="error-msg">{error?.Shop}</span>
                              )}
                            </div>
                            {console.log(
                              "lkjhgfcghjkhgcv",
                              Categorys,
                              form?.SellerCategory
                            )}
                            <div className="col-lg-6 mt-3">
                              <p className="cat_newcreate_ttls addproduct_ttl">
                                Category *
                              </p>
                              <Select
                                options={Categorys}
                                value={{
                                  label: Array.isArray(Categorys)
                                    ? Categorys?.find(
                                        (it) => it?._id == form?.SellerCategory
                                      )?.CategoryName ?? form?.SellerCategory
                                    : form?.SellerCategory,
                                  value: form?.SellerCategory,
                                }}
                                styles={stylesgraybg}
                                // value={form?.SellerCategory}
                                onChange={(e) => OnChange(e, "SellerCategory")}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="category"
                              />
                              {error?.SellerCategory && (
                                <span className="error-msg">
                                  {error?.SellerCategory}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 mt-3">
                              <p className="cat_newcreate_ttls addproduct_ttl">
                                Quantity *
                              </p>

                              <input
                                className="sigin_inputs cat_newinput"
                                type="number"
                                placeholder="Ex. $12.50"
                                id="Quantity"
                                value={form?.Quantity}
                                onChange={(e) => OnChange(e)}
                              />
                              {error?.Quantity && (
                                <span className="error-msg">
                                  {error?.Quantity}
                                </span>
                              )}
                            </div>

                            <div className="col-12 mt-3">
                              <p className="cat_newcreate_ttls addproduct_ttl">
                                About this item *
                              </p>

                              {Id && form?._id ? (
                                <CKEditor
                                  editor={ClassicEditor}
                                  // initData={form?.ProductDescription}
                                  data={form?.ProductDescription}
                                  ref={ckeditor}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setForm({
                                      ...form,
                                      ...{ ["ProductDescription"]: data },
                                    });
                                  }}
                                />
                              ) : (
                                !Id && (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    // initData={form?.ProductDescription}
                                    ref={ckeditor}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setForm({
                                        ...form,
                                        ...{ ["ProductDescription"]: data },
                                      });
                                    }}
                                  />
                                )
                              )}
                            </div>
                            {error?.ProductDescription && (
                              <span className="error-msg">
                                {error?.ProductDescription}
                              </span>
                            )}

                            <div className="col-12 mt-3">
                              <div className="d-flex justify-content-between align-items-start mb-3">
                                <p className="page_title">
                                  Additional Information
                                </p>
                              </div>

                              <div className="cont cont cont_padding nee allo">
                                {/* <h3 className='page_title mb-4'>Questions</h3> */}

                                <div className="whole_form_pad mt-3">
                                  <div className="text-end add_question_wholediv">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Add Question
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="ggl_form_btn"
                                        onClick={() => alladdvariant()}
                                      >
                                        Add Question
                                      </button>
                                    </OverlayTrigger>
                                  </div>

                                  {form?.AdditionalInfo?.map((element, i) => (
                                    <div className="selectbar_dtls">
                                      <div className="text-end allin">
                                        <Form.Select
                                          className="tt"
                                          onChange={(e) => choosingfn(e, i)}
                                          aria-label="Default select example"
                                          value={element?.type}
                                        >
                                          <option>Choose Question Type</option>
                                          {[
                                            "Radio",
                                            "Checkbox",
                                            "Dropdown",
                                            "Shortanswer",
                                            "Paragraph",
                                            "property",
                                          ].map((it) => (
                                            <option value={it}>
                                              {it == "Radio" && (
                                                <BiRadioCircle />
                                              )}{" "}
                                              {it}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        {/* </OverlayTrigger> */}
                                      </div>

                                      {element?.type && (
                                        <div className="mt-3">
                                          <div class="form-group mt-4">
                                            <input
                                              type="text"
                                              class="form-control "
                                              id="exampleInputEmail1"
                                              aria-describedby="emailHelp"
                                              placeholder="Enter Question"
                                              value={element?.question}
                                              onChange={(e) =>
                                                addvariant(
                                                  i,
                                                  element?.type,
                                                  e.target.value,
                                                  "question"
                                                )
                                              }
                                            />
                                          </div>
                                          {![
                                            "Shortanswer",
                                            "Paragraph",
                                          ].includes(element?.type) && (
                                            <div className="text-end mt-4 mb-2">
                                              <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                  <Tooltip id="button-tooltip">
                                                    Add Option
                                                  </Tooltip>
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  class="ggl_form_btn"
                                                  onClick={() =>
                                                    addvariant(i, element?.type)
                                                  }
                                                >
                                                  Add Option
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          )}
                                          <div>
                                            <div className="row align-items-center mb-2 mt-3">
                                              {Array.isArray(element.values)
                                                ? (element.values || [])?.map(
                                                    (e, index) => {
                                                      return (
                                                        <>
                                                          <div className="col-md-1 col-1 text-start">
                                                            {element?.type ==
                                                            "Radio" ? (
                                                              <BiRadioCircle className="radiocircle" />
                                                            ) : element?.type ==
                                                              "Checkbox" ? (
                                                              <MdCheckBoxOutlineBlank className="radiocircle" />
                                                            ) : (
                                                              element?.type ==
                                                                "Dropdown" && (
                                                                <MdOutlineArrowDropDownCircle className="radiocircle" />
                                                              )
                                                            )}
                                                          </div>
                                                          {console.log(
                                                            "indexindexindexindex",
                                                            index,
                                                            i
                                                          )}
                                                          <div className="col-md-5 col-5">
                                                            <div class="form-group">
                                                              <input
                                                                type="text"
                                                                class="form-control quetioning_inps"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Option"
                                                                value={e?.value}
                                                                onChange={(e) =>
                                                                  addvariant(
                                                                    i,
                                                                    element?.type,
                                                                    e.target
                                                                      .value,
                                                                    "value",
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-md-5 col-5">
                                                            <div class="form-group">
                                                              <input
                                                                type="text"
                                                                value={e?.price}
                                                                class="form-control quetioning_inps"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Option"
                                                                onChange={(e) =>
                                                                  addvariant(
                                                                    i,
                                                                    element?.type,
                                                                    e.target
                                                                      .value,
                                                                    "price",
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>

                                                          <div className="col-md-1 col-1">
                                                            <OverlayTrigger
                                                              placement="bottom"
                                                              delay={{
                                                                show: 250,
                                                                hide: 400,
                                                              }}
                                                              overlay={
                                                                <Tooltip id="button-tooltip">
                                                                  Remove
                                                                </Tooltip>
                                                              }
                                                            >
                                                              <button
                                                                type="button"
                                                                className="btn dff"
                                                              >
                                                                <GrFormClose
                                                                  className="formclose"
                                                                  onClick={() =>
                                                                    removevariant(
                                                                      element?.type,
                                                                      index,
                                                                      i
                                                                    )
                                                                  }
                                                                />
                                                              </button>
                                                            </OverlayTrigger>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )
                                                : (element?.type ==
                                                    "Shortanswer" ||
                                                    element?.type ==
                                                      "Paragraph") && (
                                                    <>
                                                      <div className="col-md-1 col-3 text-end">
                                                        <img
                                                          src={ass}
                                                          className="img-fluid mt-3"
                                                        />
                                                      </div>
                                                      <div className="col-md-8 col-9">
                                                        <div class="form-group mt-3">
                                                          <textarea
                                                            value={
                                                              element?.values
                                                            }
                                                            class="form-control form-control2"
                                                            id="exampleFormControlTextarea1"
                                                            rows={
                                                              element?.type ==
                                                              "Shortanswer"
                                                                ? "1"
                                                                : "4"
                                                            }
                                                            placeholder={`${
                                                              element?.type ==
                                                              "Shortanswer"
                                                                ? "Short"
                                                                : "Long"
                                                            } answer text`}
                                                            onChange={(e) =>
                                                              addvariant(
                                                                i,
                                                                element?.type,
                                                                e.target.value,
                                                                "values"
                                                              )
                                                            }
                                                          ></textarea>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}

                                              <div className="allfooter">
                                                <div className="row align-items-center mt-3 mb-2 justify-content-end">
                                                  <div className="col-md-1 col-3">
                                                    <OverlayTrigger
                                                      placement="top"
                                                      delay={{
                                                        show: 250,
                                                        hide: 400,
                                                      }}
                                                      overlay={
                                                        <Tooltip id="button-tooltip">
                                                          Delete
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <button
                                                        type="button"
                                                        className="btn dff"
                                                      >
                                                        <RiDeleteBin6Line
                                                          className="deleteicons"
                                                          onClick={() =>
                                                            removevariant(
                                                              element?.type,
                                                              null,
                                                              i
                                                            )
                                                          }
                                                        />
                                                      </button>
                                                    </OverlayTrigger>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 mt-3">
                              <div className="addpro_tabletop"></div>
                              <div className="ffd_table addprod_table"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="sec_border catset_border">
                          <p className="cat_newcreate_ttls addprod_imgttl">
                            Product Image *
                          </p>
                          <hr className="addprod_imgttlhr" />
                          <div className="addprod_wholeupload">
                            <div className="addprod_imageupload">
                              <img
                                src={require("../../assets/images/icons/cloud.png")}
                              />

                              <p className="addprod_uploadfiletxt">
                                Upload File
                              </p>
                              <input
                                type="file"
                                id="ProductImage"
                                className="addprod_absinpfile"
                                accept="image/*"
                                onChange={(e) => OnChange(e)}
                              />
                            </div>
                            {error?.ProductImage && (
                              <span className="error-msg">
                                {error?.ProductImage}
                              </span>
                            )}
                            {form?.ProductImage && (
                              <div className="addproduct_foodimg mt-3">
                                {form?.ProductImage?.type ? (
                                  <img
                                    src={
                                      form?.ProductImage?.type
                                        ? URL.createObjectURL(
                                            form?.ProductImage
                                          )
                                        : require("../../assets/images/dummy.png")
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      typeof form?.ProductImage == "string" &&
                                      !isEmpty(form?.ProductImage)
                                        ? `${config.IMG_URL}/Product/${form?.ProductImage}`
                                        : require("../../assets/images/dummy.png")
                                    }
                                  />
                                )}
                                {error?.ProductImage && (
                                  <span className="error-msg">
                                    {error?.ProductImage}
                                  </span>
                                )}
                              </div>
                            )}
                            {form.Quantity ? (
                              <>
                                <p className="cat_newcreate_ttls addproduct_ttl mt-3">
                                  Total Quantity
                                </p>

                                <input
                                  className="sigin_inputs cat_newinput"
                                  type="text"
                                  id="size"
                                  disabled={true}
                                  value={form.Quantity}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {form.Price && (
                              <>
                                <p className="cat_newcreate_ttls addproduct_ttl mt-3">
                                  Price
                                </p>
                                <input
                                  className="sigin_inputs cat_newinput"
                                  type="text"
                                  id="Price"
                                  disabled={true}
                                  value={form.Price}
                                />
                              </>
                            )}

                            <div className="addprod_imageupload mt-4">
                              <img
                                src={require("../../assets/images/icons/cloud.png")}
                              />

                              <p className="addprod_uploadfiletxt">
                                Upload Product's Property images
                              </p>
                              <input
                                type="file"
                                id="ProductImageMultiple"
                                className="addprod_absinpfile"
                                accept="image/*"
                                multiple
                                onChange={(e) => OnChange(e)}
                              />
                            </div>
                            {error?.ProductImage && (
                              <span className="error-msg">
                                {error?.ProductImage}
                              </span>
                            )}
                            {!isEmpty(form?.ProductImageMultiple) && (
                              <div className="addproduct_foodimg multiple mt-3">
                                {form?.ProductImageMultiple?.map((it) => {
                                  console.log("dsahdmnadsahdmna", it);
                                  return it?.type ? (
                                    <img
                                      src={
                                        it?.type
                                          ? URL.createObjectURL(it)
                                          : require("../../assets/images/dummy.png")
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        !isEmpty(it)
                                          ? `${config.IMG_URL}/Product/sub/${it.ProductImageName}`
                                          : require("../../assets/images/dummy.png")
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          {/* )} */}
                        </div>
                      </div>

                      <div className="sec_border lightgray_secborder mt-3">
                        <button
                          className="lightgray_btn"
                          onClick={() => AddProduct()}
                        >
                          {Id ? "Edit Product Variant" : "Add Product Variant"}
                        </button>
                      </div>
                    </div>
                    {/* </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddProduct;
