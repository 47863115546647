import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
import useAxiosFile from "../../actions/useaxios";

import delpick from "../../assets/images/delpick.png";
import locationCom from "../../assets/svg/Location.svg";
import homeCom from "../../assets/svg/home.svg";
import clockCom from "../../assets/svg/clock.svg";
import coolIconCom from "../../assets/svg/coolicon.svg";

function BankDetails() {
  const dispatch = useDispatch();
  const locate = useLocation();
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  console.log("sds", locate);
  const { home, location, clock } = useSelector(
    (state) => state.seller_panel.steppergo
  );
  const { Asxds } = localStorage;
  const [Error, setError] = useState({});
  const local = DecryptData(Asxds);
  const [load, Setload] = useState(false);

  const [form, setForm] = useState({
    BankName: "",
    BankAccountNumber: "",
    AccountType: "",
    RoutingNumber: "",
    AccountHolderName: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "stepper",
      stepper: {
        steppergo: {
          home: true,
          location: true,
          clock: true,
          window: false,
          dummyimage: false,
        },
      },
    });
    // setForm(locate)
    setForm(locate?.state);
  }, []);
  console.log("fdgd", form);

  const OnChange = (e) => {
    Setload(false);
    console.log("kjskjfsjkfs", e);
    setError({});
    const { id, value } = e.target;
    setForm({
      ...form,
      ...{ [id]: value },
    });
  };
  console.log("sggsgsh", form);

  // console.log("Object.values(form)",Object.values(form))
  const Validation = () => {
    let error = {};
    if (isEmpty(form?.BankName)) error.BankName = "BankName required";
    if (isEmpty(form?.BankAccountNumber))
      error.BankAccountNumber = "BankAccountNumber required";
    if (isEmpty(form?.AccountType)) error.AccountType = "AccountType required";
    if (isEmpty(form?.RoutingNumber))
      error.RoutingNumber = "RoutingNumber required";
    if (isEmpty(form?.AccountHolderName))
      error.AccountHolderName = "AccountHolderName required";
    return error;
  };

  const Continue = async () => {
    Setload(true);
    var validate = Validation();

    const toastid = LoadToast();

    if (isEmpty(validate)) {
      var resp = await axiosFile.addsellershop({
        action: "BankDetail",
        ShopId: local.shopid,
        BankDetails: form,
      });
      console.log("Dsadasdsa", resp);
      if (resp?.success == "success") {
        updateToast(toastid, resp?.msg, "success");
        navigate("/Storeimage");
      } else {
        Setload(false);
        updateToast(toastid, "Try Again", "error");
      }
    } else {
      Setload(false);
      setError(validate);
      updateToast(toastid, "Try Again", "error");
    }
  };

  return (
    <div className="regwhole_bg acnt_regwhole_bg">
      {/* <img
        className="singin_abs reg_abs"
        src={require("../../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs wd_ht_unst" src={delpick} alt="" />
      <Container>
        {console.log("jhdsbhd")}

        <Row className="justify-content-center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            <div className="reg_top_dtls">
              <h6 className="reg_top_title">Bank Details</h6>
              {/* <p className="reg_skip_txt">
                <Link to="/">skip</Link>
              </p> */}
            </div>

            <Row className="mt-5">
              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <p className="storetiming_label mb-2">Bank Name</p>
                <input
                  className="sigin_inputs bankdetails_input"
                  type="text"
                  placeholder="Enter BankName"
                  id="BankName"
                  onChange={(e) => OnChange(e)}
                />
                <span className="error-msg">{Error?.BankName}</span>
                {/* <Select
                  options={Bankname}
                  defaultValue={selectedOption}
                  styles={stylesgraybg}
                  onChange={(e) => Onchangebankname(e)}
                  // onChange={setSelectedOption}
                  className="border_blue_select"
                  classNamePrefix="react-select"
                  placeholder="Choose a bank"
                /> */}
              </Col>

              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <p className="storetiming_label mb-2">Bank Account Number</p>
                <input
                  className="sigin_inputs bankdetails_input"
                  type="text"
                  placeholder="Enter Account Number"
                  id="BankAccountNumber"
                  onChange={(e) => OnChange(e)}
                />
                <span className="error-msg">{Error?.BankAccountNumber}</span>
              </Col>
              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <p className="storetiming_label mb-2">Account Type</p>
                <input
                  className="sigin_inputs bankdetails_input"
                  type="text"
                  placeholder="Enter Account Type"
                  id="AccountType"
                  onChange={(e) => OnChange(e)}
                />
                <span className="error-msg">{Error?.AccountType}</span>

                {/* <Select
                  options={AccountType}
                  defaultValue={selectedOption}
                  styles={stylesgraybg}
                  onChange={(e) => Onchangeaccount(e)}
                  className="border_blue_select"
                  classNamePrefix="react-select"
                  placeholder="Choose a bank account type"
                /> */}
              </Col>

              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <p className="storetiming_label mb-2">Routing Number</p>
                <input
                  className="sigin_inputs bankdetails_input"
                  type="number"
                  placeholder="Enter Routing Number"
                  id="RoutingNumber"
                  onChange={(e) => OnChange(e)}
                />
                <span className="error-msg">{Error?.RoutingNumber}</span>
              </Col>

              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <p className="storetiming_label mb-2">Account Holder Name</p>
                <input
                  className="sigin_inputs bankdetails_input"
                  type="text"
                  placeholder="Enter account name"
                  id="AccountHolderName"
                  onChange={(e) => OnChange(e)}
                />
                <span className="error-msg">{Error?.AccountHolderName}</span>
              </Col>

              <Col className="mb-3" xxl={6} lg={6} md={6}>
                <div className="w-100 text-end">
                  {/* <Link to="/Storeimage" state={form}> */}
                  <button
                    disabled={load}
                    onClick={() => Continue()}
                    className="blue_btn storeimg_bluebtn"
                  >
                    {load ? "Loading..." : "Continue"}
                  </button>
                  {/* </Link> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="stepper">
        {home == true ? (
          // <img src={require("../../assets/images/icons/bluehome.png")} />
          <img src={homeCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/shop.png")} alt="" />
        )}

        <p className={home == true ? "blue_line" : ""}>------</p>
        {location == true ? (
          // <img src={require("../../assets/images/icons/bluelocation.png")} />
          <img src={locationCom} alt="" />
        ) : (
          <img src={require("../../assets/images/icons/location.png")} alt="" />
        )}
        <p className={location == true ? "blue_line" : ""}>------</p>
        {clock == true ? (
          // <img src={require("../../assets/images/icons/blueclock.png")} />
          <img src={clockCom} />
        ) : (
          <img src={require("../../assets/images/icons/Clock.png")} />
        )}
        <p className={clock == true ? "blue_line" : ""}>------</p>
        <img src={require("../../assets/images/icons/window.png")} />
        <p>------</p>
        <img src={require("../../assets/images/icons/dummyimage.png")} />
      </div>
    </div>
  );
}

export default BankDetails;
