import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LoadToast, updateToast } from "../actions/common";

import delpick from "../assets/images/delpick.png";

function OtpVerification() {
  const { EmailId } = useSelector((state) => state.seller_panel.User);

  const [otp, setOtp] = useState("");
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  console.log(otp?.length == 6, "sdfgsf");

  const { state } = useLocation();
  console.log("ggsgs", state);
  // const { payload, token } = useSelector(state => state.admin_panel.User);
  // console.log("aaaaaaa", token);
  const [error, SetError] = useState({});
  console.log("vfv", error);
  const Validate = () => {
    console.log("mssss");
    let Error = {};
    if (isEmpty(otp)) {
      Error.OTP = "OTP Required";
    }
    return Error;
  };

  const Submit = async () => {
    console.log("dgsdgsdg");
    const toastid = LoadToast();
    let errorvalid = Validate(otp);
    console.log("heyerror", errorvalid);
    if (otp?.length == 6) {
      if (isEmpty(errorvalid)) {
        let senddata = {
          otp: otp,
          action: "OTP",
          EmailId: EmailId,
        };
        let Resp = await axiosFile.LoginReg(senddata);
        if (Resp?.data == "verified") {
          updateToast(toastid, "verified", "success");
          navigate("/Changepass", { state: { EmailId: state?.EmailId } });
        } else {
          updateToast(toastid, "Enter Correct OTP", "error");
          SetError({ OTP: "Enter Correct OTP" });
        }
      }
    } else {
      updateToast(toastid, "Enter Correct OTP", "error");
      SetError({ OTP: "Enter Correct OTP" });
    }
  };

  const Resend = async () => {
    setOtp("");
    const toastid = LoadToast();
    let senddata = { EmailId: EmailId };
    senddata.action = "forgot";
    let Resp = await axiosFile.LoginReg(senddata);
    console.log("bgfdgdf", Resp);
    if (Resp.success == "success") {
      updateToast(toastid, "Otp Send To Mail", "info");
    } else {
      updateToast(toastid, "fix", "error");
    }
  };

  return (
    <div className="singin_bg">
      {/* <img
        className="singin_abs"
        src={require("../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs wd_ht_unst" src={delpick} alt="" />
      <div className="sigin_form">
        <div className="signin_topttl">
          <p className="signin_ttlleft">Verification</p>
          {/* <p className='sigin_skip'><Link to="/">Skip</Link></p> */}
        </div>
        <p className="sigin_missedtxt">
          We sent a confirmation Mail at <br />
          <b>{state?.EmailId}</b>
        </p>
        <div className="centering_sigin">
          <div className="single_center otp-inputs">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <span>{error.OTP}</span>
          {/* <div className='single_center'>
        <label className='signin_labels' for="mobile">Mobile Number</label><br/>
  <input className='sigin_inputs' type="number" placeholder='Enter your mobile number' id="mobile" name="mobile"/>
  </div> */}
        </div>

        <div className="siginin_btn_dtl">
          <button className="blue_btn signin_btn" onClick={() => Submit()}>
            Continue
          </button>
          <p className="resend_otptxt">
            Didn't get the OTP. <span onClick={() => Resend()}>Resend</span>
          </p>
        </div>
        <p className="sigin_acnt_txt">
          Don't have an account?{" "}
          <span className="reg_link">
            <Link to="/register">Register</Link>
          </span>
        </p>
      </div>
    </div>
  );
}

export default OtpVerification;
