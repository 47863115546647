import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import React from "react";

// export default function Geocode() {
  setDefaults({
    key: "AIzaSyDT9jQZ5BhdkR8dUwZFtdJKdi82PaBT_h8", // Your API key here.
    language: "en", // Default language for responses.
  });

 export const GetLatLngBasedOnAddress = async(address) => {

    // Get latitude & longitude from address.
  try{
    console.log("results",address)

   var {results}  = await fromAddress(address)
   console.log("results",results,results?.[0]?.geometry?.location)
    // coordinates: [ -73.97, 40.77 ] 
   return { type: "Point",coordinates:[results?.[0]?.geometry?.location?.lat,results?.[0]?.geometry?.location?.lng]}
    //   .then(({ results }) => {
    //     const { lat, lng } = results[0].geometry.location;
    //     console.log(lat, lng);
        
    //   })
    //   .catch(console.error);
 }
catch(e){ 
  console.log('GetLatLngBasedOnAddresseror',e)
  return { type: "Point",coordinates:[0,0]}
}
};

//   return {
//     GetLatLngBasedOnAddress
// };
// }

export const GetAddressBasedOnLatLng = async(lat,lng,from) => {
  console.log("results",lat,lng,from)
   try{
  var {results}  = await fromLatLng(lat,lng)
  console.log("results",results[0])
 
  var add = {},
  stret = {};
var data = results[0].address_components.map((it) => {
  console.log("add>>>>>>>>>>>>>", it);
  if (it.types.includes("street_number")) {
   add.Doornumber = it.long_name;
  }
  if (it.types.includes("route")) {
   add.StreetName = it.long_name;
  }
  if (it.types.includes("locality")) {
    stret.Street2 = it.long_name;
  }
  if (it.types.includes("administrative_area_level_2")) {
    add.City = it.long_name;
  }
  if (it.types.includes("administrative_area_level_3")) {
    add.City = it.long_name;
  }
  if (it.types.includes("administrative_area_level_1")) {
    add.State = it.long_name;
  }
  if (it.types.includes("country")) {
    add.Country = it.long_name;
  }
  if (it.types.includes("postal_code")||it.types.includes("post_code")) {
    add.Zipcode = it.long_name;
  }

  return add;
});
   return from == "sign" ? add : results[0].formatted_address
 }
catch(e) {
  console.log('GetAddressBasedOnLatLng',e)
  return false
}

 };
