import { Route } from "react-router-dom";

import React from "react";
import Dashboard from "../Views/Dashboard";
import Protected from "./Protected";
import CreateAccountPage from "../Views/CreateAccount/CreateAccountPage";

import SellerDetails from "../Views/CreateAccount/SellerDetails";
import StoreTiming from "../Views/CreateAccount/StoreTiming";
import BankDetails from "../Views/CreateAccount/BankDetails";
import StoreImages from "../Views/CreateAccount/StoreImages";
import Orders from "../Views/Orders";
import OrdersView from "../Views/OrdersView";
import ProductList from "../Views/MenusPages/Productlist";
import AddProduct from "../Views/MenusPages/AddProduct";
import CategoryPage from "../Views/MenusPages/CategoryPage";
import Account from "../Views/Account";
import ShopList from "../Views/Accounts/ShopList";
import UserAccount from "../Views/Accounts/UserAccounts";
import MenuManager from "../Views/MenuPages/MenuManager";

export const PublicRoutes = (Auth) => {
  console.log("Auth", Auth);
  return (
    <>
      <Route
        path="/createaccount"
        element={
          <Protected isSignedIn={Auth}>
            <CreateAccountPage />
          </Protected>
        }
      />
      <Route
        path="/sellerdetail"
        element={
          <Protected isSignedIn={Auth}>
            <SellerDetails />
          </Protected>
        }
      />
      <Route
        path="/storetiming"
        element={
          <Protected isSignedIn={Auth}>
            <StoreTiming />
          </Protected>
        }
      />
      <Route
        path="/bankdetails"
        element={
          <Protected isSignedIn={Auth}>
            <BankDetails />
          </Protected>
        }
      />
      <Route
        path="/Storeimage"
        element={
          <Protected isSignedIn={Auth}>
            <StoreImages />
          </Protected>
        }
      />
      <Route
        path="/"
        element={
          <Protected isSignedIn={Auth}>
            <Dashboard />
          </Protected>
        }
      />
      <Route
        path="/orders"
        element={
          <Protected isSignedIn={Auth}>
            <Orders />
          </Protected>
        }
      />
      <Route
        path="/orderdetail"
        element={
          <Protected isSignedIn={Auth}>
            <OrdersView />
          </Protected>
        }
      />
      <Route
        path="/orderdetail"
        element={
          <Protected isSignedIn={Auth}>
            <OrdersView />
          </Protected>
        }
      />
      <Route
        path="/category"
        element={
          <Protected isSignedIn={Auth}>
            <CategoryPage />
          </Protected>
        }
      />
      <Route
        path="/ProductList"
        element={
          <Protected isSignedIn={Auth}>
            <ProductList />
          </Protected>
        }
      />
      <Route
        path="/editproduct"
        element={
          <Protected isSignedIn={Auth}>
            <AddProduct />
          </Protected>
        }
      />
      <Route
        path="/addproduct"
        element={
          <Protected isSignedIn={Auth}>
            <AddProduct />
          </Protected>
        }
      />
      <Route
        path="/account/operation"
        element={
          <Protected isSignedIn={Auth}>
            <Account />
          </Protected>
        }
      />
      <Route
        path="/account"
        element={
          <Protected isSignedIn={Auth}>
            <Account />
          </Protected>
        }
      />
      <Route
        path="/addaccount"
        element={
          <Protected isSignedIn={Auth}>
            <Account />
          </Protected>
        }
      />

      <Route
        path="/shoplist"
        element={
          <Protected isSignedIn={Auth}>
            <ShopList />
          </Protected>
        }
      />
      <Route
        path="/accounts"
        element={
          <Protected isSignedIn={Auth}>
            <UserAccount />
          </Protected>
        }
      />

      <Route
        path="/menumanager"
        element={
          <Protected isSignedIn={Auth}>
            <MenuManager />
          </Protected>
        }
      />
    </>
  );
};
