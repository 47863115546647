import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { isEmpty } from "../actions/common";
import Useaxiosfile from "../actions/useaxios";
import { LoadToast, updateToast } from "../actions/common";
import { useDispatch, useSelector } from "react-redux";
import config from "../actions/config";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

import delpick from "../assets/images/delpick.png";

function Register() {
  const axiosfile = Useaxiosfile();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initname = {
    FirstName: "First Name",
    LastName: "Last Name",
    EmailId: "Email-Id",
    Mobile: "Mobile Number",
    Password: "Password",
    ConfirmPassword: "Confirm-Password",
  };

  const [showGender, setShowGender] = useState(false);
  const dispatch = useDispatch();
  const handleCloseGender = () => setShowGender(false);

  const [showDOB, setShowDOB] = useState(false);
  const [formdata, SetFormdata] = useState();
  const [Error, SetError] = useState({});
  const handleCloseDOB = () => setShowDOB(false);

  const [passwordText, setPasswordText] = useState("password");
  const [passwordConfirmText, setPasswordConfirmText] = useState("password");

  const OnChange = (e, ids, data) => {
    SetError({});
    console.log("kjskjfsjkfs", e);
    let id = e?.target?.id,
      value = e?.target?.value;
    SetFormdata({
      ...formdata,
      ...{ [id ? id : ids]: value ? value : e },
    });
  };
  const Validate = (data) => {
    let Error = {};
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    if (isEmpty(formdata?.FirstName)) {
      Error.FirstName = "FirstName  Required";
    }

    if (isEmpty(formdata?.EmailId)) {
      Error.EmailId = "Email cannot be empty";
    } else if (formdata?.EmailId != "") {
      if (!emailRegex.test(formdata?.EmailId)) {
        Error.EmailId = "Enter valid email";
      }
    }
    if (isEmpty(formdata?.LastName)) {
      Error.LastName = "LastName Required";
    }
    if (isEmpty(formdata?.Mobile)) {
      Error.Mobile = "Mobile Number Required";
    }
    if (isEmpty(formdata?.Password)) {
      Error.Password = "Enter Password";
    } else if (!config.PASSWORD.test(formdata?.Password)) {
      Error.Password =
        "Password must contain 1 small letter, 1 capital letter, 1 special character, 1 number.";
    }
    if (isEmpty(formdata?.ConfirmPassword)) {
      Error.ConfirmPassword = "Confirm Password required";
    }
    if (
      formdata?.ConfirmPassword &&
      formdata?.Password !== formdata?.ConfirmPassword
    ) {
      Error.ConfirmPassword = "Password and Confirm Password must be same";
    }
    if (formdata?.Mobile && !isValidPhoneNumber(formdata?.Mobile)) {
      Error.Mobile = "Enter Valid Mobile number";
    }
    return Error;
  };

  const Register = async () => {
    const toastid = LoadToast();
    let check = Validate(formdata);
    console.log("lskdlsjfsf", check);
    if (isEmpty(check)) {
      let senddata = formdata;
      senddata.action = "register";
      senddata.Type = "seller";
      let Resp = await axiosfile.LoginReg(senddata);
      console.log("dfsdf", Resp);
      if (Resp.success == "success") {
        updateToast(toastid, "Register Successfully", "success");
        navigate("/signin", { state: { from: "reg" } });
      } else {
        updateToast(toastid, "fix", Resp.success);
        SetError(Resp.error);
      }
    } else {
      SetError(check);
      updateToast(toastid, "fix", "error");
    }
  };
  console.log("hshd", formdata);
  return (
    <div className="regwhole_bg regwhole_bg_hei">
      {/* <img
        className="singin_abs reg_abs"
        src={require("../assets/images/jamskew.png")}
        alt=""
      /> */}
      <img className="singin_abs reg_abs delpick_rgs" src={delpick} alt="" />

      <Container>
        <Row className="justify-content-center">
          <Col
            xxl={7}
            xl={7}
            lg={7}
            md={10}
            sm={12}
            xs={12}
            className="reg_whole_dtls"
          >
            <div className="reg_top_dtls">
              <h6 className="reg_top_title">Register</h6>
              {/* <p className='reg_skip_txt'><Link to="/">skip</Link></p> */}
            </div>
            <p className="reg_welcome_txt">
              Welcome. Let's Create Your Seller Account.
            </p>
            <Row>
              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    First Name
                  </label>
                  <br />
                  <input
                    className="sigin_inputs"
                    autoComplete="off"
                    type="text"
                    placeholder="Ex.John"
                    id="FirstName"
                    name="FirstName"
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.FirstName && (
                    <span className="error-msg">{Error.FirstName}</span>
                  )}
                </div>
              </Col>

              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    Phone Number
                  </label>
                  <br />
                  {/* <input className='sigin_inputs' type="number" placeholder='123 456 7890' id="mobile" name="mobile"/> */}

                  {/* <InputGroup className="sigin_inputs reg_inpgrp"> */}
                  {/* <InputGroup.Text id="basic-addon1">+1 |</InputGroup.Text> */}
                  {/* <Form.Control
                      placeholder="123 456 7890"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="Mobile"
                      type="number"
                      onChange={(e) => OnChange(e)}
                    /> */}

                  <PhoneInput
                    className="new_rephoneinp"
                    placeholder="Enter phone number"
                    id="Mobile"
                    onChange={(val) => OnChange(val, "Mobile")}
                    onCountryChange={(val) =>
                      OnChange(val, "Mobile", "country")
                    }
                    defaultCountry="US"
                    country="US"
                    countries={["US","IN"]}
                  />
                  {/* </InputGroup> */}
                </div>
                <span className="error-msg">{Error?.Mobile}</span>
              </Col>
              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    Last Name
                  </label>
                  <br />
                  <input
                    className="sigin_inputs"
                    autoComplete="off"
                    type="text"
                    placeholder="Ex.Deo"
                    id="LastName"
                    name="LastName"
                    onChange={(e) => OnChange(e)}
                  />
                  <span className="error-msg">{Error?.LastName}</span>
                </div>
              </Col>

              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    Password
                  </label>
                  <br />
                  <div className="reg_passwordinps">
                    <input
                      className=""
                      autoComplete="off"
                      type={passwordText == "password" ? "password" : "text"}
                      placeholder="Enter Password"
                      id="Password"
                      name="Password"
                      onChange={(e) => OnChange(e)}
                    />
                    <i
                      onClick={() =>
                        setPasswordText(
                          passwordText == "text" ? "password" : "text"
                        )
                      }
                      className={
                        passwordText == "password"
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }
                    ></i>
                  </div>
                  {Error?.Password && (
                    <span className="error-msg">{Error.Password}</span>
                  )}
                </div>
              </Col>

              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    Email Address
                  </label>
                  <br />
                  <input
                    className="sigin_inputs"
                    autoComplete="off"
                    type="email"
                    placeholder="Ex.johndeo@gmail.com"
                    id="EmailId"
                    name="EmailId"
                    onChange={(e) => OnChange(e)}
                  />
                  <span className="error-msg">{Error?.EmailId}</span>
                </div>
              </Col>

              <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                <div className="single_center">
                  <label className="signin_labels" for="mobile">
                    Confirm Password
                  </label>
                  <br />
                  <div className="reg_passwordinps">
                    <input
                      className=""
                      autoComplete="off"
                      type={
                        passwordConfirmText == "password" ? "password" : "text"
                      }
                      placeholder="Confirm Password"
                      id="ConfirmPassword"
                      name="mobile"
                      onChange={(e) => OnChange(e)}
                    />
                    <i
                      onClick={() =>
                        setPasswordConfirmText(
                          passwordConfirmText == "text" ? "password" : "text"
                        )
                      }
                      className={
                        passwordConfirmText == "password"
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }
                    ></i>
                  </div>
                  <span className="error-msg">{Error?.ConfirmPassword}</span>
                </div>
              </Col>
            </Row>
            <div className="w-100 text-center">
              {/* <Link to='/home'> */}
              <button
                type="button"
                className="blue_btn register_btn delpick_rgs_smt"
                onClick={() => Register()}
              >
                Create An Account
              </button>
              {/* </Link> */}
            </div>
            <p className="reg_bottomtxt">
              Already have an account?
              <span className="error-msg">
                <Link to="/signin">Sign In</Link>
              </span>
            </p>
          </Col>
        </Row>

        {/* start of gender modal */}
        <Modal
          show={showGender}
          onHide={handleCloseGender}
          backdrop="static"
          keyboard={false}
          centered
          className="reg_gender_modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p className="reg_modal_hintntxt">
              “We use your gender to make Delpick services more personal.
              Additionally, we can provide more relevant, tailored content you
              might be interested in, like ads as well as promotions, including
              but not limited to ,highly discounted and/or free
              products/services".
            </p>
          </Modal.Body>
        </Modal>
        {/* end of gender modal */}

        {/* start of DOB modal */}

        <Modal
          show={showDOB}
          onHide={handleCloseDOB}
          backdrop="static"
          keyboard={false}
          centered
          className="reg_gender_modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p className="reg_modal_hintntxt reg_dobmodal_txt">
              1. Account security and personalization across Jamske services.
            </p>

            <p className="reg_modal_hintntxt reg_dobmodal_txt">
              2. Make sure you're old enough to buy certain products and
              services. For example, show/buy alcoholic beverages.
            </p>

            <p className="reg_modal_hintntxt reg_dobmodal_txt">
              3. Offer you highly discounted or free products and services on
              your birthday.
            </p>

            <p className="reg_modal_hintntxt reg_dobmodal_txt">
              4. Determine your age group for personalized recommendations and
              ads.
            </p>
          </Modal.Body>
        </Modal>
        {/* end of DOB modal */}
      </Container>
    </div>
  );
}

export default Register;
