import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import useAxiosFile from "./actions/useaxios";
import { PublicRoutes } from "./Routes/PublicRoutes";
import { UserRoutes } from "./Routes/UserRoutes";
import { useEffect, useMemo, useState } from "react";
import { DecryptData, EncryptData, isEmpty } from "./actions/common";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const { token, payload } = useSelector((state) => state.seller_panel.User);
  const usertoken = localStorage?.token_s ? localStorage?.token_s : token;
  const _id = DecryptData(localStorage.getItem("_id_s"));
  console.log(
    "usertoken",
    usertoken,
    payload,
    _id,
    localStorage,
    localStorage.getItem("_id_s")
  );
  const [Auth, SetAuth] = useState(usertoken);
  useEffect(() => {
    SetAuth(usertoken);
    if (!isEmpty(usertoken) && _id) {
      AlreadyExits(usertoken);
    }
  }, [token, usertoken, _id]);
  const axiosfile = useAxiosFile();
  console.log("DAsdasdasdasdasdasdsa", localStorage, _id, usertoken, Auth);
  const AlreadyExits = async () => {
    await axiosfile.AlreadyExits({ data: EncryptData({ _id }) });
  };
  return useMemo(
    () => (
      <BrowserRouter basename="/">
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          {Auth ? PublicRoutes(true) : UserRoutes(true)}
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    ),
    [Auth]
  );
}

export default App;
