import React , { useState }  from 'react'
import { Link , NavLink} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function Sidebar() {
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.seller_panel.User);



    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
      // const content = document.getElementById('content-wrapper');
      //   setIsToggled(!isToggled);
      //   if (isToggled == "true"){
      //     content.classList.add("preview1");
      // } else{
      //     content.classList.add("preview");
      // }
      dispatch({
        type: "Login",
        Login: {
          User: {
            ...datas,...{toggle	:	!isToggled}
          }
      }
      })
      };
      
  return (
    <div  className={isToggled ? 'toggled' : ''} id="wrapper">

    <aside id="sidebar-wrapper">
      {/* <div class="sidebar-brand">
        <h2>Logo</h2>
      </div> */}
      <ul class="sidebar-nav">
        <li>
      <NavLink to='/dashboard' className="sidebar_faq">
      <img className='sidebar_logo' src={require('../assets/images/icons/home.png')} alt="dashboard"/>
          Dashboard
                </NavLink>
                </li>

                <li>
      <NavLink to='/orders' className="sidebar_faq">
      <img className='sidebar_logo' src={require('../assets/images/icons/shopping.png')} alt="dashboard"/>
          Orders
                </NavLink>
                </li>
    
        
        <li>
        <div class="accordion accordion-flush side_dahsboard_accord" id="management">
      {/* <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button side_dashaccord_headbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#management-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <img className='sidebar_logo' src={require('../assets/images/icons/menu.png')} alt="dashboard"/>
          Menus
          </button>
        </h2>
        <div id="management-collapse" class="accordion-collapse collapse" data-bs-parent="#management">
          <div class="accordion-body">
            <ul className=''>
              
              <li>
              <NavLink to='/category'>
                <img className='sidebar_logo' src={require('../assets/images/icons/data.png')} alt="dashboard"/>
                Category</NavLink>
              </li>
              <li>
           
              </li>
              <li>
              <NavLink to='/ProductList'>
                <img className='sidebar_logo' src={require('../assets/images/icons/coffee-togo.png')} alt="dashboard"/>
                 Product List</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
     
    </div>
        </li>
    
        {/* <li>
        <div class="accordion accordion-flush side_dahsboard_accord" id="location">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button side_dashaccord_headbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#location-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <img className='sidebar_logo' src={require('../assets/images/icons/Speakerphone.png')} alt="dashboard"/>
          Offers
          </button>
        </h2>
        <div id="location-collapse" class="accordion-collapse collapse" data-bs-parent="#location">
          <div class="accordion-body">
            <ul className=''>
            <li>
              <NavLink to='/promocode'>
                <img className='sidebar_logo' src={require('../assets/images/icons/tag-outline.png')} alt="dashboard"/>
              Promocode</NavLink>
              </li>
              <li>
              <NavLink to='/discount'>
                <img className='sidebar_logo' src={require('../assets/images/icons/tagpercent.png')} alt="dashboard"/>
              Discount</NavLink>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
     
    </div>
        </li>
    
        <li>
        <div class="accordion accordion-flush side_dahsboard_accord" id="promotion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button side_dashaccord_headbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#promotion-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <img className='sidebar_logo' src={require('../assets/images/icons/roundchart.png')} alt="dashboard"/>
          Finance
          </button>
        </h2>
        <div id="promotion-collapse" class="accordion-collapse collapse" data-bs-parent="#promotion">
          <div class="accordion-body">
            <ul className=''>
            <li>
              <NavLink to='/allfinance'>
                <img className='sidebar_logo' src={require('../assets/images/icons/simvertical.png')} alt="dashboard"/>
                All</NavLink>
              </li>
            <li>
              <NavLink to='/salesfinance'>
                <img className='sidebar_logo' src={require('../assets/images/icons/simvertical.png')} alt="dashboard"/>
                Sales</NavLink>
              </li>
              <li>
              <NavLink to='/salesinvoice'>
                <img className='sidebar_logo' src={require('../assets/images/icons/simhorizontal.png')} alt="dashboard"/>
              Invocies</NavLink>
              </li>
              <li>
              <NavLink to=''>
                <img className='sidebar_logo' src={require('../assets/images/icons/tickspan.png')} alt="dashboard"/>
              Settlements</NavLink>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
     
    </div>
        </li>
    
        <li>
        <div class="accordion accordion-flush side_dahsboard_accord" id="finance">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button side_dashaccord_headbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#finance-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <img className='sidebar_logo' src={require('../assets/images/icons/folder.png')} alt="dashboard"/>
          Reports
          </button>
        </h2>
        <div id="finance-collapse" class="accordion-collapse collapse" data-bs-parent="#finance">
          <div class="accordion-body">
            <ul className=''>
            <li>
              <NavLink to='/salesreport'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Chartpie.png')} alt="dashboard"/>
                Sales Report</NavLink>
              </li>
              <li>
              <NavLink to='/itemreport'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Cube.png')} alt="dashboard"/>
                Item Report</NavLink>
              </li>
              <li>
              <NavLink to='/reviews'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Emojihappy.png')} alt="dashboard"/>
                Reviews</NavLink>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
     
    </div>
        </li> */}
    
        <li>
        <div class="accordion accordion-flush side_dahsboard_accord" id="contents">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button side_dashaccord_headbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#contents-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
          <img className='sidebar_logo' src={require('../assets/images/icons/settings.png')} alt="dashboard"/>
          Settings
          </button>
        </h2>
        <div id="contents-collapse" class="accordion-collapse collapse" data-bs-parent="#contents">
          <div class="accordion-body">
            <ul className=''>
            <li>
              <NavLink to='/shoplist'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Briefcase.png')} alt="dashboard"/>
                Shops</NavLink>
                <NavLink to='/accounts'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Briefcase.png')} alt="dashboard"/>
                Accounts
                </NavLink>
              </li>
              {/* <li>
              <NavLink to='/account/operation'>
                <img className='sidebar_logo' src={require('../assets/images/icons/Clock.png')} alt="dashboard"/>
                Operation Time</NavLink>
              </li> */}
              
            </ul>
          </div>
        </div>
      </div>
     
    </div>
        </li>
              <li>
      <NavLink to='/menumanager' className="sidebar_faq">
      <img className='sidebar_logo' src={require('../assets/images/icons/shopping.png')} alt="dashboard"/>
      Menu Manager
                </NavLink>
                </li>

      </ul>
    </aside>
     
    <div id="navbar-wrapper">
      <nav class="navbar navbar-inverse">
        <div class="container-fluid">
          <div class="navbar-header">
            {/* <a href="#" class="navbar-brand" id="sidebar-toggle" onClick={handleToggle}><i class="fa fa-bars"></i></a> */}
          </div>
        </div>
      </nav>
    </div>
    
    
    
    </div>
  )
}

export default Sidebar