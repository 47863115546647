import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { isEmpty } from "../actions/common";
import config from "../actions/config";
import { LoadToast, updateToast } from "../actions/common";
import { useDispatch, useSelector } from "react-redux";
export default function Password() {
  const axiosFile = useAxiosFile();
  const { EmailId } = useSelector((state) => state.seller_panel.User);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [form, setForm] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const navigate = useNavigate();
  console.log("nfgdgfj", form);
  useEffect(() => {
    if (!EmailId) navigate("/");
  }, []);
  const [error, SetError] = useState({});

  const OnChange = (e) => {
    console.log("kjskjfsjkfs", e);
    const { id, value } = e.target;
    setForm({
      ...form,
      ...{ [id]: value },
    });
  };
  const Validate = () => {
    let Error = {};
    if (isEmpty(form.Password)) {
      Error.Password = "Enter Password";
    } else if (!config.PASSWORD.test(form.Password)) {
      Error.Password =
        "Password must contain 1 small letter, 1 capital letter, 1 special character, 1 number.";
    }
    if (isEmpty(form.ConfirmPassword)) {
      Error.ConfirmPassword = "Enter Confirm Password";
    }

    if (form.ConfirmPassword && form.Password !== form.ConfirmPassword) {
      Error.ConfirmPassword = "Password and Confirm Password must be same";
    }
    return Error;
  };

  const ChangePassword = async () => {
    const toastid = LoadToast();
    let check = Validate();
    console.log("lskdlsjfsf", check);
    if (isEmpty(check)) {
      let senddata = {
        action: "changepassword",
        Password: form,
        EmailId: EmailId,
      };
      let Resp = await axiosFile.LoginReg(senddata);
      console.log("lskflsklfs", Resp);
      if (Resp.success == "success") {
        updateToast(toastid, "Password Changed Successfully", "success");
        // dispatch({
        //   type: "Login",
        //   Login: {
        //     User: {
        //       token: Resp.token,
        //       payload: Resp.data?.data,
        //     },
        //   },
        // });
        navigate("/signin");
      } else {
        toast.error(Resp.msg);
        updateToast(toastid, "fix", "error");
      }
    } else {
      SetError(check);
      updateToast(toastid, "fix", "error");
    }
  };

  return (
    <div>
      <div className="login_pages">
        <div className="container custom_container">
          <div className="row aligns_itm login_row">
            <div className="col-lg-6 leftsec">
              {/* <img src={require("../../assests/images/loginleft.png")}  className='img-fluid login_img'/> */}
            </div>
            <div className="col-lg-6 rightsec">
              <div className="row logosec mb-3 mb-md-5">
                {/* <img src={require("../../assests/images/logo.png")}  className='img-fluid logo_img'/> */}
              </div>

              <div className="row form_sec">
                <p className="log_title">Choose a new password</p>
                <p className="log_desc">
                  Choose a new password for your store account and continue
                  selling with us.
                </p>
                <form>
                  <div className="form-group mb-4">
                    <label for="exampleInputEmail1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="Password"
                      aria-describedby="emailHelp"
                      placeholder="Enter Password"
                      onChange={(e) => OnChange(e)}
                    />
                    {error?.Password && <span>{error.Password}</span>}
                  </div>
                  <div class="form-group mb-4">
                    <label for="exampleInputPassword1">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="ConfirmPassword"
                      placeholder="Confirm Password"
                      onChange={(e) => OnChange(e)}
                    />
                    {error?.ConfirmPassword && (
                      <span>{error.ConfirmPassword}</span>
                    )}
                  </div>

                  <Link
                    className="btn btn-primary"
                    onClick={() => ChangePassword()}
                  >
                    Save
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
